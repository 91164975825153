<div *ngIf="userTierLevel?.tierLevel!='FREE'" type="button" (click)="resetForm()" data-bs-toggle="modal" data-bs-target="#supportModal" class="support-button rounded-circle">
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white"
    class="bi bi-question-circle support-button-symbol" viewBox="0 0 16 16">
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
    <path
      d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
  </svg>
</div>


<!-- Modal -->
<div class="modal-lg modal fade" id="supportModal" tabindex="-1" aria-labelledby="supportModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="supportModalLabel">Support</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
            role="tab" aria-controls="home" aria-selected="true">Create Ticket</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="tickets-tab" data-bs-toggle="tab" data-bs-target="#tickets" type="button"
            role="tab" aria-controls="tickets" aria-selected="false">My Tickets <span
              class="badge rounded-pill bg-primary">{{ticketCount}}</span></button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <div *ngIf="!formSend" class="modal-body" [formGroup]="form">
            <h4>Need Help?</h4>
            <p>We're here to help! Please provide details about the issue you're encountering.</p>
            <form [formGroup]="form">
              <div class="mb-3">
                <label for="requestType" class="col-form-label">Request Type:</label>
                <select class="form-control form-control" name="requestType" formControlName="supportRequestType">
                  <option value="" selected disabled i18n>Select</option>
                  <option *ngFor="let value of requestTypeOptions" [ngValue]="value">
                    {{ value | titlecase}}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label class="col-form-label">Please describe your issue</label>
                <textarea class="form-control" formControlName="issueText" rows="3"></textarea>
                <div *ngIf="issueText.invalid && issueText.touched" class="invalid-feedback">
                  Please describe your issue.
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="formSend" class="modal-body">
            <h4 class="text-center"> Successfully created support Request ✔️</h4>
            <p>We will take care of your issue as soon as possible and will inform you as soon as possible.</p><br>
          </div>
          <div *ngIf="formError" class="modal-body">
            <h4 class="text-center"> Whoops! Something went wrong. </h4>
            <p>We are sorry! It seems that somthing went wrong. Please try again later or send us an email with your
              issue <a href="mailto:help@adcolabs.com"><b>help&#64;adcolabs.com</b></a> </p><br>
          </div>
          <div class="p-3">
            <button *ngIf="!formSend && !formError" (click)="sendForm()" [disabled]="form.invalid" type="button"
              class="btn btn-primary">Create Support Ticket</button>
          </div>
        </div>
        <div class="tab-pane fade container" id="tickets" role="tabpanel" aria-labelledby="tickets-tab">
          <div class="listcontainer overflow-auto" style="max-height: 200px;">
            <div class="list-group p-3">
              <div class="row">
                <div class="list-group-item list-group-item-action">
                  <div class="row">
                    <div class="col-md-1">
                      <b>#</b>
                    </div>
                    <div class="col-md-3">
                      <b>Status</b>
                    </div>
                    <div class="col-md-2">
                      <b>Type</b>
                    </div>
                    <div class="col-md-4">
                      <b>Created</b>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngFor="let item of supportList let indexOfelement=index" class="row">
                <div class="list-group-item list-group-item-action" (click)="selectItem(item)">
                  <div class="row">
                    <div class="col-md-1">
                      {{indexOfelement+1}}
                    </div>
                    <div class="col-md-3">
                      {{item.supportStatus | titlecase}}
                    </div>
                    <div class="col-md-2">
                      {{item.supportRequestType | titlecase}}
                    </div>
                    <div class="col-md-4">
                      {{item.created | date :'dd.MM.yyyy HH:mm'}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 p-3" style="min-height:300px">
            <div class="card">
              <div *ngIf="supportList.length==0" class="card-body">
                <h4 class="card-title text-muted">Not Tickets Available </h4>
              </div>
              <div *ngIf="supportList.length>0" class="card-body">
                <div class="row">
                  <h4 class="card-title text-muted">Ticket-{{selectedSupportRequest?.id}} </h4>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <b>Created: </b>  
                  </div>
                  <div class="col-md-9">
                    {{selectedSupportRequest?.created | date: 'dd.MM.yyyy HH:mm'}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <b>Type:</b>  
                  </div>
                  <div class="col-md-9">
                      {{selectedSupportRequest?.supportRequestType | titlecase}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <b>Status: </b>
                  </div>
                  <div class="col-md-9">
                    {{selectedSupportRequest?.supportStatus | titlecase}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <b>You wrote: </b>
                  </div>
                  <div class="col-md-9">
                    <p>{{selectedSupportRequest?.issueText}}</p>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="clearForm()" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<ngx-spinner type="ball-pulse" bdColor="rgba(0, 0, 0, 0.6)" size="medium" color="#fff" [fullScreen]="true">
</ngx-spinner>