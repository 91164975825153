import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { KeycloakService } from 'keycloak-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { MetricsDto } from '../_model/MetricsDto';

import { UserDataDto } from '../_model/UserDataDto';
import { MetricsService } from '../_service/metrics.service';
import { UserDataService } from '../_service/userdata.service';
import { NewsDTO, NewsType } from '../_model/NewsDTO';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private userDataService:UserDataService,
              private metricsService:MetricsService,
              private keycloakService:KeycloakService,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) { }

  userData:UserDataDto={} as UserDataDto;
  newsDTO: NewsDTO[] = undefined;
  createNewsDTO:NewsDTO ={
    headline:"",
    created:undefined,
    createdBy:"",
    newsText:"",
    newsType: undefined
  }
  NewsTypes = Object.values(NewsType);
  form: FormGroup;
  usedPercentage: string;
  metricsLastDays:number[] = [];
  metricsExtractionStatus: number[] = [];
  metricsReady=false;
  lastThirtyDays:string[] = [];
  metrics: MetricsDto ={
    extractionsLastThirtyDays: [],
    totalExtractions: 0,
    todayExtractions: 0,
    activeScraper: 0,    
    createdExtractions: 0,
    waitingExtractions: 0,
    errorExtractions: 0,
    doneExtractions: 0
  }
  isAdmin = false;

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: this.metricsLastDays,
        label: 'Extractions per day',
        backgroundColor: 'rgba(213, 53, 53, 0.2)',
        borderColor: 'rgba(213, 53, 53, 1)',
        pointBackgroundColor: 'rgba(213, 53, 53, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(213, 53, 53, 1)',
        fill: 'origin',
      }
    ],
    labels: []
  };

  public barChartData: ChartConfiguration['data'] = {
    labels: ['Waiting','Error', 'Done', 'Created'],
    datasets: [{
        data: this.metricsExtractionStatus,
        label: 'Extractionstatus Overall',
        backgroundColor:[
          'rgba(48, 86, 211, 1)',
          'rgba(188, 4, 18, 1)',
          'rgba(0, 171, 76, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderColor: [
          'rgba(48, 86, 211, 1)',
          'rgba(188, 4, 18, 1)',
          'rgba(0, 171, 76, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        hoverBackgroundColor: [
          'rgba(48, 86, 211, 1)',
          'rgba(188, 4, 18, 1)',
          'rgba(0, 171, 76, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        hoverBorderColor: [
          'rgba(48, 86, 211, 1)',
          'rgba(188, 4, 18, 1)',
          'rgba(0, 171, 76, 1)',
          'rgba(255, 206, 86, 1)',
        ]
      }],
  }

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: { line: { tension: 0.4 } },
    scales: {
      y:{beginAtZero: true} },
  };

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
       y:{beginAtZero: true}
      },
  };

  public lineChartType: ChartType = 'line';
  public barChartType: ChartType = 'bar';

  loggedIn = false;
  userName = '';
  
  ngOnInit(): void {
    this.isAdmin = this.keycloakService.isUserInRole('admin')
    this.getLastThirtyDays();
    this.fetchUserData();
    this.fetchMetrics();
    this.userName =  this.keycloakService.getUsername();
    this.initForm();
  }

  chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
  }

  initForm(){
    this.form = this.fb.group({
      headline: [{ value: '', disabled: false }, Validators.required],
      newsText: [{ value: '', disabled: false }, Validators.required],
      newsType: [{value: '', disbaled: false}, Validators.required],
      createdBy: []
    })
  }

  async fetchUserData(){
    this.spinner.show();
    this.userDataService.getUserDataForDashbaord()
      .subscribe(
        res => {
        this.userData = res;
        this.spinner.hide();
      },(error)=>{
        this.spinner.hide();
      })

    this.userDataService.getNews()
    .subscribe(
      res=>{
        this.newsDTO = res;
        this.newsDTO = this.newsDTO.slice().reverse();
      },
      error=>{
        console.log(error)
      }
    )
  }

  async fetchMetrics(){
    this.metricsService.getMetrics()
    .subscribe(
      res=>{
        this.metrics = res;
        res.extractionsLastThirtyDays?.forEach(ltd=>{
          this.metricsLastDays.push(ltd)
          this.metricsExtractionStatus.push(res.waitingExtractions,res.errorExtractions,res.doneExtractions,res.createdExtractions)
          this.metricsReady = true;
        })
      }
    )
  }

  getLastThirtyDays(){
    for(let i = 0;i< 15; i++){
      const day = new Date()
      day.setDate(day.getDate()-i)
      this.lastThirtyDays.push((day.getMonth()+1)+'/'+day.getDate())
    }
    this.lastThirtyDays.reverse();
    this.lastThirtyDays.forEach(s=>{this.lineChartData.labels.push(s.toString())})
  }

  calcPercentage(used:number,limit:number){
    this.usedPercentage = ((used/limit)*100).toFixed(2)
  }  

  async createNews(){
    this.createNewsDTO.headline = this.form.get('headline').value;
    this.createNewsDTO.newsText = this.form.get('newsText').value;
    this.createNewsDTO.newsType = this.form.get('newsType').value
    this.createNewsDTO.createdBy = this.keycloakService.getUsername();
    this.userDataService.createNews(this.createNewsDTO).subscribe({
      next:()=>{
        this.clearForm();
        this.fetchUserData()
      }, error:(err)=>{
        console.log(err)
      }
    });
  }

  clearForm(){
    this.createNewsDTO.headline = ''
    this.createNewsDTO.newsText = ''
    this.form.get('headline').patchValue('')
    this.form.get('newsText').patchValue('')
    this.form.get('newsType').patchValue('')
  }

}

