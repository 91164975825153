<div class="bg-light">
    <app-navbar></app-navbar>
    <div class="container-fluid">
        <div class="row"> 
            <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                <app-sidebar></app-sidebar>
            </nav>       
            <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <router-outlet></router-outlet>
            </main>
            <app-support *ngIf="userHasSupportLevel"></app-support>
        </div>
    </div>
    <app-footer></app-footer>
</div>