import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  authUrl:string = environment.KEYCLOAK_URL;

  constructor(private readonly keycloakService:KeycloakService) { }


  ngOnInit(): void {
  }


  accountSettings(){
    window.open(this.authUrl+"/realms/adcolabs-scraper/account/","_blank")
  }

}
