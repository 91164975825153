import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactUsDTO } from 'src/app/_model/CreateSupportRequestDto';
import { SupportService } from 'src/app/_service/support.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  
  form: FormGroup;
  formError:boolean = false;
  formSend:boolean = false;

  constructor(private fb: FormBuilder,
              private supportService:SupportService,
              private spinner: NgxSpinnerService){
      
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(){
    this.form = this.fb.group({
      email:['', [Validators.required, Validators.email]],
      name:['', [Validators.required]],
      phone:[''],
      company:[''],
      message:['', [Validators.required]]
    })
  }

  contactUs(){
    this.spinner.show();
    this.supportService.createContactRequest(new ContactUsDTO(this.email.value,this.name.value,this.company.value,this.phone.value,this.message.value)).subscribe({
      next: (v) => {
        this.spinner.hide()
        this.formSend = true
        this.form.reset()
      },
      error: (e) => {
        this.spinner.hide()
        this.formError=true;
        console.log('ERROR:'+e.message)
      }
    })
  }

  close(){
    this.formError=false;
    this.formSend=false;
    this.form.reset()
  }

  get email(){
    return this.form.get("email")
  }
  get name(){
    return this.form.get("name")
  }
  get phone(){
    return this.form.get("phone")
  }
  get company(){
    return this.form.get("company")
  }
  get message(){
    return this.form.get("message")
  }

}
