import { Injectable } from "@angular/core";
import { BaseService } from "./base-service";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ApiConfiguration } from "./api-configuration";
import { Extractor } from "../_model/Extractor";
import { Observable } from "rxjs";

@Injectable({
    providedIn:'root',
})
export class ExtractorService extends BaseService{
    private extractorEndpoint = '/extractors';
    private RootUrl = environment.SCRAPER_UI_SERVICE + this.extractorEndpoint;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        }),
    };

    constructor(config:ApiConfiguration, http:HttpClient){
        super(config, http);
    }

    getExtractors():Observable<Extractor[]>{
        return this.http.get<Extractor[]>(this.RootUrl)
    }

    getAddedExtractors():Observable<Extractor[]>{
        return this.http.get<Extractor[]>(this.RootUrl+ '/added')
    }

    getExtractorById(id:string):Observable<Extractor> {
        return this.http.get<Extractor>(this.RootUrl + '/' + id)
    }

    createExtractor(extractor:Extractor):Observable<Extractor> {
        return this.http.post<Extractor>(this.RootUrl, extractor);
    }

    removeExtractr(id:string):Observable<void> {
        return this.http.delete<void>(this.RootUrl + '/' + id);
    }

    enableExtractor(extractorId:string, enabled:boolean): Observable<Extractor> {
        const params = new HttpParams().set('enabled', enabled)
        return this.http.patch<Extractor>(this.RootUrl + '/' + extractorId + '/enabled', params);
    }
}
