import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiConfiguration } from "./api-configuration";
import { BaseService } from "./base-service";


@Injectable({
    providedIn:'root',
})
export class PaymentService extends BaseService{
    RootPaymentUrl = environment.PAYMENT_URL;
    //Subscription Price ids
    PRICE_BASIC_ID = environment.PRICE_BASIC_ID;
    PRICE_PRO_ID = environment.PRICE_PRO_ID;
    PRICE_1k_SCRAPES_ID = environment.PRICE_1k_SCRAPES_ID;
    PRICE_4k_SCRAPES_ID = environment.PRICE_4k_SCRAPES_ID;
    PRICE_8k_SCRAPES_ID = environment.PRICE_8k_SCRAPES_ID;
    
    formdataBasic: any = new FormData();
    formdataPro: any = new FormData();
    //Single Item price ids
    formdata1kScrapes: any = new FormData();
    formdata4kScrapes: any = new FormData();
    formdata8kScrapes: any = new FormData();
    //For Portal Session
    formDataPortalSession: any = new FormData();

    constructor(config:ApiConfiguration, http:HttpClient){
        super(config,http);
        this.formdataBasic.append('price_id',this.PRICE_BASIC_ID);
        this.formdataPro.append('price_id',this.PRICE_PRO_ID);
        this.formdata1kScrapes.append('price_id',this.PRICE_1k_SCRAPES_ID)
        this.formdata4kScrapes.append('price_id',this.PRICE_4k_SCRAPES_ID)
        this.formdata8kScrapes.append('price_id',this.PRICE_8k_SCRAPES_ID)
    }

    initiateBasicPlan():Observable<any>{
        return this.http.post<any>(this.RootPaymentUrl + '/create-checkout-session',this.formdataBasic)
    }

    initiateProPlan():Observable<any>{
        return this.http.post<any>(this.RootPaymentUrl + '/create-checkout-session',this.formdataPro)
    }


    changeSubscription(newSubsciption: string):Observable<any>{
        const formDataToSend = newSubsciption === 'BASIC' ? this.formdataBasic : this.formdataPro;
        return this.http.post<any>(this.RootPaymentUrl + '/change-subscription', {price_id: formDataToSend.get("price_id")})
    }

    createPortalSession(session_id: string):Observable<any>{
        this.formDataPortalSession.append('session_id',session_id)
        return this.http.post<any>(this.RootPaymentUrl + '/create-portal-session', this.formDataPortalSession)
    }

    createPortalSessionForUser():Observable<any>{
        return this.http.get<any>(this.RootPaymentUrl + '/create-user-portal-session')
    }
    
    initiate1kaquire():Observable<any>{
        return this.http.post<any>(this.RootPaymentUrl + '/create-checkout-session',this.formdata1kScrapes)
    }
    initiate4kaquire():Observable<any>{
        return this.http.post<any>(this.RootPaymentUrl + '/create-checkout-session',this.formdata4kScrapes)
    }
    initiate8kaquire():Observable<any>{
        return this.http.post<any>(this.RootPaymentUrl + '/create-checkout-session',this.formdata8kScrapes)
    }

}