import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScrapeComponent } from './scrape/scrape.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateScrapeComponent } from './scrape/create-scrape/create-scrape.component';
import { ViewScrapeComponent } from './scrape/view-scrape/view-scrape.component';
import { FooterComponent } from './_shared/footer/footer.component';
import { NavbarComponent } from './_shared/navbar/navbar.component';
import { SidebarComponent } from './_shared/sidebar/sidebar.component';
import { ApiComponent } from './api/api.component';
import { PaymentComponent } from './payment/payment.component';
import { ErrorPagesComponent } from './error-pages/error-pages.component';
import { initializeKeycloak } from './_utility/app.init';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DataService } from './_utility/data.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SuccessComponent } from './payment/success/success.component';
import { CancelComponent } from './payment/cancel/cancel.component';

import { SupportComponent } from './support/support.component';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';
import { AccountComponent } from './account/account.component';
import { WorkflowTransformPipe } from './pipes/workflow.pipe';
import { ScraperComponent } from './scrape/scraper/scraper.component';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { ScraperExtractionsComponent } from './scrape/scraper/scraper-extractions/scraper-extractions.component';
import { HeaderPipe } from './pipes/header.pipe';
import { Thumbnail } from './pipes/screenhsot-thumbnail.pipe';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { ContactUsComponent } from './_shared/contact-us/contact-us.component';
import { CreateExtractionComponent } from './scrape/extraction/create-extraction/create-extraction.component';
import { ExtractorsComponent } from './extractors/extractors.component';

const monacoConfig: NgxMonacoEditorConfig = {
  baseUrl: './assets', // configure base path for monaco editor. Starting with version 8.0.0 it defaults to './assets'. Previous releases default to '/assets'
  defaultOptions: { scrollBeyondLastLine: false }, // pass default options to be used
  //onMonacoLoad: () => { console.log((<any>window).monaco); } // here monaco object will be available as window.monaco use this function to extend monaco editor functionalities.
};


@NgModule({ declarations: [
        AppComponent,
        ScrapeComponent,
        DashboardComponent,
        CreateScrapeComponent,
        CreateExtractionComponent,
        ViewScrapeComponent,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ApiComponent,
        PaymentComponent,
        ErrorPagesComponent,
        SuccessComponent,
        CancelComponent,
        SupportComponent,
        AccountComponent,
        WorkflowTransformPipe,
        HeaderPipe,
        Thumbnail,
        ScraperComponent,
        ScraperExtractionsComponent,
        EnterpriseComponent,
        ContactUsComponent,
        ExtractorsComponent
    ],
    exports: [
        NgxSpinnerModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        AppRoutingModule,
        KeycloakAngularModule,
        NgChartsModule,
        NgxPaginationModule,
        ClipboardModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        MonacoEditorModule.forRoot(monacoConfig),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        HighlightModule], providers: [
        DataService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        { provide: HIGHLIGHT_OPTIONS,
            useValue: {
                fullLibraryLoader: () => import('highlight.js'),
            }
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
