import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Toast } from 'bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { finalize } from 'rxjs';
import { ApiDataDto } from '../_model/ApiDataDto';
import { ApiStatus } from '../_model/ApiStatus';
import { ApiService } from '../_service/api.service';
import { HighlightLoader,HighlightAutoResult } from 'ngx-highlightjs';


@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.scss']
})

export class ApiComponent implements OnInit {
  @ViewChild('myToast',{static:true}) toastEl!: ElementRef<HTMLDivElement>;
  toast: Toast | null = null;
  response: HighlightAutoResult;
  apiData:ApiDataDto={
    apiUrl: '',
    apiKey: '',
    apiStatus: new ApiStatus
  }

  bootstrap: any;
  _toast={
    heading:"",
    message:"",
    time:"",
    visibility:"hidden"
  }
  
  selectedClient="curl";

  curlExample ="";
  httpieExample="";
  javaExample="";
  nodejsExample="";
  pythonExample="";
  kotlinExample="";
  phpExample="";

  loading=false;

  constructor(private apiService:ApiService,
              private clipboardApi: ClipboardService,
              private hljsLoader: HighlightLoader) { }

  ngOnInit(): void {
    this.toast = new Toast(this.toastEl.nativeElement,{});
    this.fetchCrawlData();
  }

  async fetchCrawlData(){
    this.apiService.getApiData().pipe(finalize(() => this.loading = false)).subscribe(data => {
      this.apiData = data
      this.initCodeExamples(data);
    })
  }

  async resetApiKey(){
    await this.apiService.resetApiKey(this.apiData).subscribe(
      (data: ApiDataDto)=>{
        this.apiData.apiKey = data.apiKey;
        this.apiData.apiUrl = data.apiUrl;
      }
    )
  }

  copyText(content:string){
    this.clipboardApi.copyFromContent(content)
    this._toast.heading= "Copied text";
    this.toast!.show();
    this._toast.message= content;
    this._toast.visibility= "show";
    setTimeout(()=>{this._toast.visibility="hide"},5000)
  }

  show(){
    this.toast!.show();
  }
  
  selectClient(_event:any){
    this.selectedClient=_event.target.value
  }

  onHighlight(e: HighlightAutoResult) {
    this.response = {
      language: e.language,
      relevance: e.relevance,
      secondBest: '{...}',
      value: '{...}',
    };
  }

  initCodeExamples(apiData:ApiDataDto){
    this.curlExample=`curl `+ apiData.apiUrl + `/scraper -H 'API-KEY:`+ apiData.apiKey + `'`
    this.httpieExample= "http "+ apiData.apiUrl +"/scraper API-KEY:"+ apiData.apiKey;
    this.javaExample=`import java.net.URI;
import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;
    
    
    var httpClient = HttpClient.newHttpClient();
    var req = HttpRequest.newBuilder()
                .uri(URI.create("`+ apiData.apiUrl +`/scraper"))
                .header("API-KEY","`+ apiData.apiKey +`")
                .build();
    httpClient.sendAsync(req, HttpResponse.BodyHandlers.ofString())
            .thenApply(HttpResponse::body)
            .thenAccept(System.out::println)
            .join();`
    this.nodejsExample=`const http = require('http');

http.get({
        host: '`+ apiData.apiUrl +`/scraper',
        path: '/v1/scraper',
        headers: { 'API-KEY': '`+ apiData.apiKey +`' },
    },
    (res) => {
        res.setEncoding('utf8');
        let rawData = '';
        res.on('data', (chunk) => { rawData += chunk; });
        res.on('end', () => {
            try {
                const parsedData = JSON.parse(rawData);
                console.log(parsedData);
            } catch (e) {
                console.error(e.message);
            }
        });
    },
    (err) => {
        console.log(err);
    }
);`
    this.pythonExample=`import requests
url = "`+ apiData.apiUrl +`/scraper"
headers = {
  'API-KEY': '`+ apiData.apiKey +`'
}
data = requests.get(url=url, headers=headers)
print(data.json)`
    this.phpExample=`<?php
  $url = "`+ apiData.apiUrl +`/scraper";
  $headers = array('API-KEY: `+ apiData.apiKey +`');

  $ch = curl_init();
  curl_setopt($ch, CURLOPT_URL, $url);
  curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
  curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);

  $response = curl_exec($ch);
  $httpcode = curl_getinfo($ch, CURLINFO_HTTP_CODE);

  if ($httpcode == 200) {
    echo $response;
  } else {
    echo "Error: " . $httpcode;
  }

  curl_close($ch);
?>`
      this.kotlinExample=`import java.net.HttpURLConnection
import java.net.URL
      
  fun main() {
      val url = URL("`+ apiData.apiUrl +`/scraper")
      val connection = url.openConnection() as HttpURLConnection
  
      connection.requestMethod = "GET"
      connection.setRequestProperty("API-KEY", "`+ apiData.apiKey +`")
  
      val responseCode = connection.responseCode
  
      if (responseCode == 200) {
          val response = connection.inputStream.bufferedReader().readText()
          println(response)
      } else {
          println("Error: $responseCode")
      }
  
      connection.disconnect()
  }`
  }

}
