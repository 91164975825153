import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserTierLevel } from '../_model/UserTierLevelDto';
import { PaymentService } from '../_service/payment.service';
import { UserDataService } from '../_service/userdata.service';
import { finalize } from 'rxjs';
import { TierLevel } from '../_model/enum/TierLevel';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  usertierLevel: TierLevel;
  changedSubscriptionCurrentPeriod: boolean;
  confirmUpgrade: boolean;
  confirChangeSub: boolean;

  constructor(private paymentService:PaymentService, 
              private router: Router,
              private spinner: NgxSpinnerService,
              private userdataService: UserDataService ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.userdataService.getPaymentUserData().subscribe(
      {
        next: (res) => {
          this.usertierLevel = res.tierlevel;
          this.changedSubscriptionCurrentPeriod = res.changedSubscriptionCurrentPeriod
          this.spinner.hide();
        },
        error: (err) => {
          this.spinner.hide();
        }
      }
    )
  }

 async subscribeBasic(){
  this.spinner.show();
  this.paymentService.initiateBasicPlan()
  .subscribe(
    {
      next:(res) =>{
        window.location.href = res;
        this.spinner.hide()
      },
      error: (err) =>{
        this.spinner.hide();
        this.router.navigate(['/payment-cancel'])
      }
    }
  )
} 

 async subscribePro(){
  this.spinner.show();
  this.paymentService.initiateProPlan()
  .subscribe(
    {
      next:(res) =>{
        window.location.href = res;
        this.spinner.hide()
      },
      error: (err) =>{
        this.spinner.hide();
        this.router.navigate(['/payment-cancel'])
      }
    }
  )
} 

async changeSubscription(changeSubscription: string){
  this.spinner.show();
  this.paymentService.changeSubscription(changeSubscription).pipe(finalize(()=> this.spinner.hide()))
  .subscribe(
    {
      next:(res) =>{
        window.location.href = res;
      },
      error: (err) =>{
        this.router.navigate(['/payment-cancel'])
      }
    }
  )
}


async purchase1kScrapes(){
  this.spinner.show();
  this.paymentService.initiate1kaquire().subscribe(
    {
      next: (res) => {
        window.location.href=res
        this.spinner.hide();
      },
      error: (err) => {
        this.spinner.hide();
        this.router.navigate(['/payment-cancel'])
      }
    }
  )
}

async purchase4kScrapes(){
  this.spinner.show();
  this.paymentService.initiate4kaquire().subscribe(
    {
      next: (res) => {
        window.location.href=res
        this.spinner.hide();
      },
      error: (err) => {
        this.spinner.hide();
        this.router.navigate(['/payment-cancel'])
      }
    }
  )
}

async purchase8kScrapes(){
  this.spinner.show();
  this.paymentService.initiate8kaquire().subscribe(
    {
      next: (res) => {
        window.location.href=res
        this.spinner.hide();
      },
      error: (err) => {
        this.spinner.hide();
        this.router.navigate(['/payment-cancel'])
      }
    }
  )
}

startPortalSesstion(){
  this.spinner.show();
  this.paymentService.createPortalSessionForUser().subscribe(
    {
      next: (res) => {
        window.location.href=res
        this.spinner.hide();
      },
      error: (err) => {
        this.spinner.hide();
      }
    }
  )
}

}
