<form [formGroup]="form" (ngSubmit)="submitData()" novalidate>
  <div class="row">
    <div class="grid-margin col-md-12">
      <div class="row">
        <!-- Begin Row -->
        <!-- Begin Scrape URL and Browser Selection -->
        <div class="col-md-4 my-2">
          <div class="card shadow-sm h-100">
            <div class="card-body flex-column">
              <h3 class="card-title">Create Extraction</h3>
              <div class="alert alert-primary" role="alert">
                <div>
                  <small>An extraction is a single process which visits the website you specify to scrape data from it.
                    See our <a href="https://docs.scraper.adcolabs.de/docs/introduction/overview">docs</a> to find out
                    more about <a
                      href="https://docs.scraper.adcolabs.de/docs/Adcolabs-Scraper%20Web/Scrape/create/extraction">extractions</a>.</small>
                </div>
              </div>
              <div class="form-group">
                <div class="col-8 mb-3">
                  <label class="form-label text-muted" for="url" i18n>URL</label>
                  <input type="text" formControlName="url" class="form-control" name="url"
                    [ngClass]="{'is-invalid':url.touched && url.invalid,'is-valid':url.touched && url.valid}" />
                  <span id="scraperNameHelper" class="form-text">
                    Enter the url to the webside you want to scrape
                  </span>
                  <div *ngIf="url.touched && url.invalid" class="invalid-feedback">
                    Please enter a valid url.
                  </div>
                </div>
              </div>
              <hr>

              <div class="form-group">
                <div class="col-12 mb-1">
                  <label class="form-label text-muted" for="url" i18n>Extractions</label>
                  <div class="col-md-12">
                    <div class="form-group my-2">
                      <div class="form-check form-switch">
                        <input [formControl]="checkboxControl" type="checkbox" class="form-check-input"
                          (change)="onChangeFullExtraction($event)" checked />
                        <label class="form-check-label text-muted" i18n for="checkboxControl">
                          Full Extraction
                        </label>
                      </div>
                      <small class="text-muted">The complete HTML Document after rendering the page will be
                        extracted</small>
                    </div>
                  </div>
                  <hr>
                  <div class="col-md-12" formArrayName="selectors">
                    <label for="fullExtraction" class="label-description text-muted mb-3" i18n>Selectors and
                      Extractors</label>
                    <div class="row">
                      <div class="col-md-3 col-sm-3 col-3">
                        <select class="form-control" name="action" (change)="selecetExtractionType($event)">
                          <option value="" selected disabled i18n>Select</option>
                          <option *ngFor="let extractionType of extractionTypes">
                            {{ extractionType | titlecase}}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-7 col-sm-7 col-7">
                        <input #inputValueRef type="text" class="form-control col-md-12"
                          placeholder="Selector expression" (keyup)="onInputChange()"
                          [disabled]="selectedExtractionType!=='Regex'" />
                      </div>
                      <div class="col-md-1 col-sm-1 col-1" style="padding-left: 0px;">
                        <button class="btn btn-outline-primary addButton start" type="button"
                          (click)="addExtraction(inputValueRef)"
                          [disabled]="!addButtonExpressionChecker(selectedExtractionType, inputValueRef.value)">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-md-12">
                      <div>
                        <div *ngIf="filteredExtractions().length > 0" class="card my-2">
                          <ul class="list-group list-group-flush">
                            <li *ngFor="let extraction of filteredExtractions(); let i = index" class="list-group-item">
                              <div class="row">
                                <div *ngIf="extraction.value.type === 'full'" class="col-10 text-muted"
                                  style="display: flex; align-items: center;">
                                  <b class="text-muted">Full Extraction</b>
                                </div>
                                <div *ngIf="extraction.value.type !== 'full'" class="col-10"
                                  style="display: flex; align-items: center;">
                                  <div class="col-12 text-muted"><b>{{ extraction.value.type }}</b></div>
                                </div>
                                <div class="col-md-1 col-sm-1 col-1">
                                  <div class="btn btn-outline-danger"
                                    (click)="deleteExtractionByValue(extraction.value)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                      class="bi bi-trash" viewBox="0 0 16 16">
                                      <path
                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                      <path fill-rule="evenodd"
                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <!-- Begin Row-->
                <div class="form-group bottom-0 pt-4">
                  <div class="col-md-12 col-sm-12 col-12 my-3">
                    <button class="btn btn-primary create-button float-start mt-auto" [disabled]="form.invalid" i18n>
                      Create
                    </button>
                    <button class="btn btn-outline-secondary mx-1 mt-auto" (click)="cancel()" i18n>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Row -->
          </div>
        </div>
        <!-- End Scrape URL and Browser Selection -->

        <!-- Begin Extraction-->
        <div class="col-md-4 my-2">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <h5 class="card-title text-muted mb-3" i18n><b>Configuration</b></h5>
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <!-- Begin  Browser Workflow -->
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      Workflow
                    </button>
                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <!-- Begin Workflos -->
                      <div class="col-md-12">
                        <div class="form-group">
                          <div class="container center">
                            <div class="row">
                              <div class="col-md-4 col-sm-4 col-4" style="padding-left: 0px;">
                                <select class="form-control" name="action" (change)="selectOption($event)">
                                  <option selected disabled i18n>Select</option>
                                  <option *ngFor="let parameter of parameters" value="{{parameter}}">
                                    {{ parameter | titlecase}}
                                  </option>
                                </select>
                              </div>
                              <div *ngIf="selectedOption === 'scroll'" class="col-md-5 col-4 col-sm-4">
                                <div class="input-group col-md-6 col-6 col-sm-6"
                                  formGroupname="browserActionsFormScroll">
                                  <input type="text" class="form-control" placeholder="x" [value]="scrollXValue"
                                    (keyup)="scrollXValueOnKeyUp($event)"
                                    [ngClass]="{'is-invalid':scrollYValue === null && scrollXValue === null ,'is-valid': scrollXValue || scrollYValue}" />
                                  <input type="text" class="form-control" placeholder="y" [value]="scrollYValue"
                                    (keyup)="scrollYValueOnKeyUp($event)"
                                    [ngClass]="{'is-invalid':scrollYValue === null && scrollXValue === null ,'is-valid': scrollXValue || scrollYValue}" />
                                </div>
                              </div>
                              <div *ngIf="selectedOption === 'click'" class="col-md-5 col-4 col-sm-4">
                                <div class="input-group col-md-6 col-6 col-sm-6">
                                  <input type="text" class="form-control" placeholder="x" [value]="clickXValue"
                                    (keyup)="clickXValueOnKeyUp($event)"
                                    [ngClass]="{'is-invalid':clickYValue === null && clickXValue === null ,'is-valid': clickXValue || clickYValue}" />
                                  <input type="text" class="form-control" placeholder="y" [value]="clickYValue"
                                    (keyup)="clickYValueOnKeyUp($event)"
                                    [ngClass]="{'is-invalid':clickYValue === null && clickXValue === null ,'is-valid': clickXValue || clickYValue}" />
                                </div>
                              </div>
                              <div *ngIf="selectedOption === 'wait'" class="col-md-5 col-5 col-sm-5">
                                <div class="input-group col-md-6 col-6 col-sm-6">
                                  <span class="input-group-text">Sec</span>
                                  <input type="text" class="form-control col-md-5 col-5 col-sm-5" placeholder="10"
                                    (keyup)="waitValueOnKeyUp($event)" [value]="waitValue"
                                    [ngClass]="{'is-invalid': waitValue === null || waitValue <= 0 ,'is-valid': waitValue > 0}" />
                                </div>
                              </div>
                              <div class="col-md-2 col-2 col-sm-2 ms-auto justify-content-center"
                                style="padding-left: 0px;">
                                <button *ngIf="selectedOption === 'click'" type="button"
                                  class="btn btn-outline-primary addButton"
                                  [disabled]="workflowArray.value.length > 4 || ((clickXValue === null  && clickYValue === null) || (clickXValue <= 0 && clickYValue <= 0 ))"
                                  (click)="addAction()" i18n>
                                  Add
                                </button>
                                <button *ngIf="selectedOption === 'scroll'" type="button"
                                  class="btn btn-outline-primary addButton"
                                  [disabled]="workflowArray.value.length > 4 || ((scrollYValue === null  && scrollXValue === null) || (scrollXValue <= 0 && scrollYValue <= 0 )) "
                                  (click)="addAction()" i18n>
                                  Add
                                </button>
                                <button *ngIf="selectedOption === 'wait'" type="button"
                                  class="btn btn-outline-primary addButton"
                                  [disabled]="workflowArray.value.length > 4  || (waitValue === null ||  waitValue <= 0 )"
                                  (click)="addAction()" i18n>
                                  Add
                                </button>
                                <button *ngIf="selectedOption === 'screenshot'" type="button"
                                  class="btn btn-outline-primary addButton" [disabled]="workflowArray.value.length > 4 "
                                  (click)="addAction()" i18n>
                                  Add
                                </button>
                                <button *ngIf="selectedOption === 'full-page-screenshot'" type="button"
                                  class="btn btn-outline-primary addButton" [disabled]="workflowArray.value.length > 4 "
                                  (click)="addAction()" i18n>
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div>
                                <div *ngIf="workflowArray.value.length > 0" class="card my-2">
                                  <ul class="list-group list-group-flush">
                                    <li *ngFor=" let browserAction of workflowArray.value; let i = index "
                                      class="list-group-item">
                                      <div class="row">
                                        <div *ngIf="browserAction.action === 'screenshot' " class="col-10"
                                          style=" display: flex; align-items: center; ">
                                          <div class="col-5">
                                            <b>{{ browserAction.action | titlecase}}</b>
                                          </div>
                                        </div>
                                        <div *ngIf=" browserAction.action === 'scroll' " class="col-10"
                                          style=" display: flex; align-items: center; ">
                                          <div class="col-3">
                                            <b>{{ browserAction.action | titlecase}}:</b>
                                          </div>
                                          <div class="col-9">X: {{ browserAction.options.x}}, Y: {{
                                            browserAction.options.y }}</div>
                                        </div>
                                        <div *ngIf=" browserAction.action === 'click' " class="col-10"
                                          style=" display: flex; align-items: center;">
                                          <div class="col-3">
                                            <b>{{ browserAction.action | titlecase}}:</b>
                                          </div>
                                          <div class="col-9">
                                            X: {{ browserAction.options.x }}, Y: {{ browserAction.options.y }}
                                          </div>
                                        </div>
                                        <div *ngIf=" browserAction.action === 'wait' " class="col-10"
                                          style=" display: flex; align-items: center; ">
                                          <div class="col-3">
                                            <b>{{ browserAction.action | titlecase}}:</b>
                                          </div>
                                          <div class="col-9">
                                            {{ browserAction.value }} Seconds
                                          </div>
                                        </div>
                                        <div *ngIf=" browserAction.action === 'full-page-screenshot' " class="col-10"
                                          style=" display: flex; align-items: center; ">
                                          <div class="col-8">
                                            <b>Full Screenshot</b>
                                          </div>
                                        </div>
                                        <div class="col-2">
                                          <button type="button" class="btn btn-outline-danger mx-1 float-end"
                                            (click)="deleteBrowserAction(i)" i18n>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                              fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                              <path
                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                              <path fill-rule="evenodd"
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- End Actions -->
                    </div>
                  </div>
                </div>
                <!-- End Browser Actions -->

                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      Resolution
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <!-- Begin Options -->
                      <div class="col-md-12 col-12 col-sm-12 p-1" formGroupName="agent">
                        <div class="form-group" formGroupName="resolution">
                          <div class="row">
                            <div class="col-md-5 col-sm-5 col-5">
                              <button type="button" class="btn btn-outline-primary" (click)="setMobileResolution()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                  class="bi bi-phone" viewBox="0 0 16 16">
                                  <path
                                    d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z">
                                  </path>
                                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
                                </svg>
                                Mobile
                              </button>
                            </div>
                            <div class="col-md-7 col-sm-7 col-7">
                              <div class="input-group p-1">
                                <span class="input-group-text" style="width: 80px" i18n>Width</span>
                                <input formControlName="width" aria-label="width" class="form-control"
                                  [ngClass]="{'is-invalid':width.invalid && width.touched,'is-valid': width.valid && width.touched}" />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5 col-sm-5 col-5">
                              <button type="button" class="btn btn-outline-primary" (click)="setDesktopResolution()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                  class="bi bi-display" viewBox="0 0 16 16">
                                  <path
                                    d="M0 4s0-2 2-2h12s2 0 2 2v6s0 2-2 2h-4c0 .667.083 1.167.25 1.5H11a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1h.75c.167-.333.25-.833.25-1.5H2s-2 0-2-2V4zm1.398-.855a.758.758 0 0 0-.254.302A1.46 1.46 0 0 0 1 4.01V10c0 .325.078.502.145.602.07.105.17.188.302.254a1.464 1.464 0 0 0 .538.143L2.01 11H14c.325 0 .502-.078.602-.145a.758.758 0 0 0 .254-.302 1.464 1.464 0 0 0 .143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.757.757 0 0 0-.302-.254A1.46 1.46 0 0 0 13.99 3H2c-.325 0-.502.078-.602.145z">
                                  </path>
                                </svg>
                                Desktop
                              </button>
                            </div>
                            <div class="col-md-7 col-sm-7 col-7">
                              <div class="input-group p-1">
                                <span class="input-group-text" style="width: 80px" i18n>Height</span>
                                <input formControlName="height" aria-label="height" class="form-control"
                                  [ngClass]="{'is-invalid':height.invalid && height.touched,'is-valid': height.valid && height.touched}" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- End Options -->
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseHeader" aria-expanded="false" aria-controls="flush-collapseHeader">
                      Options
                    </button>
                  </h2>
                  <div id="flush-collapseHeader" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body" formGroupName="agent">
                      <div class="col-md-12 my-1" formGroupName="options">
                        <label class="text-muted">Header</label>
                        <div formArrayName="headers">
                          <!-- Begin Row-->
                          <div *ngFor=" let header of headers.controls; index as i; let last = last ">
                            <div [formGroupName]="i" class="col-12 col-md-12 col-sm-12 row">
                              <div class="p-1 col-md-5 col-sm-5 col-5">
                                <input type="text" formControlName="key" class="form-control col-md-12"
                                  placeholder="Content-Type"
                                  [ngClass]="{'is-invalid':headers.at(i).get('key').touched && headers.at(i).get('key').invalid,'is-valid':headers.at(i).get('key').valid && headers.at(i).get('key').touched}" />
                              </div>
                              <div *ngIf="headers.at(i).get('key').touched && headers.at(i).get('key').invalid"
                                class="invalid-feedback">
                                Please enter a key for your header
                              </div>
                              <div class="p-1 col-md-5 col-sm-5 col-5">
                                <input type="text" formControlName="value" class="form-control col-md-12"
                                  placeholder="application/json"
                                  [ngClass]="{'is-invalid':headers.at(i).get('value').touched && headers.at(i).get('value').invalid,'is-valid':headers.at(i).get('value').valid && headers.at(i).get('value').touched}" />
                              </div>
                              <div *ngIf="headers.at(i).get('value').touched && headers.at(i).get('value').invalid"
                                class="invalid-feedback">
                                Please enter a value for your header
                              </div>
                              <div class="p-1 col-md-2 col-sm-2 col-2">
                                <button class="btn btn-outline-danger" (click)="deleteHeader(i)">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-trash" viewBox="0 0 16 16">
                                    <path
                                      d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path fill-rule="evenodd"
                                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                  </svg>
                                </button>
                                <button *ngIf="last" class="btn btn-outline-primary end my-1" (click)="addHeader()">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-plus" viewBox="0 0 16 16">
                                    <path
                                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                          <button *ngIf="headers.length === 0" class="btn btn-outline-primary end"
                            (click)="addHeader()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                              class="bi bi-plus" viewBox="0 0 16 16">
                              <path
                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                          </button>
                          <!-- End Row-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Extraction-->

                <!-- Begin Connectivity -->
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseProxy" aria-expanded="false" aria-controls="flush-collapseProxy">
                      Connectivity
                    </button>
                  </h2>
                  <div id="flush-collapseProxy" class="accordion-collapse collapse" aria-labelledby="flush-headingProxy"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div class="container form-group col-md-12 col-12 col-sm-12 my-1">
                        <label class="text-muted" i18n>Proxy</label>
                        <!-- Begin Row-->
                        <div class="row">
                          <div class="p-1 col-sm-12 col-12 col-md-12" formGroupName="connectivity">
                            <select class="form-control form-control" name="proxy" formControlName="proxy">
                              <option value="" selected disabled i18n>
                                Select
                              </option>
                              <option *ngFor="let proxy of proxyList" [ngValue]="proxy">
                                {{ proxy | titlecase}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Connectivity -->

                <!-- Begin Webhook-->
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      Webhook
                    </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div class="col-md-12 p-1">
                        <div class="col-md-12" formGroupName="webhook">
                          <div class="form-group">
                            <div class="form-check form-switch">
                              <label class="form-check-label text-muted">
                                <input type="checkbox" class="form-check-input" formControlName="enabled"
                                  (change)="onChangeWebhookEnable($event)" i18n />
                                Enable
                              </label>
                            </div>
                            <div class="form-group my-2">
                              <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" class="btn-check" name="method" value="GET" id="get"
                                  formControlName="method">
                                <label class="btn btn-outline-primary" for="get">GET</label>
                                <input type="radio" class="btn-check" name="method" value="POST" id="post"
                                  formControlName="method">
                                <label class="btn btn-outline-primary" for="post">POST</label>
                              </div>
                            </div>
                            <label for="webhook" class="text-muted">Url</label>
                            <input type="text" class="form-control" formControlName="url"
                              placeholder="https://www.someurl.com/api/v1"
                              [ngClass]="{'is-invalid':webhookUrl.touched && webhookUrl.invalid, 'is-valid':webhookUrl.touched && webhookUrl.valid}" />
                            <div class="invalid-feedback" *ngIf="webhookUrl.touched && webhookUrl.invalid" i18n>
                              Please enter a valid URL - use https in
                              your url String
                            </div>
                            <!-- header-->
                            <div class="col-md-12 my-1">
                              <label class="text-muted">Header</label>
                              <div class="container" formArrayName="header">
                                <div *ngFor=" let header of webhookHeaders.controls; index as i; let last = last ">
                                  <div [formGroupName]="i" class="col-12 col-md-12 col-sm-12 row">
                                    <div class="p-1 col-md-5 col-sm-5 col-5">
                                      <input type="text" formControlName="key" class="form-control col-md-12"
                                        placeholder="Content-Type"
                                        [ngClass]="{'is-invalid':webhookHeaders.at(i).get('key').touched && webhookHeaders.at(i).get('key').invalid,'is-valid':webhookHeaders.at(i).get('key').valid && webhookHeaders.at(i).get('key').touched}" />
                                    </div>
                                    <div
                                      *ngIf="webhookHeaders.at(i).get('key').touched && webhookHeaders.at(i).get('key').invalid"
                                      class="invalid-feedback">
                                      Please enter a key for your header
                                    </div>
                                    <div class="p-1 col-md-5 col-sm-5 col-5">
                                      <input type="text" formControlName="value" class="form-control col-md-12"
                                        placeholder="application/json"
                                        [ngClass]="{'is-invalid':webhookHeaders.at(i).get('value').touched && webhookHeaders.at(i).get('value').invalid,'is-valid':webhookHeaders.at(i).get('value').valid && webhookHeaders.at(i).get('value').touched}" />
                                    </div>
                                    <div
                                      *ngIf="webhookHeaders.at(i).get('value').touched && webhookHeaders.at(i).get('value').invalid"
                                      class="invalid-feedback">
                                      Please enter a value for your header
                                    </div>

                                    <div class="p-1 col-md-2 col-sm-2 col-2">
                                      <button class="btn btn-outline-danger" (click)="deleteWebhookHeader(i)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                          fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                          <path
                                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                          <path fill-rule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                        </svg>
                                      </button>
                                      <button *ngIf="last" class="btn btn-outline-primary end my-1"
                                        [disabled]="!webhookEnabled.value" (click)="addWebhookHeader()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                          fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                          <path
                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button *ngIf="webhookHeaders.length === 0" class="btn btn-outline-primary end"
                              [disabled]="!webhookEnabled.value" (click)="addWebhookHeader()">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-plus" viewBox="0 0 16 16">
                                <path
                                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                              </svg>
                            </button>
                            <!-- header end-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Webhook -->
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 my-2">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <h5 *ngIf="selectedGeneratedCode==='json'" class="card-title mb-3 text-muted">Generated Json</h5>
              <h5 *ngIf="selectedGeneratedCode==='curl'" class="card-title mb-3 text-muted">Generated Curl</h5>

              <div class="btn-group flex-wrap" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="apiexample" value="json" id="json" checked
                  (change)="selectClient($event)">
                <label class="btn" for="json">JSON</label>

                <input type="radio" class="btn-check" name="apiexample" value="curl" id="curl"
                  (change)="selectClient($event)">
                <label class="btn" for="curl">cURL</label>
              </div>
              <pre class="overflow-auto pre">
              <code *ngIf="selectedGeneratedCode==='json'" class="col-md-12 col-12" [highlight]="getFormValue() | json" [languages]="['json']"></code>
              <code *ngIf="selectedGeneratedCode==='curl'" class="col-md-12 col-12" [highlight]="getCurlValue()" [languages]="['bash']"></code>
            </pre>
              <button class="btn btn-primary create-button float-start" i18n (click)="copyText()">
                Copy
              </button>
            </div>
          </div>
        </div>
        <!-- End Row -->
      </div>
    </div>
  </div>
</form>
<div aria-live="polite" aria-atomic="true" class="position-relative">
  <div class=" toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 11">
    <div #myToast role="alert" aria-live="assertive" aria-atomic="true" class="toast fade" data-bs-autohide="true">
      <div class="toast-header">
        <!-- 
           <img src="..." class="rounded me-2" alt="...">
           -->
        <strong class="me-auto">{{_toast.heading}}</strong>
        <small>{{_toast.time}}</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        {{_toast.message}}
      </div>
    </div>
  </div>
</div>