import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartData } from 'chart.js';
import { finalize, forkJoin, interval, startWith, Subscription } from 'rxjs';
import { UserDataDto } from '../_model/UserDataDto';
import { UserDataService } from '../_service/userdata.service';
import { Toast, Tooltip } from 'bootstrap';
import { ScheduleInterval } from '../_model/enum/ScheduleInterval';
import ScraperData from '../_model/Scraperdata';
import { ScraperService } from '../_service/scraper.service';
import { ExtractionService } from '../_service/extraction.service';
import { ExtractionDataDto } from '../_model/ExtractionDataDto';
import { ExtractionDataUIDTO } from '../_model/ExtractionDataUIDTO';
import { CreateExtractionDto } from '../_model/CreateExtractionDto';
import { NgxSpinnerService } from 'ngx-spinner';
import { UpdateScraperDto } from '../_model/UpdateScraperDto';



@Component({
  selector: 'app-scrape',
  templateUrl: './scrape.component.html',
  styleUrls: ['./scrape.component.scss'],
})
export class ScrapeComponent implements OnInit, OnDestroy  {

    constructor(
    private extractionService: ExtractionService,
    private userDataService: UserDataService,
    private scraperService: ScraperService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef) {}


  @ViewChild('myToast', { static: true }) toastEl!: ElementRef<HTMLDivElement>;
  fetchDataSubscription: Subscription;
  shouldFetchData = true;
  intervalSubscription: Subscription;
  scheduleInterval = ScheduleInterval;
  doughnutChart: Chart<'doughnut'>;
  extractionDataUIDTO: ExtractionDataUIDTO = {
    extractionDTOS: [],
    totalItems: 0,
    totalPages: 0,
    currentPage: 0,
    done: 0,
    created: 0,
    waiting: 0,
    failed: 0
  }
  extractionData: ExtractionDataDto[] = [];
  deleteScraperData: ScraperData = {} as ScraperData;
  scraperData: ScraperData[] = [];
  totalRecords: number | undefined;
  p = 1;
  itemsPerPage = 10;
  userData: UserDataDto = {} as UserDataDto;
  loading = false;
  chartReady = false;
  selectedScrape: ExtractionDataDto = {} as ExtractionDataDto;
  repeatExtractionObject: CreateExtractionDto = {} as CreateExtractionDto;
  toast: Toast | null = null;

  _toast = {
    heading: '',
    message: '',
    time: '',
    visibility: 'hidden',
  };
  archived: boolean;
  selectedScraperData: ScraperData;



  ngOnInit(): void {
    this.spinner.show();
    Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))
    this.fetchData();
    const doughnutChartData: ChartData<'doughnut'> = {
        labels: ['Done', 'Waiting', 'Created','Failed'],
        datasets:  [ { 
          data: [0, 0, 0, 0],
          backgroundColor: ['#00ab4c', '#3056d3', '#FFCE56', '#bc0412'],
          hoverBorderColor: ['#00ab4c', '#3056d3', '#FFCE56', '#bc0412'],
          hoverBackgroundColor: ['#00ab4c', '#3056d3', '#FFCE56', '#bc0412']
        } ]
      };

      const doughnutChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
      };
      const canvas = <HTMLCanvasElement> document.getElementById('myDoughnutChart')
      const ctx = canvas.getContext('2d')
      this.doughnutChart = new Chart(ctx,{
        type: 'doughnut',
        data: doughnutChartData,
        options: doughnutChartOptions,
      })
  
    this.toast = new Toast(this.toastEl.nativeElement, {});
  }

  ngOnDestroy() {
    this.intervalSubscription.unsubscribe();
  }

  async fetchData(){
    this.archived = false;
      const observables = [
        this.fetchUserData(),
        this.fetchScraper(),
        this.fetchExtractionData()
      ];
    
      forkJoin(observables)
      .subscribe({
        next:()=>{
          this.setupFetchDataInterval();
        },
        error:()=>{
          this.spinner.hide()
        }
      })
  }

  fetchArchivedData(){
    this.archived = !this.archived;
    if(this.archived){
      this.extractionService
      .getCrawlDataPage(0,this.archived)
      .subscribe((data)=>{
        this.extractionDataUIDTO = data;
        this.extractionData = data.extractionDTOS;
        this.shouldFetchData = false;
        this.totalRecords = data.totalItems;
        this.cdr.detectChanges();
      })
    } else{
      this.fetchData().finally(()=>{
        this.spinner.hide();
      })
    }
  }

  async fetchExtractionDataByPage(page:number){
    this.extractionService
      .getCrawlDataByPage(page)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((data) => {
        this.extractionDataUIDTO = data;
        this.extractionData = data.extractionDTOS;
        this.shouldFetchData = this.extractionData.some((extraction) => (extraction.status === 'WAITING' || extraction.status === 'CREATED') );
        this.totalRecords = data.totalItems;
        
        const newData = [
          this.extractionDataUIDTO.done,
          this.extractionDataUIDTO.waiting,
          this.extractionDataUIDTO.created,
          this.extractionDataUIDTO.failed
        ];
        this.doughnutChart.data.datasets[0].data = newData;
        this.doughnutChart.update();
        this.cdr.detectChanges()
      });
  }

  async fetchExtractionData() {
    this.extractionService
      .getExtractionData()
      .pipe(finalize(() => (this.loading = false, this.spinner.hide())))
      .subscribe((data) => {
        this.extractionDataUIDTO = data;
        this.extractionData = data.extractionDTOS;
        this.shouldFetchData = this.extractionData.some((extraction) => extraction.status === 'WAITING' || extraction.status === 'CREATED');
        this.totalRecords = data.totalItems;

        const newData = [
          this.extractionDataUIDTO.done,
          this.extractionDataUIDTO.waiting,
          this.extractionDataUIDTO.created,
          this.extractionDataUIDTO.failed
        ];
        this.doughnutChart.data.datasets[0].data = newData;
        this.chartReady = true;
        this.cdr.detectChanges()
      });
  }

  async fetchUserData() {
    this.userDataService.getUserDataForDashbaord().subscribe(
      (res) => {
        this.userData = res;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  async fetchScraper() {
    this.scraperService.getScraperList().subscribe(
      (res) => {
        this.scraperData = res;
        this.cdr.detectChanges();
      },
      (error) => {
        console.log(error)
      }
    );
  }

  setupFetchDataInterval(){
    if(this.shouldFetchData){
        this.intervalSubscription = interval(5000)
        .pipe(startWith(0))
        .subscribe(() => {
          if(this.shouldFetchData){
            this.fetchExtractionDataByPage(this.extractionDataUIDTO?.currentPage)
          } 
      });
    }
  }

  get userDataScrapeLimit() {
    return this.userData && this.userData.extractionLimit
      ? this.userData.extractionLimit
      : 0;
  }

  get userDataScrapeCounter() {
    return this.userData && this.userData.extractionCounter
      ? this.userData.extractionCounter
      : 0;
  }

  chooseDeleteScraper(scraperData: ScraperData) {
    this.deleteScraperData = scraperData;
  }

  async invertSchedulingEnabled(){
    this.spinner.show();
    const updateDTO: UpdateScraperDto = {
      name:this.selectedScraperData.name,
      schedulingEnabled: !this.selectedScraperData.schedulingEnabled,
      scheduleDetials:{
        scheduleType: this.selectedScraperData.scheduleDetails.scheduleType,
      }
    };
    updateDTO.schedulingEnabled = !this.selectedScraperData.schedulingEnabled;
    this.scraperService.updateScraper(updateDTO, this.selectedScraperData.id).subscribe({
      next:()=>{
        this.fetchScraper();
        this.cdr.detectChanges();
        this.spinner.hide();
      },error:(e)=>{
        this.spinner.hide();
        console.log(e)
      },complete: ()=>{
        this.spinner.hide();
      }
    })
  }

  async deleteScraper() {
    this.scraperService
      .deleteScraper(this.deleteScraperData.id)
      .subscribe(() => {
        this.fetchScraper();
      });
  }

  async repeatExtraction() {
    this.repeatExtractionObject.agent = this.selectedScrape.agent
    this.repeatExtractionObject.workflow = this.selectedScrape.workflow;
    this.repeatExtractionObject.url = this.selectedScrape.url;
    this.repeatExtractionObject.connectivity = this.selectedScrape.connectivity
    this.repeatExtractionObject.extractions = this.selectedScrape.extractions;
    this.repeatExtractionObject.scraper = {
      scraperName:null,
      schedulingEnabled: false,
      specificTime: false,
      specificTimeValue: null,
      scheduleInterval: null,
      scheduleTime: null,
    };

    if(this.userDataScrapeCounter<this.userDataScrapeLimit){
      this.extractionService.createExtraction(this.repeatExtractionObject).subscribe({
        next: () => {
          this.fetchData();
          this._toast.heading = 'Extraction created ✔️';
          this.toast!.show();
          this._toast.message = 'Extraction has Sucessfully been created';
          this._toast.visibility = 'show';
          setTimeout(() => {
            this._toast.visibility = 'hide';
          }, 5000);
        },
        error: () => {
          this._toast.heading = 'Extraction not created ❌';
          this.toast!.show();
          this._toast.time = new Date().toUTCString();
          this._toast.message = 'Something went Wrong creating a new Extraction';
          this._toast.visibility = 'show';
          setTimeout(() => {
            this._toast.visibility = 'hide';
          }, 5000);
        },
      });
    } else {
      this._toast.heading = 'Extraction not created ❌';
      this.toast!.show();
      this._toast.time = new Date().toUTCString();
      this._toast.message = 'You cant create more extractions!';
      this._toast.visibility = 'show';
    }

  }

  setScraperForInvertingScheduling(scraperData: ScraperData){
      this.selectedScraperData = scraperData;
  }

  selectScrape(scrapeData: ExtractionDataDto) {
    this.selectedScrape = scrapeData;
  }

  async changePage(_event: any) {
    this.spinner.show()
    this.extractionService
      .getCrawlDataPage(_event - 1, this.archived)
      .pipe(finalize(() => {
        this.spinner.hide()
        }))
      .subscribe((data) => {
        this.extractionDataUIDTO = data;
        this.extractionData = data.extractionDTOS;
        this.shouldFetchData = this.extractionData.some((extraction) => extraction.status === 'WAITING');
        this.p = data.currentPage + 1;
      });
  }

  show() {
    this.toast!.show();
  }
}
