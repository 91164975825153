import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'thumbnail'
  })
  export class Thumbnail implements PipeTransform {
      transform(url:string):string {
        const lastIndex = url.lastIndexOf('.');
        if (lastIndex === -1) {
        // The URL does not have a file extension.
        return url;
        }

        // Extract the file name and extension.
        const fileName = url.substring(0, lastIndex);
        const extension = url.substring(lastIndex);

        // Add "-thumbnail" before the extension.
        const thumbnailUrl = `${fileName}-thumbnail${extension}`;
        return thumbnailUrl;
        }
    }
