import { SupportRequestType } from "./enum/SupportRequestType";

export interface CreateSupportRequestDto{
    createdBy:string,
    issueText:string,
    supportRequestType:SupportRequestType   
}

export class ContactUsDTO{
    email: string;
    name: string;
    companyName: string;
    phone:string;
    message:string;

    constructor(
    email: string,
    name: string,
    companyName: string,
    phone:string,
    message:string
    ) {
        this.email=email;
        this.name=name;
        this.companyName=companyName;
        this.phone=phone;
        this.message=message;
    }
}