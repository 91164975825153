import { Pipe, PipeTransform } from "@angular/core";
import { Workflow } from "../_model/Browseractions";

@Pipe({
    name: 'workflow'
})
export class WorkflowTransformPipe implements PipeTransform{

    transform(workflow:Workflow): string{
        if(workflow != null){
            switch(workflow.action){
                case 'scroll':
                    return 'Scroll - ' + ' x: ' + workflow.x + 'y: ' +workflow.y;
                case 'screenshot':
                    return 'Screenshot';
                case 'wait':
                    return 'Wait - ' + workflow.value + ' Seconds';
                case 'click':
                    return 'Click -' + ' X: ' + workflow.x + ' Y: ' + workflow.y
                    case 'full-page-screenshot':
                        return 'Full Page Screenshot';
            }
        } 
    }

}