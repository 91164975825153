import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ContactUsDTO, CreateSupportRequestDto } from "../_model/CreateSupportRequestDto";
import { ApiConfiguration } from "./api-configuration";
import { BaseService } from "./base-service";


@Injectable({
    providedIn:'root',
})
export class SupportService extends BaseService{

    private RootUrl = environment.SCRAPER_UI_SERVICE;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        }),
    };

    constructor(config:ApiConfiguration, http:HttpClient){
        super(config,http);
    }

    createNewSupportRequest(request:CreateSupportRequestDto):Observable<any>{
        return this.http.post<any>(this.RootUrl + '/support',request)
    }

    createContactRequest(request:ContactUsDTO):Observable<any>{
        return this.http.post<any>(this.RootUrl + '/support/contact-us',request)
    }

    getAllSupportRequestsForUser():Observable<any>{
        return this.http.get<any>(this.RootUrl +'/support/tickets')
    }

    getSupportReqestById(ticketId:String):Observable<any>{
        return this.http.get<any>(this.RootUrl + '/support/tickets' + ticketId)
    }


}