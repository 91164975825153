<div class="row">
    <div class="col-md-12 grid-margin py-2">
        <div class="card shadow-sm">
            <div class="pricing-header p-3 pb-md-4 mx-auto text-center">
                <h1 class="display-4 fw-normal" i18n>Subscriptions</h1>
                <p class="fs-5 text-muted" *ngIf="!usertierLevel" i18n>Choose between our plas</p>
                <p class="fs-5 text-muted" *ngIf="usertierLevel" i18n>Your current plan is <span [ngClass]="{'bg-secondary': usertierLevel ==='FREE','bg-primary': usertierLevel == 'BASIC','bg-success': usertierLevel =='PRO','bg-business': usertierLevel =='BUSINESS'}" class="badge rounded-pill">{{usertierLevel}}</span></p>
            </div>
            <div class="card-body d-flex flex-column">
                <main class="py-3">
                    <div class="container">
                        <div class="row row-cols-1 row-cols-md-2 mb-2 text-center">
                            <div class="col-md-2">
                            </div>
                            <div class="col-md-4">
                                <div class="card mb-4 rounded-3 shadow-sm border-primary" id="pricePro">
                                    <div class="card-header py-3 text-white bg-primary border-primary" >
                                        <h4 class="my-0 fw-normal card-title-h4" i18n>Pro</h4>
                                    </div>
                                    <div class="card-body flex-column d-flex">
                                        <h3><small class="text-muted fw-light"> <del>€ 49,99</del></small></h3>
                                        <h2 class="card-title pricing-card-title"i18n>€ 39,99<small class="text-muted fw-light">/mo</small>
                                        </h2>
                                        <ul class="list-unstyled mt-3 mb-4">
                                            <li class="text-muted" i18n>2,500 Requests</li>
                                            <li class="text-muted" i18n>All features</li>
                                            <li class="text-muted" i18n>Prioritized Support</li>
                                        </ul>
                                        <input type="hidden" name="lookup_key" value="test Suchschlüssel" />
                                        <button  *ngIf="usertierLevel ==='FREE'" type="button" class="w-100 btn btn-lg btn-primary mt-auto"  (click)="subscribePro()" i18n>Subscribe
                                        </button>
                                        <button *ngIf="usertierLevel ==='BASIC' || usertierLevel ==='PRO'" class="w-100 btn btn-lg btn-primary mt-auto" data-bs-target="#upgradeModal"
                                            data-bs-toggle="modal"
                                            [disabled]="usertierLevel === 'PRO'">{{usertierLevel ==='PRO' ? 'Active' : usertierLevel ==='BASIC'? 'Change Subscription': 'Change Subscription'}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card mb-4 rounded-3 shadow-sm border-primary">
                                    <div class="card-header text-white py-3 bg-primary border-primary">
                                        <h4 class="my-0 fw-normal card-title-h4" i18n>Enterprise</h4>
                                    </div>
                                    <div class="card-body flex-column d-flex" [class]="usertierLevel==='ENTERPRISE' ? 'activeCard':''">
                                        <h2 class="card-title pricing-card-title" i18n>Get Quote</h2>
                                        <ul class="list-unstyled mt-3 mb-4">
                                            <li class="text-muted" i18n>Dedicated support person</li>
                                            <li class="text-muted" i18n>Customized data export</li>
                                            <li class="text-muted" i18n>Custom extractor development</li>

                                        </ul>
                                        <button type="button" class="btn btn-lg btn-primary mt-auto" data-bs-target="#contact-us" data-bs-toggle="modal"
                                            i18n>Contact Us</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="changedSubscriptionCurrentPeriod"  class="alert alert-warning" role="alert">
                            You have already changed your subscription during this billing period. You'll be able to switch your subscription again in the next billing period.
                          </div>
                    </div>
                    <div class="row justify-content-center">
                        <div *ngIf="usertierLevel !='FREE' " class="mx-auto text-center gy-5">
                            <p class="text-muted" i18n>You can cancel and manage your subscriptions via our Stripe customer portal</p>
                            <button (click)="startPortalSesstion()" type="button" class="btn btn-lg btn-primary mt-auto" i18n>Stripe Portal</button>
                        </div>
                        <div class="mx-auto text-center gy-5">
                            <p class="fs-5 text-muted" i18n>Additional Scrapes</p>
                        </div>
                        <div class="container">
                            <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
                                <div class="col">
                                </div>
                                <div class="col">
                                    <div class="card mb-4 rounded-3 shadow-sm border-primary" id="firstscrapes">
                                        <div class="card-header py-3 bg-primary border-primary">
                                            <h4 class="my-0 text-white fw-normal">1.000 Scrapes</h4>
                                        </div>
                                        <div class="card-body flex-column d-flex">
                                            <h2 class="card-title pricing-card-title">€ 19,99</h2>
                                            <ul class="list-unstyled mt-3 mb-4">
                                                <li class="text-muted" i18n>Add 1.000 Scrapes to your current Subscription</li>
                                            </ul>
                                            <button *ngIf="usertierLevel !='FREE' " type="button" class="w-100 btn btn-lg btn-primary mt-auto"
                                                (click)="purchase1kScrapes()" i18n>Buy</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                        </div>
                        <div class="col-md-8">
                            <h4 class="display-6 text-center mb-4" i18n>Compare plans</h4>
                            <div class="table-responsive">
                                <table class="table text-center">
                                    <thead>
                                        <tr>
                                            <th style="width: 34%;"></th>
                                            <th style="width: 22%;" i18n><span class="badge rounded-pill bg-secondary">Free</span></th>
                                            <th style="width: 22%;" i18n><span class="badge rounded-pill bg-success">Pro</span></th>
                                            <th style="width: 22%;" i18n><span class="badge rounded-pill bg-primary">Enterprise</span></th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row" class="text-start">Requests</th>
                                            <td><b>10</b>
                                            </td>
                                            <td><b>2,500</b></td>
                                            <td><b>Custom Amount</b></td>
                                        </tr>
                                    </tbody>

                                    <tbody>
                                        <tr>
                                            <th scope="row" class="text-start" i18n>Javascript Rendering</th>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-start" i18n>Scheduling </th>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-start">Rest API</th>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-start" i18n>Webhook</th>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-start" i18n>Browser Action Automation</th>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-start" i18n>Rotating IPs</th>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-start" i18n>Proxies</th>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-start" i18n>Prioritized Support</th>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45"
                                                    fill="red" class="bi bi-x" viewBox="0 0 20 20">
                                                    <path
                                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-start" i18n>Custom Scraper</th>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45"
                                                    fill="red" class="bi bi-x" viewBox="0 0 20 20">
                                                    <path
                                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45"
                                                    fill="red" class="bi bi-x" viewBox="0 0 20 20">
                                                    <path
                                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-start" i18n>Post Processing</th>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45"
                                                    fill="red" class="bi bi-x" viewBox="0 0 20 20">
                                                    <path
                                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45"
                                                    fill="red" class="bi bi-x" viewBox="0 0 20 20">
                                                    <path
                                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="green" class="bi bi-check-lg" viewBox="0 0 20 20">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                </svg>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-2">
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
<ngx-spinner type="ball-pulse" bdColor="rgba(0, 0, 0, 0.6)" size="medium" color="#fff" [fullScreen]="true">
</ngx-spinner>

<!-- Modal Upgrade Subscription to Pro-->
<div class="modal fade" id="upgradeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="upgradeModal" i18n>Change Subscription</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p i18n> You are about to upgrade your Subscription to <b> Pro</b>? </p>
        <p i18n>Are you sure you want to upgrde your current subscription?</p>
        <p><small i18n>You will only be charged for the remaining subscription period with your new subscription fee. You do not need to update payment infomration and after confirming your subscription will be automaticly upgraded to the Pro Subscription.</small></p>
      </div>
      <div class="form-check m-4">
        <input class="form-check-input" type="checkbox" [(ngModel)]="confirmUpgrade" id="flexCheckIndeterminate">
        <label class="form-check-label" for="flexCheckIndeterminate">
            <small>I understand that by confirming this process, my subscription will change to a more expensive subscription from now on. I will be charged for the remaining billing period at the cost of the new subscription.</small>
        </label>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" i18n>Cancel</button>
        <button (click)="changeSubscription('PRO')" data-bs-dismiss="modal" class="btn btn-outline-success" [disabled]="!confirmUpgrade" i18n>Change Subscription
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal change Subsciption to Basic -->
<div class="modal fade" id="changeToBasicModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="changeToBasicModal" i18n>Change Subscription</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p i18n>You are about to change your current Subscription to <b>Basic</b>? </p>
        <p i18n>The amount of scrapes will be decresed to 400 Scrapes</p>
        <p><small i18n>You will only be charged for the remaining subscription period with your new subscription fee. You do not need to update payment infomration.</small></p>
      </div>
      <div class="form-check m-4">
        <input class="form-check-input" type="checkbox" [(ngModel)]="confirChangeSub" id="flexCheckIndeterminate">
        <label class="form-check-label" for="flexCheckIndeterminate">
            I understand that by confirming this process, my subscription will change to the Basic subscription <b>at the end of the current subscription period automaticly</b>. I am also aware that I will be billed  at the new price when this subscription period ends. Once I confirm this change, I won't be able to alter my subscription until the following period.
        </label>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" i18n>Cancel</button>
        <button (click)="changeSubscription('BASIC')" data-bs-dismiss="modal" class="btn btn-outline-success" [disabled]="!confirChangeSub"  i18n>Change Subscription
        </button>
      </div>
    </div>
  </div>
</div>
<app-contact-us></app-contact-us>