<form [formGroup]="form" (ngSubmit)="submitData()" novalidate>
  <div class="row">
    <div class="grid-margin col-md-12">
      <div class="row">
        <!-- Begin Row -->
        <!-- Begin Scrape URL and Browser Selection -->
        <div class="col-md-4 my-2">
          <div class="card shadow-sm h-100">
            <div class="card-body flex-column">
              <h4 class="card-title">Create Scraper</h4>
              <div class="alert alert-primary" role="alert">
                <div>
                  A scraper performs scheduled extractions that you can configure. Please refer to our <a
                    href="https://docs.scraper.adcolabs.de/docs/Adcolabs-Scraper%20Web/Scrape/create/scraper">docs</a>
                  to
                  learn more about scrapers.
                </div>
              </div>
              <div class="form-group pt-4">
                <div class="col mb-3">
                  <div class="form-floating col" formGroupName="scraper">
                    <input type="text" formControlName="name" name="name" class="form-control"
                      [ngClass]="{'is-invalid':name.touched && name.invalid,'is-valid':name.touched && name.valid}"
                      placeholder="Scraper Name" />
                    <label class="form-label" for="name" i18n>Scraper Name</label>
                  </div>
                  <div *ngIf="name.value.length > 20" class="invalid-feedback">
                    The Scraper-Name may be a maximum of 20 characters long
                  </div>
                </div>
                <div class="form-floating col mb-3" formGroupName="extraction">
                  <input type="text" formControlName="url" class="form-control" name="url"
                    [ngClass]="{'is-invalid':url.touched && url.invalid,'is-valid':url.touched && url.valid}"
                    placeholder="URL" />
                  <label class="form-label" for="url" i18n>URL</label>
                  <div *ngIf="url.touched && url.invalid" class="invalid-feedback">
                    Please enter a valid url.
                  </div>
                </div>
              </div>
              <hr>
              <div class="form-group col-md-12 pt-3" formGroupName="scraper">
                <label class="form-label text-muted" for="url" i18n>Schedule Settings</label>
                <div class="col-md-12" formGroupName="scheduleDetails">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-check mt-3" style="margin-bottom: 3px;">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" value="weekly"
                          id="flexRadioDefault1" checked (change)="onChangeScheduleSetting($event)">
                        <label class="form-check-label text-muted" for="flexRadioDefault1">
                          Weekly Schedule
                        </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-check mt-3">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                          value="interval" (change)="onChangeScheduleSetting($event)">
                        <label class="form-check-label text-muted" for="flexRadioDefault2">
                          Interval
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div *ngIf="scheduleType.value==='weekly'" class="form-group">
                      <div class="btn-group btn-group-toggle btn-sm d-flex" role="group"
                        aria-label="Basic checkbox toggle button group">
                        <div *ngFor="let day of dayLabels" class="w-100 d-flex col-1">
                          <input type="checkbox" class="btn-check btn-sm" id="{{day}}" autocomplete="off"
                            value="{{day}}" (change)="toggleDay($event)" [disabled]="scheduleType.value==='interval'">
                          <label class="btn btn-outline-primary" for="{{day}}">
                            {{ day | slice:0:3}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="scheduleType.value==='weekly'" class="row">
                    <div class="form-group" formGroupName="weeklyScheduleDetails">
                      <div class="btn-group btn-group-sm col-12">
                        <div *ngFor="let day of dayLabels; let i = index" class="d-flex flex-column w-100 col-1">
                          <div [formGroupName]="day.toUpperCase()">
                            <div *ngFor="let time of getDay(day.toUpperCase()).controls; let j = index">
                              <div *ngIf="getDay(day.toUpperCase()).controls.length > 0">
                                <input type="time" class="form-control text-center col"
                                  [disabled]="scheduleType.value !== 'weekly'" [formControlName]="j" />
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2 justify-content-center">
                            <div class="col">
                              <button *ngIf="getDay(day.toUpperCase()).value.length > 0" type="button"
                                class="btn btn-primary btn-sm m-1" (click)="addTime(day.toUpperCase())"
                                [disabled]="scheduleType.value==='interval'">
                                <span>
                                  <i class="fa-solid fa-plus"></i>
                                </span>
                              </button>
                              <button *ngIf="getDay(day.toUpperCase()).value.length > 1" type="button"
                                class="btn btn-danger btn-sm" (click)="removeTime(day.toUpperCase())"
                                [disabled]="scheduleType.value==='interval'">
                                <span>
                                  <i class="fa-regular fa-trash-can"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Row-->

                <div *ngIf="scheduleType.value==='interval'" class="row" style="margin-left: 12px;">
                  <!-- Begin Interval Value-->
                  <div formGroupName="scheduleDetails" class="col-6">
                    <div class="input-group p-1 col-12">
                      <span class="input-group-text" style="width: 80px" i18n>Minute</span>
                      <input formControlName="minute" type="text" class="form-control" />
                    </div>
                    <div class="input-group p-1">
                      <span class="input-group-text" style="width: 80px" i18n>Hour</span>
                      <input formControlName="hour" type="text" class="form-control" />
                    </div>
                    <div class="input-group p-1 col-md-1">
                      <span class="input-group-text" style="width: 80px" i18n>Day</span>
                      <input formControlName="day" type="text" class="form-control" />
                    </div>
                  </div>
                  <!-- End scheduleTime Time Value-->
                </div>
                <hr>
              </div>
              <div class="form-group">
                <div class="col-12 mb-1" formGroupName="extraction">
                  <label class="form-label text-muted" for="url" i18n>Extractions</label>
                  <div class="col-md-12">
                    <div class="form-group my-2">
                      <div class="form-check form-switch">
                        <input [formControl]="checkboxControl" type="checkbox" class="form-check-input"
                          (change)="onChangeFullExtraction($event)" checked />
                        <label class="form-check-label text-muted" i18n for="checkboxContro">
                          Full Extraction
                        </label>
                      </div>
                      <small class="text-muted">The complete HTML Doucment after rendering the page will be
                        extracted</small>
                    </div>
                  </div>
                  <hr>
                  <div class="col-md-12" formArrayName="selector">
                    <label for="fullExtraction" class="label-description text-muted mb-3" i18n>Selectors and
                      Extractors</label>
                    <div class="row">
                      <div class="col-md-3 col-sm-3 col-3">
                        <select class="form-control" name="action" (change)="selecetExtractionType($event)">
                          <option value="" selected disabled i18n>Select</option>
                          <option *ngFor="let extractionType of extractionTypes" [ngValue]="extractionType">
                            {{ extractionType | titlecase}}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-7 col-sm-7 col-7">
                        <input #inputValueRef type="text" class="form-control col-md-12"
                          placeholder="Selector expression" (keyup)="onInputChange()"
                          [disabled]="selectedExtractionType!=='Regex'" />
                      </div>
                      <div class="col-md-1 col-sm-1 col-1" style="padding-left: 0px;">
                        <button class="btn btn-outline-primary addButton start" type="button"
                          (click)="addExtraction(inputValueRef)"
                          [disabled]="!addButtonExpressionChecker(selectedExtractionType, inputValueRef.value)">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-md-12">
                      <div>
                        <div *ngIf="filteredExtractions().length > 0" class="card my-2" formGroupName="selector">
                          <ul class="list-group list-group-flush">
                            <li *ngFor="let extraction of filteredExtractions(); let i = index" class="list-group-item">
                              <div class="row">
                                <div *ngIf="extraction.value.type === 'full'" class="col-10 text-muted"
                                  style="display: flex; align-items: center;">
                                  <b class="text-muted">Full Extraction</b>
                                </div>
                                <div *ngIf="extraction.value.type !== 'full'" class="col-10"
                                  style="display: flex; align-items: center;">
                                  <div class="col-12 text-muted"><b>{{extraction?.value.type}}</b></div>
                                  <!-- <div *ngIf="extraction.value.value !== ''" class="col-7 text-muted">{{extraction.value.value}}</div> -->
                                  <!-- <div *ngIf="extraction.value.value == ''" class="col-7 text-muted"><i>Predefined</i></div> -->
                                  <!-- <div *ngIf="extraction.value.value !== ''" class="col-7 text-muted"><i>See Json</i></div> -->
                                </div>
                                <div class="col-md-1 col-sm-1 col-1">
                                  <button type="button" class="btn btn-outline-danger"
                                    (click)="deleteExtractionByValue(extraction?.value)">
                                    <i class="fa-regular fa-trash-can"></i>
                                  </button>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- Begin Row-->
                <div class="form-group bottom-0 pt-4">
                  <div class="col-md-12 col-sm-12 col-12 my-3">
                    <button class="btn btn-primary create-button float-start mt-auto" [disabled]="form.invalid" i18n>
                      Create
                    </button>
                    <button class="btn btn-outline-secondary mx-1 mt-auto" (click)="cancel()" i18n>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Row -->
          </div>
        </div>
        <!-- End Scrape URL and Browser Selection -->
        <!-- Begin Extraction-->
        <div class="col-md-4 my-2" formGroupName="extraction">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <!-- <h5 class="card-title text-muted" i18n><b>Configuration</b></h5> -->
              <h4 class="card-title">Configuration</h4>
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <!-- Begin  Browser Workflow -->
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      Browser Workflow
                    </button>
                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <!-- Begin Workflos -->
                      <div class="col-md-12">
                        <div class="form-group">
                          <div class="container center">
                            <div class="row">
                              <div class="col-md-4 col-sm-4 col-4" style="padding-left: 0px;">
                                <select class="form-control" name="action" (change)="selectOption($event)">
                                  <option selected disabled i18n>Select</option>
                                  <option *ngFor="let parameter of parameters" value="{{parameter}}">
                                    {{ parameter | titlecase}}
                                  </option>
                                </select>
                              </div>
                              <div *ngIf="selectedOption === 'scroll'" class="col-md-5 col-4 col-sm-4">
                                <div class="input-group col-md-6 col-6 col-sm-6"
                                  formGroupname="browserActionsFormScroll">
                                  <input type="text" class="form-control" placeholder="x" [value]="scrollXValue"
                                    (keyup)="scrollXValueOnKeyUp($event)"
                                    [ngClass]="{'is-invalid':scrollYValue === null && scrollXValue === null ,'is-valid': scrollXValue || scrollYValue}" />
                                  <input type="text" class="form-control" placeholder="y" [value]="scrollYValue"
                                    (keyup)="scrollYValueOnKeyUp($event)"
                                    [ngClass]="{'is-invalid':scrollYValue === null && scrollXValue === null ,'is-valid': scrollXValue || scrollYValue}" />
                                </div>
                              </div>
                              <div *ngIf="selectedOption === 'click'" class="col-md-5 col-4 col-sm-4">
                                <div class="input-group col-md-6 col-6 col-sm-6">
                                  <input type="text" class="form-control" placeholder="x" [value]="clickXValue"
                                    (keyup)="clickXValueOnKeyUp($event)"
                                    [ngClass]="{'is-invalid':clickYValue === null && clickXValue === null ,'is-valid': clickXValue || clickYValue}" />
                                  <input type="text" class="form-control" placeholder="y" [value]="clickYValue"
                                    (keyup)="clickYValueOnKeyUp($event)"
                                    [ngClass]="{'is-invalid':clickYValue === null && clickXValue === null ,'is-valid': clickXValue || clickYValue}" />
                                </div>
                              </div>
                              <div *ngIf="selectedOption === 'wait'" class="col-md-5 col-5 col-sm-5">
                                <div class="input-group col-md-6 col-6 col-sm-6">
                                  <span class="input-group-text">Sec</span>
                                  <input type="text" class="form-control col-md-5 col-5 col-sm-5" placeholder="10"
                                    (keyup)="waitValueOnKeyUp($event)" [value]="waitValue"
                                    [ngClass]="{'is-invalid': waitValue === null || waitValue <= 0 ,'is-valid': waitValue > 0}" />
                                </div>
                              </div>
                              <div class="col-md-2 col-2 col-sm-2 ms-auto justify-content-center"
                                style="padding-left: 0px;">
                                <button *ngIf="selectedOption === 'click'" type="button"
                                  class="btn btn-outline-primary addButton"
                                  [disabled]="workflowArray.value.length > 4 || ((clickXValue === null  && clickYValue === null) || (clickXValue <= 0 && clickYValue <= 0 ))"
                                  (click)="addAction()" i18n>
                                  Add
                                </button>
                                <button *ngIf="selectedOption === 'scroll'" type="button"
                                  class="btn btn-outline-primary addButton"
                                  [disabled]="workflowArray.value.length > 4 || ((scrollYValue === null  && scrollXValue === null) || (scrollXValue <= 0 && scrollYValue <= 0 )) "
                                  (click)="addAction()" i18n>
                                  Add
                                </button>
                                <button *ngIf="selectedOption === 'wait'" type="button"
                                  class="btn btn-outline-primary addButton"
                                  [disabled]="workflowArray.value.length > 4  || (waitValue === null ||  waitValue <= 0 )"
                                  (click)="addAction()" i18n>
                                  Add
                                </button>
                                <button *ngIf="selectedOption === 'screenshot'" type="button"
                                  class="btn btn-outline-primary addButton" [disabled]="workflowArray.value.length > 4 "
                                  (click)="addAction()" i18n>
                                  Add
                                </button>
                                <button *ngIf="selectedOption === 'full-page-screenshot'" type="button"
                                  class="btn btn-outline-primary addButton" [disabled]="workflowArray.value.length > 4 "
                                  (click)="addAction()" i18n>
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div>
                                <div *ngIf="workflowArray.value.length > 0" class="card my-2">
                                  <ul class="list-group list-group-flush">
                                    <li *ngFor=" let browserAction of workflowArray.value; let i = index "
                                      class="list-group-item">
                                      <div class="row">
                                        <div *ngIf="browserAction.action === 'screenshot' " class="col-10"
                                          style=" display: flex; align-items: center; ">
                                          <div class="col-5" style="display: flex; align-items: center;">
                                            <b>{{ browserAction.action | titlecase}}</b>
                                            <span class="badge rounded-pill bg-info small mx-1" id="badges">
                                              <span title="Screenshot">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                  fill="currentColor" class="bi bi-camera" viewBox="0 0 16 16">
                                                  <path
                                                    d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                                  <path
                                                    d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                        <div *ngIf=" browserAction.action === 'scroll' " class="col-10"
                                          style=" display: flex; align-items: center; ">
                                          <div class="col-3" style="display: flex; align-items: center;">
                                            <b>{{ browserAction.action | titlecase}}:</b>
                                            <span class="badge rounded-pill bg-info small mx-1" id="badges">
                                              <span title="Scroll">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                  fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                                  <path fill-rule="evenodd"
                                                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </div>
                                          <div class="col-9">X: {{ browserAction.options.x}}, Y: {{
                                            browserAction.options.y }}</div>
                                        </div>
                                        <div *ngIf=" browserAction.action === 'click' " class="col-10"
                                          style=" display: flex; align-items: center;">
                                          <div class="col-3" style="display: flex; align-items: center;">
                                            <b>{{ browserAction.action | titlecase}}:</b>
                                            <span class="badge rounded-pill bg-info small mx-1" id="badges">
                                              <span title="Click">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                  fill="currentColor" class="bi bi-mouse2" viewBox="0 0 16 16">
                                                  <path
                                                    d="M3 5.188C3 2.341 5.22 0 8 0s5 2.342 5 5.188v5.625C13 13.658 10.78 16 8 16s-5-2.342-5-5.188V5.189zm4.5-4.155C5.541 1.289 4 3.035 4 5.188V5.5h3.5V1.033zm1 0V5.5H12v-.313c0-2.152-1.541-3.898-3.5-4.154zM12 6.5H4v4.313C4 13.145 5.81 15 8 15s4-1.855 4-4.188V6.5z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </div>
                                          <div class="col-9">
                                            X: {{ browserAction.options.x }}, Y: {{ browserAction.options.y }}
                                          </div>
                                        </div>
                                        <div *ngIf=" browserAction.action === 'wait' " class="col-10"
                                          style=" display: flex; align-items: center; ">
                                          <div class="col-3" style="display: flex; align-items: center;">
                                            <b>{{ browserAction.action | titlecase}}:</b>
                                            <span class="badge rounded-pill bg-info small mx-1" id="badges">
                                              <span title="Wait">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                  fill="currentColor" class="bi bi-hourglass-split" viewBox="0 0 16 16">
                                                  <path
                                                    d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </div>
                                          <div class="col-9">
                                            {{ browserAction.value }} Seconds
                                          </div>
                                        </div>
                                        <div *ngIf=" browserAction.action === 'full-page-screenshot' " class="col-10"
                                          style=" display: flex; align-items: center; ">
                                          <div class="col-8">
                                            <b>Full Screenshot</b>
                                            <span class="badge rounded-pill bg-info small mx-1" id="badges">
                                              <span title="Screenshot-Full">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                  fill="currentColor" class="bi bi-arrows-fullscreen"
                                                  viewBox="0 0 16 16">
                                                  <path fill-rule="evenodd"
                                                    d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
                                                </svg>
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                        <div class="col-2">
                                          <button type="button" class="btn btn-outline-danger mx-1 float-end"
                                            (click)="deleteBrowserAction(i)" i18n>
                                            <i class="fa-regular fa-trash-can"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- End Actions -->
                    </div>
                  </div>
                </div>
                <!-- End Browser Actions -->

                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      Resolution
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <!-- Begin Options -->
                      <div class="col-md-12 col-12 col-sm-12 p-1" formGroupName="agent">
                        <div class="form-group" formGroupName="resolution">
                          <div class="row">
                            <div class="col-md-5 col-sm-5 col-5">
                              <button type="button" class="btn btn-outline-primary" (click)="setMobileResolution()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                  class="bi bi-phone" viewBox="0 0 16 16">
                                  <path
                                    d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z">
                                  </path>
                                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
                                </svg>
                                Mobile
                              </button>
                            </div>
                            <div class="col-md-7 col-sm-7 col-7">
                              <div class="input-group p-1">
                                <span class="input-group-text" style="width: 80px" i18n>Width</span>
                                <input formControlName="width" aria-label="width" class="form-control"
                                  [ngClass]="{'is-invalid':width.invalid && width.touched,'is-valid': width.valid && width.touched}" />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5 col-sm-5 col-5">
                              <button type="button" class="btn btn-outline-primary" (click)="setDesktopResolution()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                  class="bi bi-display" viewBox="0 0 16 16">
                                  <path
                                    d="M0 4s0-2 2-2h12s2 0 2 2v6s0 2-2 2h-4c0 .667.083 1.167.25 1.5H11a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1h.75c.167-.333.25-.833.25-1.5H2s-2 0-2-2V4zm1.398-.855a.758.758 0 0 0-.254.302A1.46 1.46 0 0 0 1 4.01V10c0 .325.078.502.145.602.07.105.17.188.302.254a1.464 1.464 0 0 0 .538.143L2.01 11H14c.325 0 .502-.078.602-.145a.758.758 0 0 0 .254-.302 1.464 1.464 0 0 0 .143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.757.757 0 0 0-.302-.254A1.46 1.46 0 0 0 13.99 3H2c-.325 0-.502.078-.602.145z">
                                  </path>
                                </svg>
                                Desktop
                              </button>
                            </div>
                            <div class="col-md-7 col-sm-7 col-7">
                              <div class="input-group p-1">
                                <span class="input-group-text" style="width: 80px" i18n>Height</span>
                                <input formControlName="height" aria-label="height" class="form-control"
                                  [ngClass]="{'is-invalid':height.invalid && height.touched,'is-valid': height.valid && height.touched}" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- End Options -->
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseHeader" aria-expanded="false" aria-controls="flush-collapseHeader">
                      Options
                    </button>
                  </h2>
                  <div id="flush-collapseHeader" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body" formGroupName="agent">
                      <div class="col-md-12 my-1" formGroupName="options">
                        <label class="text-muted">Header</label>
                        <div formArrayName="headers">
                          <!-- Begin Row-->
                          <div *ngFor=" let header of headers.controls; index as i; let last = last ">
                            <div [formGroupName]="i" class="col-12 col-md-12 col-sm-12 row">
                              <div class="p-1 col-md-5 col-sm-5 col-5">
                                <input type="text" formControlName="key" class="form-control col-md-12"
                                  placeholder="Content-Type"
                                  [ngClass]="{'is-invalid':headers.at(i).get('key').touched && headers.at(i).get('key').invalid,'is-valid':headers.at(i).get('key').valid && headers.at(i).get('key').touched}" />
                              </div>
                              <div *ngIf="headers.at(i).get('key').touched && headers.at(i).get('key').invalid"
                                class="invalid-feedback">
                                Please enter a key for your header
                              </div>
                              <div class="p-1 col-md-5 col-sm-5 col-5">
                                <input type="text" formControlName="value" class="form-control col-md-12"
                                  placeholder="application/json"
                                  [ngClass]="{'is-invalid':headers.at(i).get('value').touched && headers.at(i).get('value').invalid,'is-valid':headers.at(i).get('value').valid && headers.at(i).get('value').touched}" />
                              </div>
                              <div *ngIf="headers.at(i).get('value').touched && headers.at(i).get('value').invalid"
                                class="invalid-feedback">
                                Please enter a value for your header
                              </div>
                              <div class="p-1 col-md-2 col-sm-2 col-2">
                                <button class="btn btn-outline-danger" (click)="deleteHeader(i)">
                                  <i class="fa-regular fa-trash-can"></i>
                                </button>
                                <button *ngIf="last" class="btn btn-outline-primary end my-1" (click)="addHeader()">
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <button *ngIf="headers.length == 0" class="btn btn-outline-primary end" (click)="addHeader()">
                            <i class="fa-solid fa-plus"></i>
                          </button>
                          <!-- End Row-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Extraction-->

                <!-- Begin Connectivity -->
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseProxy" aria-expanded="false" aria-controls="flush-collapseProxy">
                      Proxy
                    </button>
                  </h2>
                  <div id="flush-collapseProxy" class="accordion-collapse collapse" aria-labelledby="flush-headingProxy"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div class="container form-group col-md-12 col-12 col-sm-12 my-1">
                        <label class="text-muted" i18n>Choose a Proxy</label>
                        <!-- Begin Row-->
                        <div class="row">
                          <div class="p-1 col-sm-12 col-12 col-md-12" formGroupName="connectivity">
                            <select class="form-control form-control" name="proxy" formControlName="proxy">
                              <option value="" selected disabled i18n>
                                Select
                              </option>
                              <option *ngFor="let proxy of proxyList" [ngValue]="proxy">
                                {{ proxy | titlecase}}
                              </option>
                              <option *ngIf="isAdmin">eu-central-1-testing</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Connectivity -->

                <!-- Begin Webhook-->
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      Webhook
                    </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div class="col-md-12 p-1">
                        <div class="col-md-12" formGroupName="webhook">
                          <div class="form-group">
                            <div class="form-check form-switch">
                              <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" formControlName="enabled"
                                  (change)="onChangeWebhookEnable($event)" i18n />
                                Enable
                              </label>
                            </div>
                            <div class="form-group my-2">
                              <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" class="btn-check" name="method" value="GET" id="get"
                                  formControlName="method">
                                <label class="btn btn-outline-primary" for="get">GET</label>
                                <input type="radio" class="btn-check" name="method" value="POST" id="post"
                                  formControlName="method">
                                <label class="btn btn-outline-primary" for="post">POST</label>
                              </div>
                            </div>
                            <label for="webhook" class="text-muted">Url</label>
                            <input type="text" class="form-control" formControlName="url"
                              placeholder="https://www.someurl.com/api/v1"
                              [ngClass]="{'is-invalid':webhookUrl.touched && webhookUrl.invalid, 'is-valid':webhookUrl.touched && webhookUrl.valid}" />
                            <div class="invalid-feedback" *ngIf="webhookUrl.touched && webhookUrl.invalid" i18n>
                              Please enter a valid URL - use https in
                              your url String
                            </div>
                            <!-- header-->
                            <div class="col-md-12 my-1">
                              <label class="text-muted">Header</label>
                              <div class="container" formArrayName="header">
                                <div *ngFor=" let header of webhookHeaders.controls; index as i; let last = last ">
                                  <div [formGroupName]="i" class="col-12 col-md-12 col-sm-12 row">
                                    <div class="p-1 col-md-5 col-sm-5 col-5">
                                      <input type="text" formControlName="key" class="form-control col-md-12"
                                        placeholder="Content-Type"
                                        [ngClass]="{'is-invalid':webhookHeaders.at(i).get('key').touched && webhookHeaders.at(i).get('key').invalid,'is-valid':webhookHeaders.at(i).get('key').valid && webhookHeaders.at(i).get('key').touched}" />
                                    </div>
                                    <div
                                      *ngIf="webhookHeaders.at(i).get('key').touched && webhookHeaders.at(i).get('key').invalid"
                                      class="invalid-feedback">
                                      Please enter a key for your header
                                    </div>
                                    <div class="p-1 col-md-5 col-sm-5 col-5">
                                      <input type="text" formControlName="value" class="form-control col-md-12"
                                        placeholder="application/json"
                                        [ngClass]="{'is-invalid':webhookHeaders.at(i).get('value').touched && webhookHeaders.at(i).get('value').invalid,'is-valid':webhookHeaders.at(i).get('value').valid && webhookHeaders.at(i).get('value').touched}" />
                                    </div>
                                    <div
                                      *ngIf="webhookHeaders.at(i).get('value').touched && webhookHeaders.at(i).get('value').invalid"
                                      class="invalid-feedback">
                                      Please enter a value for your header
                                    </div>

                                    <div class="p-1 col-md-2 col-sm-2 col-2">
                                      <button class="btn btn-outline-danger" (click)="deleteWebhookHeader(i)">
                                        <i class="fa-regular fa-trash-can"></i>
                                      </button>
                                      <button *ngIf="last" class="btn btn-outline-primary end my-1"
                                        [disabled]="!webhookEnabled.value" (click)="addWebhookHeader()">
                                        <i class="fa-solid fa-plus"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button *ngIf="webhookHeaders.length === 0" class="btn btn-outline-primary end"
                              [disabled]="!webhookEnabled.value" (click)="addWebhookHeader()">
                              <i class="fa-solid fa-plus"></i>
                            </button>
                            <!-- header end-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Webhook -->
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 my-2">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <h4 *ngIf="selectedGeneratedCode==='json'" class="card-title">Generated Json</h4>
              <h4 *ngIf="selectedGeneratedCode==='curl'" class="card-title">Generated Curl</h4>
              <div class="row">
                <div class="col-4">
                  <div class="btn-group flex-wrap" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="apiexample" value="json" id="json" checked
                      (change)="selectClient($event)">
                    <label class="btn" for="json">JSON</label>

                    <input type="radio" class="btn-check" name="apiexample" value="curl" id="curl"
                      (change)="selectClient($event)">
                    <label class="btn" for="curl">cURL</label>
                  </div>
                </div>
                <div class="col-8">
                  <button type="button" class="btn btn-primary create-button float-end" i18n (click)="copyText()">
                    Copy
                  </button>
                </div>
              </div>
              <pre class="overflow-auto pre">
                <code *ngIf="selectedGeneratedCode==='json'" class="col-md-12 col-12" [highlight]="getFormValue() | json" [languages]="['json']"></code>
                <code *ngIf="selectedGeneratedCode==='curl'" class="col-md-12 col-12" [highlight]="getCurlValue()" [languages]="['bash']"></code>
              </pre>
            </div>
          </div>
        </div>
        <!-- End Row -->
      </div>
    </div>
  </div>
</form>

<div aria-live="polite" aria-atomic="true" class="position-relative">
  <div class=" toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 11">
    <div #myToast role="alert" aria-live="assertive" aria-atomic="true" class="toast fade" data-bs-autohide="true">
      <div class="toast-header">S
        <!-- 
           <img src="..." class="rounded me-2" alt="...">
           -->
        <strong class="me-auto">{{_toast.heading}}</strong>
        <small>{{_toast.time}}</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        {{_toast.message}}
      </div>
    </div>
  </div>
</div>