import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExtractionDataDto } from 'src/app/_model/ExtractionDataDto';
import ScraperData from 'src/app/_model/Scraperdata';
import { ScraperService } from 'src/app/_service/scraper.service';
import { HighlightLoader,HighlightAutoResult } from 'ngx-highlightjs';
import { ExtractionDataUIDTO } from 'src/app/_model/ExtractionDataUIDTO';

@Component({
  selector: 'app-scraper-extractions',
  templateUrl: './scraper-extractions.component.html',
  styleUrls: ['./scraper-extractions.component.scss']
})
export class ScraperExtractionsComponent implements OnInit{
  scraperId: string;
  scraper:ScraperData;
  extractionDataUIDTO:ExtractionDataUIDTO;
  extractions: ExtractionDataDto[];
  selectedExtraction:ExtractionDataDto;
  response: HighlightAutoResult;
  p: number = 1;
  itemsPerPage = 10;


  constructor(private route: ActivatedRoute,
              private scraperService: ScraperService,
              private hljsLoader: HighlightLoader){}


  ngOnInit(): void {
    this.route.paramMap.subscribe(
      paramMap=>{
        this.scraperId = paramMap.get('id')
        this.fetchExtractionsFromScraper(this.scraperId)
        this.fetchScraper(this.scraperId)
      });
  }


  fetchExtractionsFromScraper(id:string){
    this.scraperService.getAllScraperExtractions(id,0).subscribe(
      {
        next: (res) => {
          this.extractionDataUIDTO =res
          this.extractions = res.extractionDTOS
          if(res.extractionDTOS != null){
            this.selectedExtraction = res.extractionDTOS[0];
          }else{
            this.selectedExtraction = null
          }
        },
        error: (err) => {

        }
      }
    )
  }

  fetchScraper(id:string){
    this.scraperService.getScraperById(id).subscribe(
      {
        next: (res)=> {
          this.scraper=res
        },
        error:(err)=> {
          //handle error
        }
      })
  }

  selectedRow(index:number){
    this.selectedExtraction =this.extractions[index]
  }

  changePage(_event:any){
    this.scraperService.getAllScraperExtractions(this.scraperId,_event -1)
    .subscribe(
      {
        next: (res)=>{
          this.extractions = res.extractionDTOS;
          this.p = res.currentPage + 1;
        },
        error:(err)=> {
          //handle error
        }
      })
  }

  onHighlight(e: HighlightAutoResult) {
    this.response = {
      language: e.language,
      relevance: e.relevance,
      secondBest: '{...}',
      value: '{...}',
    };
  }

}
