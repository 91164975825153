import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';
import { ExtractionPayloadDto, ExtractionsResponse } from 'src/app/_model/ExtractionPayloadDto';
import { Type } from 'src/app/_model/enum/Type';
import { ExtractionService } from 'src/app/_service/extraction.service';


@Component({
  selector: 'app-view-scrape',
  templateUrl: './view-scrape.component.html',
  styleUrls: ['./view-scrape.component.scss']
})
export class ViewScrapeComponent implements OnInit {
  extractionDataPayload:ExtractionPayloadDto={
    url: '',
    timeStamp: undefined,
    data: '',
    id: '',
    agent:{
      resolution:{
        width:undefined,
        height: undefined
      },
      options:{
        headers: null
      }
    },
    artifacts: [],
    outputAgents: {
      statusCode: 0,
      headers: null,
      extractions:[{
        request:{
          type:"",
          value:""},
        response: "",
      }]
    },
    extractionsResponse:[],
    webhook:{
      url:"",
      enabled: undefined,
      method: undefined,
      header: undefined,
      successful: undefined,
      retry: undefined

    }
  }

  json: { htmlData: string[] } = { htmlData: [] };
  data:string;
  identifier:string;
  editorOptions = {theme: 'vs-dark', language: 'html', readOnly: true};
  code: string= '';
  fullExtraction: string[]=[];
  cssSelectorExtractions: string[]=[];
  regexExtractions: string[]=[];
  headers={};
  imageUrl: string = '';


  constructor(private extractionService:ExtractionService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute) {
     }

  ngOnInit(): void{
    this.spinner.show()
    this.route.paramMap.subscribe(
      paramMap=>{
        this.identifier = paramMap.get('id')
        this.fetchPayload(this.identifier)
      });
  }
  
  async fetchPayload (identifier:string){
    this.extractionService.getCrawlPayload(identifier).pipe(finalize(()=> this.spinner.hide()))
    .subscribe(res => {
      this.extractionDataPayload = res;
      if(this.extractionDataPayload?.extractionsResponse?.length > 0){
        this.getExtractions(this.extractionDataPayload?.extractionsResponse);
      }
    })
  }

  getExtractions (extractions:ExtractionsResponse[]){
    extractions.forEach((extraction) => {
      switch (extraction.request.type) {
        case Type.full:
          this.fullExtraction.push(...extraction.response);
          break;
        case Type.css:
          this.cssSelectorExtractions.push(...extraction.response);
          break;
        case Type.regex:
          this.regexExtractions.push(...extraction.response);
          break;
        default:
          break;
      }
    });
  }

  downloadData(selector:string){
    switch(selector){
      case 'css':
        this.json.htmlData = this.cssSelectorExtractions;
        break;
      case 'full':
        this.json.htmlData = this.fullExtraction;
        break;
      case 'regex': 
        this.json.htmlData = this.regexExtractions;
        break;
    }
    const jsonData = JSON.stringify(this.json, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = this.extractionDataPayload.id+'_extraction_data.json'; 
    a.click();
    window.URL.revokeObjectURL(url);
  }

  setImgUrlModal(screenshotIndex:number){
    this.imageUrl = this.extractionDataPayload.artifacts[screenshotIndex];
  }
  

}
