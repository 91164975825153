import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'header'
})
export class HeaderPipe implements PipeTransform {
    transform(value: Map<string, string>): { key: string, value: string }[] {
      if (!value || !(value instanceof Map)) {
        return [];
      }
      return Array.from(value.entries()).map(([key, value]) => ({ key, value }));
    }
}
