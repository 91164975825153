import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-pages',
  templateUrl: './error-pages.component.html',
  styleUrls: ['./error-pages.component.scss']
})
export class ErrorPagesComponent implements OnInit {

  quotes:string[]=[
    "Help me, Obi-Wan Kenobi. You're my only hope... of finding that missing page!",
    "Do or do not. There is no 404.",
    "I find your lack of webpage disturbing.",
    "The ability to destroy a webpage is insignificant next to the power of the Force.",
    "It's a trap! You've stumbled upon a non-existent page in this galaxy.",
    "I suggest a new strategy, R2. Let the web developers know about this 404 page!",
    "I've got a bad feeling about this... missing page.",
    "Fear is the path to the dark side... and encountering a 404 page.",
    "The Force will be with you, always. But not with this page.",
    "May the code be with you, but unfortunately, not on this particular page.",
    "The Millennium Falcon made the Kessel Run, but this page couldn't be found in less than 12 parsecs.",
    "This is not the webpage you're looking for. Move along, move along.",
    "The Jedi Council has sensed a disturbance in the web.",
    "The dark side of the web has consumed this page. Try another path, young Padawan.",
    "404 error, you will. Patience, you must have, young Jedi.",
    "These aren't the webpages you're looking for. You can go about your browsing. Move along.",
    "It's a trap! Admiral Ackbar advises you to search elsewhere.",
    "The Force is strong, but even it can't locate this missing page.",
    "The Death Star's tractor beam pulled this content into oblivion.",
    "A long time ago on a webpage far, far away... this 404 error occurred.",
    "404...Hello there!"
  ]
  quote:string;

  constructor() { }

  ngOnInit(): void {
    this.setQuotes()
  }

  setQuotes(){
    const randomIndex = Math.floor(Math.random() * this.quotes.length);
    this.quote = this.quotes[randomIndex];
  }

}
