export class ProxyList {
    static proxyList: string[] = [
        'america-north',
        'america-south',
        'middleeast',
        'europe-central',
        'europe-west',
        'europe-north',
        'europe-south',
        'asiapacific-southeast',
        'asiapacific-northeast',
        'asiapacific-south',
        'asiapacific-east',
        'africa-south',
        'america',
        'middleeast',
        'asiapacific',
        'africa',
        'worldwide',
        'europe'
      ];
}