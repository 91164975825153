<div class="row">
    <div class="col-md-12 my-2 stretch-card">
        <div class="card shadow-sm">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                      <h3 class="card-title">Scraper</h3>
                      <h5 class="text-muted">{{scraper?.name}}</h5>
                      <button *ngIf="scraper?.schedulingEnabled" class="btn btn-success btn-sm" type="button" disabled style="height: 30px;" title="Scraper is Active">
                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
                        </span> Active
                      </button>
                      <button *ngIf="!scraper?.schedulingEnabled" class="btn btn-warning btn-sm" type="button" disabled style="height: 30px;" title="Scraper is Inactive">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-fill" viewBox="0 0 16 16">
                          <path
                            d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" />
                        </svg>
                        Inactive
                      </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 my-5">
                        <div class="card shadow-sm">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <h5 class="text-muted">Extraction - {{selectedExtraction?.id}} </h5><br>
                                            <small class="text-muted">{{selectedExtraction?.timestamp | date:"medium"}}</small>
                                        </div>
                                        <br>
                                        <div class="col-md-12 col-sm-12">
                                            <div class="row">
                                                <label for="status" class="col-sm-4 col-form-label text-muted"><b>Status</b></label>
                                                <div class="col-sm-6">
                                                  <span *ngIf="selectedExtraction?.status === 'DONE' || selectedExtraction?.status=='CREATED'" class="badge rounded-pill"
                                                  [ngClass]="{'bg-success':selectedExtraction?.status==='DONE','bg-warning':selectedExtraction?.status=='CREATED'}">
                                                  {{selectedExtraction?.status | titlecase}}
                                              </span>
                                              <span *ngIf="selectedExtraction?.status=='ERROR'" class="badge rounded-pill"
                                                  [ngClass]="{'bg-danger':selectedExtraction?.status=='ERROR'}">
                                                  Failed
                                              </span>
                                              <div *ngIf="selectedExtraction?.status === 'WAITING'" class="spinner-border text-primary" role="status"
                                                  title="Waiting">
                                                </div>
                                              </div>
                                              <div class="row">
                                                <label for="status" class="col-sm-4 col-form-label text-muted"><b>URL</b></label>
                                                <div class="col-sm-6">
                                                  <input type="text" readonly class="form-control-plaintext" id="status" value="{{selectedExtraction?.url}}">
                                                </div>
                                              </div>
                                              <div class="row">
                                                <label for="status" class="col-sm-4 col-form-label text-muted"><b>Browser Actions</b></label>
                                                <div class="col-sm-6" >
                                                    <span *ngFor="let action of scraper?.extraction.workflow" class="badge rounded-pill bg-info small mx-1" id="badges">
                                                      <span *ngIf="action.action==='screenshot'" title="Screenshot" >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera" viewBox="0 0 16 16" >
                                                          <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/>
                                                          <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                                        </svg>
                                                      </span>
                                                      <span *ngIf="action.action==='full-page-screenshot'" title="Screenshot-Full" >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
                                                          <path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"/>
                                                        </svg>
                                                      </span>
                                                      <span *ngIf="action.action==='wait'" title="Wait">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hourglass-split" viewBox="0 0 16 16" >
                                                          <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                                                        </svg>
                                                      </span> 
                                                      <span *ngIf="action.action==='click'" title="Click">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mouse2" viewBox="0 0 16 16" >
                                                          <path d="M3 5.188C3 2.341 5.22 0 8 0s5 2.342 5 5.188v5.625C13 13.658 10.78 16 8 16s-5-2.342-5-5.188V5.189zm4.5-4.155C5.541 1.289 4 3.035 4 5.188V5.5h3.5V1.033zm1 0V5.5H12v-.313c0-2.152-1.541-3.898-3.5-4.154zM12 6.5H4v4.313C4 13.145 5.81 15 8 15s4-1.855 4-4.188V6.5z"/>
                                                        </svg>
                                                      </span>
                                                      <span *ngIf="action.action==='scroll'" title="Scroll">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16" >
                                                          <path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/>
                                                        </svg>
                                                      </span>
                                                    </span>

                                                </div>
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="extractions!=null" class="col-md-6 col-sm-12">
                    <div class="row">
                        <div class="pagination flex-wrap d-flex justify-content-center">
                            <pagination-controls (pageChange)="changePage($event)"></pagination-controls>
                        </div>
                    </div>
                    <div class="row">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">id</th>
                                  <th scope="col">timestamp</th>
                                  <th scope="col">status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor = "let extraction of extractions| paginate: { itemsPerPage: itemsPerPage, currentPage: p , totalItems:extractionDataUIDTO?.totalItems } let i = index" (click)="selectedRow(i)">
                                    <th>{{i+1}}</th>
                                    <td class="pe-auto" *ngIf="extraction.status === 'DONE' ||extraction.status=== 'ERROR'"> <a href="" routerLink="/extraction/{{extraction.id}}">{{extraction.id}}</a></td>
                                    <td *ngIf="extraction.status === 'WAITING'">{{extraction.id}}</td>
                                    <td>{{extraction.timestamp | date: 'medium'}}</td>
                                    <td><span *ngIf="extraction.status === 'DONE' || extraction.status=='CREATED'" class="badge rounded-pill"
                                        [ngClass]="{'bg-success':extraction.status==='DONE','bg-warning':extraction.status=='CREATED'}">
                                        {{extraction.status | titlecase}}
                                      </span>
                                      <span *ngIf="extraction.status=='ERROR'" class="badge rounded-pill"
                                        [ngClass]="{'bg-danger':extraction.status=='ERROR'}">
                                        Failed
                                      </span>
                                      <div *ngIf="extraction.status === 'WAITING'" class="spinner-border text-primary" role="status"
                                        title="Waiting">
                                        <span class="visually-hidden" i18n>Loading...</span>
                                      </div>
                                    </td>
                                </tr>
                              </tbody> 
                        </table>
                    </div>
                </div>
                <div *ngIf="extractions==null" class="col-md-6 col-sm-12">
                  <div class="flex-wrap d-flex justify-content-center align-middle h-100">
                    <h5 class="text text-muted m-auto" i18n> No Extractions done yet</h5>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
