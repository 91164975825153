<div class="row">
  <div class="grid-margin col-md-12">
    <div class="row">
      <div class="col-md-6 my-2">
        <div class="card shadow-sm h-100">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h3 class="card-title">Exctractions</h3>
                <p class="text-muted border-bottom" i18n>Overview</p>
                <div class="mr-5">
                  <h5 class="text-muted" i18n>Current Subscription</h5>
                  <p class="fs-4">{{userDataScrapeCounter}}/{{userDataScrapeLimit}}</p>
                </div>
                <div class="row">
                  <div class="mr-5">
                    <h5 class="text-muted" i18n>Active Scraper</h5>
                    <p class="fs-4">{{scraperData.length}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 stretch-card">
                <div class="diagram">
                  <canvas class="diagram" id="myDoughnutChart"></canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-md-8">
                <button [disabled]="userData.extractionLimit <= userData.extractionCounter" class="btn btn-primary "
                  routerLink="/create-extraction" i18n>Create Extraction</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 my-2">
        <div class="card shadow-sm h-100">
          <div class="card-body">
            <h3 class="card-title">Scraper</h3>
            <p class="card-title text-muted border-bottom" i18n>Manage your scraper</p>
            <div class="col-md-12">
              <div class="text-center justify-content-center align-middle h-100 " *ngIf="scraperData.length === 0">
                <span class="text-center text-muted align-middle" i18n>No scraper created yet</span>
              </div>
              <div class="col-md-12 col-sm-12 col-12 container" *ngIf="scraperData.length !== 0">
                <div class="row col-12">
                  <div class="col-3 col-sm-2">
                    <small><b>Name</b></small>
                  </div>
                  <div class="col-3 col-sm-2 col-md-3">
                    <small><b>URL</b></small>
                  </div>
                  <div class="col-md-2 col-sm-1 col-2" style="display:flex;">
                    <small><b>Status</b></small>
                  </div>
                  <div class="col-md-2 col-sm-2 col-1" style="display:flex; justify-content: center;">
                    <small><b>Action</b></small>
                  </div>
                  <div class="col-md-2 col-sm-2 col-3" style="display:flex;">
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12 col-12 container">
                <div *ngFor="let scraper of scraperData" class="row scraper-row col-12">
                  <div class="col-3 col-sm-2 overflow-hidden"
                    style="text-overflow: ellipsis; white-space: nowrap; display:flex; align-items: center;">
                    <small>{{scraper.name}}</small>
                  </div>
                  <div class="col-3 col-sm-2 col-md-3 overflow-hidden"
                    style="text-overflow: ellipsis; white-space: nowrap; display:flex; align-items: center;">
                    <small><a href="{{scraper.extraction.url}}" target="_blank"
                        rel="noopener noreferrer">{{scraper.extraction.url}}</a></small>
                  </div>
                  <div class="col-sm-1 col-md-2 col-2">
                    <button *ngIf="scraper.schedulingEnabled" class="btn btn-success btn-sm" type="button" disabled
                      style="height: 30px; min-width: 80px;" title="Scraper is Active">
                      <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
                      </span> Active
                    </button>
                    <button *ngIf="!scraper.schedulingEnabled" class="btn btn-warning btn-sm" type="button" disabled
                      style="height: 30px; min-width: 80px;" title="Scraper is Inactive">
                      <span class="d-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                          class="bi bi-pause-fill" viewBox="0 0 19 19">
                          <path
                            d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" />
                        </svg>
                      </span>Paused
                    </button>
                  </div>
                  <div class="col-md-2 col-sm-1 col-1" style="display:flex; justify-content: center;">
                    <button *ngIf="!scraper.schedulingEnabled" class="btn btn-outline-primary btn-sm"
                      title="Resume Scraper" (click)="setScraperForInvertingScheduling(scraper)"
                      data-bs-target="#togglePauseModal" data-bs-toggle="modal"
                      style="display: flex; align-items: center; justify-content: center;">
                      <i class="fa-solid fa-play"></i>
                    </button>
                    <button *ngIf="scraper.schedulingEnabled" class="btn btn-outline-primary btn-sm"
                      title="Pause Scraper" (click)="setScraperForInvertingScheduling(scraper)"
                      data-bs-target="#togglePauseModal" data-bs-toggle="modal">
                      <i class="fa-solid fa-pause"></i>
                    </button>
                  </div>
                  <div class="col-md-2 col-sm-2 col-md-2 d-flex ms-auto" style="padding-right:0px!important;">
                    <div class="btn-toolbar btn-group btn-group-sm"
                      style="height: 30px; display:flex; margin-left:auto;">
                      <div class="button-group btn-group btn-group-sm ">
                        <button class="btn btn-outline-primary btn-sm" [routerLink]="['/scrape/scraper',scraper.id]"
                          title="Edit Scraper">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-pen" viewBox="0 0 16 16">
                            <path
                              d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                          </svg>
                        </button>
                        <button class="btn btn-outline-danger btn-sm" type="button" data-bs-target="#exampleModal"
                          title="Delete Scraper" data-bs-toggle="modal" (click)="chooseDeleteScraper(scraper)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-trash" viewBox="0 0 16 16">
                            <path
                              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path fill-rule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-md-8">
                <button [disabled]="userData.extractionLimit <= userData.extractionCounter" class="btn btn-primary "
                  routerLink="/create-scraper" i18n>Create Scraper</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="grid-margin col-md-12">
    <div class="card shadow-sm">
      <div class="card-body grid-margin">
        <div class="col-md-12">
          <div class="col-12">
            <div class="row">
              <div class="col">
              </div>
              <div class="col">
                <pagination-controls class="pagination" [responsive]="true" [maxSize]="5"
                  (pageChange)="changePage($event)">
                </pagination-controls>
              </div>
              <div class="col">
                <button (click)="fetchArchivedData()" class="float-end"
                  [ngClass]="archived ? 'btn btn-primary' : 'btn btn-outline-primary'" data-bs-toggle="tooltip"
                  data-bs-placement="top" data-bs-title="Toggle between archived extractions">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-archive" viewBox="0 0 16 16">
                    <path
                      d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                  </svg>
                  Archived
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="number-column">#</th>
                  <th class="col-2 text-left" i18n>URL</th>
                  <th class="col-2 text-left d-none d-sm-table-cell" i18n>Id</th>
                  <th class="col-1 col-md-1 col-sm-1 text-center d-none d-sm-table-cell" i18n>Timestamp</th>
                  <th class="col-3 text-center d-none d-sm-table-cell" i18n>Browser-Workflow</th>
                  <th class="col-1 text-center" i18n>Status</th>
                  <th class=""></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="extractionData.length===0">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="col-2 text-center"><span class="text-muted" i18n>No extraction done yet</span></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr
                  *ngFor="let data of extractionData | paginate: { itemsPerPage: itemsPerPage, currentPage: p , totalItems:extractionDataUIDTO.totalItems } let indexOfelement=index">
                  <td class="number-column"><small>{{indexOfelement + 1 + (p*itemsPerPage) -10}}</small></td>
                  <td class="col-2 text-left url-column text-truncate"><small><a title="{{data.url}}"
                        href="{{data.url}}" target="_blank" rel="noopener noreferrer">{{data.url}}</a></small>
                  </td>
                  <td class="col-2 d-none d-sm-table-cell">
                    <span class="text-muted"><small>{{data.id}}</small></span>
                  </td>
                  <td class="pl-0 col-1 text-center d-none d-sm-table-cell">
                    <span class="text-muted"><small>{{data.timestamp | date :'dd.MM.yy-HH:mm'}}</small></span>
                  </td>
                  <td class="col-auto text-center d-none d-sm-table-cell">
                    <span *ngFor="let action of data.workflow" class="badge rounded-pill bg-info small mx-1"
                      id="badges">
                      <span *ngIf="action.action==='screenshot'" title="Screenshot">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-camera" viewBox="0 0 16 16">
                          <path
                            d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                          <path
                            d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                        </svg>
                      </span>
                      <span *ngIf="action.action==='full-page-screenshot'" title="Screenshot-Full">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
                        </svg>
                      </span>
                      <span *ngIf="action.action==='wait'" title="Wait">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-hourglass-split" viewBox="0 0 16 16">
                          <path
                            d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                        </svg>
                      </span>
                      <span *ngIf="action.action==='click'" title="Click">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-mouse2" viewBox="0 0 16 16">
                          <path
                            d="M3 5.188C3 2.341 5.22 0 8 0s5 2.342 5 5.188v5.625C13 13.658 10.78 16 8 16s-5-2.342-5-5.188V5.189zm4.5-4.155C5.541 1.289 4 3.035 4 5.188V5.5h3.5V1.033zm1 0V5.5H12v-.313c0-2.152-1.541-3.898-3.5-4.154zM12 6.5H4v4.313C4 13.145 5.81 15 8 15s4-1.855 4-4.188V6.5z" />
                        </svg>
                      </span>
                      <span *ngIf="action.action==='scroll'" title="Scroll">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-arrow-down-up" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
                        </svg>
                      </span>
                    </span>
                  </td>
                  <td class="col-1 text-center">
                    <span *ngIf="data.status === 'DONE'" title="Done">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green"
                        class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                    </span>
                    <span *ngIf="data.status==='CREATED'" class="badge rounded-pill"
                      [ngClass]="{'bg-warning':data.status==='CREATED'}">
                      {{data.status | titlecase}}
                    </span>

                    <span *ngIf="data.status==='ERROR'" class="badge rounded-pill"
                      [ngClass]="{'bg-danger':data.status==='ERROR'}">
                      Failed
                    </span>
                    <div *ngIf="data.status === 'WAITING'" class="spinner-border spinner-border-sm text-primary"
                      role="status">
                      <span class="visually-hidden" i18n></span>
                    </div>
                  </td>
                  <td class="col-auto">
                    <div class="btn-group float-end">
                      <button [disabled]="data.status === 'WAITING' || data.status === 'CREATED'"
                        [routerLink]="['/extraction/',data.id]" class="btn btn-outline-primary btn-sm mx-1"
                        data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Inspect Extracted Data" i18n>
                        Inspect
                      </button>
                      <button [disabled]="data.status !== 'DONE' || userDataScrapeCounter>=userDataScrapeLimit"
                        class="btn btn-outline-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="top"
                        data-bs-title="Repeat Extraction" title="Repeat Extraction" (click)="selectScrape(data)"
                        data-bs-target="#repeatScrapeModal" data-bs-toggle="modal">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                          class="bi bi-arrow-repeat" viewBox="0 0 16 16">
                          <path
                            d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                          <path fill-rule="evenodd"
                            d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner type="ball-pulse" bdColor="rgba(0, 0, 0, 0.6)" size="medium" color="#fff" [fullScreen]="true">
</ngx-spinner>

<!-- Modal Repeat Extraction -->
<div class="modal fade" id="repeatScrapeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="repeatScrapeModal" i18n>Repeat Extraction</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-group row">
          <label for="ScrapedAt" class="col-md-3 col-sm-3 col-form-label" i18n><b>URL</b></label>
          <div class="col-sm-9">
            <input type="datetime" readonly class="form-control-plaintext" id="ScrapedAt"
              value="{{selectedScrape?.url}}">
          </div>
        </div>
        <div class="form-group row">
          <label for="ScrapedAt" class="col-md-3 col-sm-3 col-form-label" i18n><b>Workflow</b></label>
          <div *ngIf="selectedScrape?.workflow?.length > 0" class="col-sm-9">
            <span *ngFor="let action of selectedScrape.workflow" class="badge rounded-pill bg-info small mx-1"
              id="badges">
              <span *ngIf="action.action==='screenshot'" title="Screenshot">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera"
                  viewBox="0 0 16 16">
                  <path
                    d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                  <path
                    d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                </svg>
              </span>
              <span *ngIf="action.action==='full-page-screenshot'" title="Screenshot-Full">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
                </svg>
              </span>
              <span *ngIf="action.action==='wait'" title="Wait">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-hourglass-split" viewBox="0 0 16 16">
                  <path
                    d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                </svg>
              </span>
              <span *ngIf="action.action==='click'" title="Click">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mouse2"
                  viewBox="0 0 16 16">
                  <path
                    d="M3 5.188C3 2.341 5.22 0 8 0s5 2.342 5 5.188v5.625C13 13.658 10.78 16 8 16s-5-2.342-5-5.188V5.189zm4.5-4.155C5.541 1.289 4 3.035 4 5.188V5.5h3.5V1.033zm1 0V5.5H12v-.313c0-2.152-1.541-3.898-3.5-4.154zM12 6.5H4v4.313C4 13.145 5.81 15 8 15s4-1.855 4-4.188V6.5z" />
                </svg>
              </span>
              <span *ngIf="action.action==='scroll'" title="Scroll">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-down-up" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
                </svg>
              </span>
            </span>
          </div>
          <div *ngIf="selectedScrape?.workflow?.length < 0" class="col-sm-9">
            <input *ngFor="let action of selectedScrape.workflow" type="datetime" readonly
              class="form-control-plaintext" id="ScrapedAt" value="No Workflow">
          </div>
        </div>
        <div class="form-group row">
          <label for="ScrapedAt" class="col-md-3 col-sm-3 col-form-label" i18n><b>Connectivity</b></label>
          <div class="col-sm-9">
            <input type="datetime" readonly class="form-control-plaintext" id="connectivity"
              value="{{selectedScrape?.connectivity?.proxy | titlecase}}">
          </div>
        </div>
        <div class="form-group row">
          <label for="ScrapedAt" class="col-md-3 col-sm-3 col-form-label" i18n><b>Window Size</b></label>
          <div class="col-sm-9">
            <input type="datetime" readonly class="form-control-plaintext" id="windowsize"
              value="{{selectedScrape?.agent?.resolution?.width}} x {{selectedScrape.agent?.resolution?.height}}">
          </div>
        </div>
        <br>
        <p i18n> <b>Are you sure you want repeat this task?</b></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
        <button (click)="repeatExtraction()" data-bs-dismiss="modal" class="resetbutton btn btn-outline-primary"
          i18n>Repeat
          Extraction
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-arrow-repeat"
            viewBox="0 0 16 16">
            <path
              d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
            <path fill-rule="evenodd"
              d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Delete Schedule -->
<div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModal" i18n>Delete Schedule</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p><small>This will permanently delete this scheduling Task. It will deleted and stop scheduling
            immediately</small></p>
        <p> <b>Are you sure you want to Delete this Scheduling Task?</b></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" i18n>Cancel</button>
        <button (click)="deleteScraper()" data-bs-dismiss="modal" class="resetbutton btn btn-danger" i18n>Delete
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
            viewBox="0 0 16 16">
            <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
            <path fill-rule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Pause Schedule -->
<div class="modal fade" id="togglePauseModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5>{{selectedScraperData?.schedulingEnabled ? 'Pause Scraper' : 'Resume Scraper' }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p><small>This will {{selectedScraperData?.schedulingEnabled ? 'stop' : 'resume' }} the scheduling Task. You can
            {{selectedScraperData?.schedulingEnabled ? 'resume' : 'pause' }} it any time again</small></p>
        <p> <b>Are you sure you want to {{selectedScraperData?.schedulingEnabled ? 'Pause' : 'Resume' }} this
            Scraper?</b></p>
      </div>
      <div class="modal-footer">
        <button (click)="invertSchedulingEnabled()" data-bs-dismiss="modal" class="resetbutton btn btn-outline-primary">
          {{selectedScraperData?.schedulingEnabled ? 'Pause' : 'Resume' }}
        </button>
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<!--- Toast Sucess-->
<div aria-live="polite" aria-atomic="true" class="position-relative">
  <div class=" toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 11">
    <div #myToast role="alert" aria-live="assertive" aria-atomic="true" class="toast fade" data-bs-autohide="true">
      <div class="toast-header">
        <!-- 
           <img src="..." class="rounded me-2" alt="...">
           -->
        <strong class="me-auto">{{_toast.heading}}</strong>
        <small>{{_toast.time}}</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        {{_toast.message}}
      </div>
    </div>
  </div>
</div>