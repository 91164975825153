<div class="row">
    <div class="col-md-12 my-2 grid-margin stretch-card">
        <div class="card shadow-sm">
            <div class="card-body">
                <div class="col-12 col-md-12 col-sm-12 col-lg-12">
                    <h3 class="card-title text-md-left text-xl-left text-muted" i18n>Your Extracted Data</h3>
                    <div class="row">
                        <div class="col-md-6 col-12 my-1">
                            <div class="form-group row">
                                <label for="url" class="col-md-3 col-sm-3 col-form-label" i18n><b>URL</b></label>
                                <div class="col-sm-9">
                                    <input type="url" readonly class="form-control-plaintext" id="url"
                                        value="{{extractionDataPayload.url}}">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ScrapedAt" class="col-md-3 col-sm-3 col-form-label" i18n><b>Scraped
                                        at</b></label>
                                <div class="col-sm-9">
                                    <input type="datetime" readonly class="form-control-plaintext" id="ScrapedAt"
                                        value="{{extractionDataPayload.timeStamp | date :'dd.MM.yyyy - HH:mm'}}">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="id" class="col-md-3 col-sm-3 col-form-label" i18n><b>Id</b></label>
                                <div class="col-sm-9">
                                    <input type="text" readonly class="form-control-plaintext" id="id"
                                        value="{{extractionDataPayload.id}}">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <div class="col-md-3 col-sm-3 col-3">
                                    <label for="id"
                                        class="col-md-12 col-12 col-sm-12 col-form-label"><b>Attachment</b></label>
                                </div>
                                <div *ngFor="let screenshot of extractionDataPayload.artifacts;  index as i;"
                                    class="col-sm-3 col-3 col-md-3">
                                    <img class="img-thumbnail" [src]="screenshot | thumbnail" alt="Image"
                                        data-bs-toggle="modal" data-bs-target="#screenshotModal"
                                        style="cursor: pointer;" (click)="setImgUrlModal(i)">
                                </div>
                            </div>
                            <div class="row">
                                <div *ngIf="extractionDataPayload?.outputAgents?.headers !== null"
                                    class="form-group row">
                                    <div for="id" class="col-md-3 col-sm-12"
                                        style="padding-top:20px; padding-bottom: 20px;"><b>Headers</b></div>
                                    <div class="col-sm-12 col-9 col-md-9">
                                        <div class="accordion accordion-flush" id="accordionFlushExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="flush-headingThree">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#flush-extraction"
                                                        aria-expanded="false" aria-controls="flush-extraction">
                                                        Show Headers
                                                    </button>
                                                </h2>
                                                <div id="flush-extraction" class="accordion-collapse collapse"
                                                    aria-labelledby="flush-headingThree"
                                                    data-bs-parent="#accordionFlushExample">
                                                    <div class="accordion-body">
                                                        <div *ngFor="let header of extractionDataPayload?.outputAgents?.headers | keyvalue"
                                                            class="form-group">
                                                            <span [ngStyle]="{ 'font-weight': 'bold' }">{{ header.key }}
                                                                :</span>
                                                            <span>{{ header.value }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div *ngIf="extractionDataPayload.webhook?.url !== null" class="form-group row">
                                <h5><b>Webhok</b></h5>
                                <div class="form-group row">
                                    <label for="webhook-url" class="col-md-3 col-sm-3 col-form-label"><b>Url</b></label>
                                    <div class="col-sm-9">
                                        <input type="text" readonly class="form-control-plaintext" id="webhook-url"
                                            value="{{extractionDataPayload.webhook.url}}">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="webhook-method" class="col-md-3 col-sm-3 col-form-label"
                                        i18n><b>Method</b></label>
                                    <div class="col-sm-9">
                                        <input type="text" readonly class="form-control-plaintext" id="webhook-method"
                                            value="{{extractionDataPayload.webhook.method | uppercase}}">
                                    </div>
                                </div>
                                <div *ngIf="extractionDataPayload.webhook.header !== null" class="form-group row">
                                    <label for="webhook-method" class="col-md-3 col-sm-3 col-form-label"
                                        i18n><b>Header</b></label>
                                    <div class="col-sm-9">
                                        <input *ngFor="let header of extractionDataPayload.webhook.header" type="text"
                                            readonly class="form-control-plaintext" id="webhook-method"
                                            value="{{header.key}}:{{header.value}}">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="webhook-method" class="col-md-3 col-sm-3 col-form-label"
                                        i18n><b>Status</b></label>
                                    <div class="col-sm-9">
                                        <span *ngIf="extractionDataPayload.webhook.successful"
                                            class="badge rounded-pill text-bg-success align-baseline mt-2">Successful</span>
                                        <span
                                            *ngIf="!extractionDataPayload.webhook.successful && extractionDataPayload.webhook.retry < 5"
                                            class="badge rounded-pill text-bg-warning align-baseline mt-2">Pending
                                            <div class="spinner-grow spinner-grow-sm text-light" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </span>
                                        <span
                                            *ngIf="!extractionDataPayload.webhook.successful && extractionDataPayload.webhook.retry >= 5"
                                            class="badge rounded-pill text-bg-danger align-baseline mt-2">Failed</span>
                                    </div>
                                </div>
                                <div *ngIf="!extractionDataPayload.webhook.successful" class="form-group row">
                                    <label for="webhook-method" class="col-md-3 col-sm-3 col-form-label"
                                        i18n><b>Retries</b></label>
                                    <div class="col-sm-9">
                                        <input type="text" readonly class="form-control-plaintext" id="webhook-method"
                                            value="{{extractionDataPayload.webhook.retry}}">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <ng-container *ngFor="let extraction of extractionDataPayload.extractionsResponse; index as i;">
                            <ng-container *ngIf="extraction.request.type === 'full'">
                                <h6 class="text-muted">Full Extraction</h6>
                                <div class="row">
                                    <div class="col-md-6 grid-margin">
                                        <button class="btn btn-primary" (click)="downloadData('full')"
                                            i18n>Download</button>
                                    </div>
                                </div>
                                <pre class="col-md-6 col-12">
                                    <code [highlight]="fullExtraction[0]" [languages]="['html']" class="codeblock"></code>
                                </pre>
                            </ng-container>
                            <ng-container *ngIf="extraction.request.type === 'regex'">
                                <div class="col-auto m-1">
                                    <h6 class="text-muted">Regex</h6>
                                    <div class="row">
                                        <div class="col-md-6 grid-margin">
                                            <button class="btn btn-primary" (click)="downloadData('regex')"
                                                i18n>Download</button>
                                        </div>
                                    </div>
                                    <pre *ngFor="let extraction of regexExtractions" class="col-md-12">
                                        <code  [highlight]="extraction" [languages]="['html']" class="codeblock"></code>
                                    </pre>
                                </div>

                            </ng-container>
                            <ng-container *ngIf="extraction.request.type === 'css'">
                                <div class="col-auto m-1">
                                    <h6 class="text-muted">Css selector</h6>
                                    <div class="row">
                                        <div class="col-md-6 grid-margin">
                                            <button class="btn btn-primary" (click)="downloadData('css')"
                                                i18n>Download</button>
                                        </div>
                                    </div>
                                    <pre *ngFor="let extraction of cssSelectorExtractions" class="col-md-12">
                                        <code [highlight]="extraction" [languages]="['html']" class="codeblock"></code>
                                    </pre>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- Screenshot Modal -->
<div class="modal fade" id="screenshotModal" tabindex="-1" aria-labelledby="screenshotModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="screenshotModalLabel">Screenshot</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <img class="img-fluid" [src]="imageUrl" alt="Image">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                <a class="btn btn-primary" [href]="imageUrl" class="btn btn-primary">Download Screenshot</a>
            </div>
        </div>
    </div>
</div>
<ngx-spinner type="ball-pulse" bdColor="rgba(0, 0, 0, 0.6)" size="medium" color="#fff" [fullScreen]="true">
</ngx-spinner>