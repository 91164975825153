<div class="row">
  <div class="col-md-12 grid-margin py-2">
      <div class="card shadow-sm ">
        <div class="card-body d-flex flex-column">
          <div class="pricing-header p-3 pb-md-4 mx-auto text-center">
            <h1 class="display-4 fw-normal" i18n>404</h1>
            <p class="fs-5 text-muted" i18n>{{quote}}</p>
        </div>
        </div>
      </div>
  </div>
</div>