<form [formGroup]="form">
  <div class="row">
    <div class="grid-margin col-md-12">
      <div class="row">
        <div class="col-md-12 my-2">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <h3 class="card-title">Extractors</h3>
              <div class="col-md-3">
                <button *ngIf="isAdmin" data-bs-toggle="modal" data-bs-target="#createExtractorModal"
                  class="btn btn-primary" style="color: white!important;"> Create Extractor</button>
              </div>
              <hr>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="mt-auto">
                        <div *ngIf="extractorData.length === 0">
                          <p>No Extractors available</p>
                        </div>
                        <h4>Extractor List</h4>
                        <div *ngIf="extractorData" class="list-group overflow-auto">
                          <button *ngFor="let extractor of extractorData; let i = index" type="button"
                            class="list-group-item list-group-item-action"
                            (click)="selectExtractorIndex(i)">{{extractor.title}} <span
                              *ngIf="userExtractorList.includes(extractor.id)"
                              class="badge text-bg-success float-end">Added</span></button>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 flex-column">
                      <div class="mt-auto">
                        <div *ngIf="extractorData.length !== 0" class="card">
                          <div class="card-header">
                            Details
                          </div>
                          <div class="card-body overflow-auto">
                            <div class="row">
                              <div class="col-md-8">
                                <h5 class="card-title">{{selectedExtractor?.title}} <span
                                    *ngIf="userExtractorList?.includes(selectedExtractor?.id)"
                                    class="badge text-bg-success">Added</span></h5>
                                <p class="card-text">{{selectedExtractor?.description}}</p>
                              </div>
                              <div class="col-md-4">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <button *ngIf="!userExtractorList?.includes(selectedExtractor?.id)"
                                        data-bs-toggle="modal" data-bs-target="#addExtractorModal"
                                        class="btn btn-outline-success">
                                        Add to
                                        Extractor
                                        List</button>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12">
                                      <button *ngIf="userExtractorList?.includes(selectedExtractor?.id)"
                                        data-bs-toggle="modal" data-bs-target="#removeExtractorModal"
                                        class="btn btn-outline-danger">Remove
                                        from
                                        Extractor List</button>
                                    </div>
                                  </div>
                                </div>
                                <div *ngIf="isAdmin" class="form-check form-switch">
                                  <input class="form-check-input" type="checkbox" id="enabled"
                                    [checked]="selectedExtractor?.enabled"
                                    (change)="enableExtractor($event,selectedExtractor)">
                                  <label class="form-check-label" for="enabled">Enabled</label>
                                </div>
                                <button *ngIf="isAdmin" data-bs-toggle="modal" data-bs-target="#deleteExtractorModal"
                                  class="btn btn-outline-danger"><i class="fa-solid fa-trash"></i></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="alert alert-success" role="alert">
                        <h4 class="alert-heading">What are Extractors?</h4>
                        <p>An Extractor is a ready to use selector we made for you. We provided the right selectors for
                          you
                          to
                          collect the most common Data. <br>If you want to add an Extractor to your Extractor list, just
                          check the
                          "<b>Add to Extractors</b>" mark
                        </p>
                        <hr>
                        <p class="mb-0"><u> Requesting your own custom Extractor:</u><br /><br />Create a support-ticket
                          with the
                          following
                          information <br /><br />
                        <ul>
                          <li>Full web site url you want to scrape</li>
                          <li>Information you want to extract</li>
                          <li>Additional information that might be a help for us</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner type="ball-pulse" bdColor="rgba(0, 0, 0, 0.6)" size="medium" color="#fff" [fullScreen]="true">
  </ngx-spinner>


  <!-- Create Extractor Modal -->
  <div class="modal fade" id="createExtractorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createnewsModal">Create Extractor</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            style="color: white!important;"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-sm-7" [formGroup]="form">
              <div class="form-group">
                <label for="title" class="label-description"> Title</label>
                <input type="text" id="title" class="form-control" formControlName="title">
              </div>
              <div class="form-group">
                <label for="key" class="label-description"> Key</label>
                <input type="text" id="key" class="form-control" formControlName="key">
              </div>
              <div class="form-group">
                <label for="newsText" class="label-description">Description</label>
                <textarea class="form-control" formControlName="description" id="description" rows="3"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
          <button (click)="createExtractor()" data-bs-dismiss="modal" class="btn btn-primary my-2 col-md-2"
            [disabled]="!form.valid" style="color: white!important;">Create</button>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- Delete Extractor Modal -->
<div class="modal fade" id="deleteExtractorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteExtractorModal">Delete Extractor</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          style="color: white!important;"></button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <p>Are you sure you want to delete this Extractor?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
        <button (click)="deleteExtractor(selectedExtractor.id)" data-bs-dismiss="modal"
          class="btn btn-primary my-2 col-md-2" style="color: white!important;">Delete</button>
      </div>
    </div>
  </div>
</div>

<!-- Add Extractor Modal -->
<div class="modal fade" id="addExtractorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addExtractorModal">Add Extractor</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          style="color: white!important;"></button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <p>Are you sure you want to add this Extractor to your extractor list?</p>
          <p class="text-muted">
            This extractor will be available in your extractor list when creating Extractions or Scraper
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
        <button (click)="toggleExtractorToUser(selectedExtractor?.id)" data-bs-dismiss="modal"
          class="btn btn-success my-2 col-md-2" style="color: white!important;">Add</button>
      </div>
    </div>
  </div>
</div>

<!-- Remove Extractor Modal -->
<div class="modal fade" id="removeExtractorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="removeExtractorModal">Remove Extractor</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          style="color: white!important;"></button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <p>Are you sure you want to remove this Extractor from your Extractor-List?</p>
          <p class="text-muted small">You can add this extractor anytime again</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
        <button (click)="toggleExtractorToUser(selectedExtractor?.id)" data-bs-dismiss="modal"
          class="btn btn-primary my-2 col-md-2" style="color: white!important;">Remove</button>
      </div>
    </div>
  </div>
</div>