import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Toast } from 'bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ApiDataDto } from 'src/app/_model/ApiDataDto';
import { ApiStatus } from 'src/app/_model/ApiStatus';
import { KeycloakService } from 'keycloak-angular';
import { CreateExtractionDto } from 'src/app/_model/CreateExtractionDto';
import { CreateScraperDto } from 'src/app/_model/CreateScraperDto';
import { Days } from 'src/app/_model/Days';
import { Extractor } from 'src/app/_model/Extractor';
import { ProxyList } from 'src/app/_model/ProxyList';
import { ApiService } from 'src/app/_service/api.service';
import { ExtractorService } from 'src/app/_service/extractor.service';
import { ScraperService } from 'src/app/_service/scraper.service';
import { __values } from 'tslib';

@Component({
  selector: 'app-create-scrape',
  templateUrl: './create-scrape.component.html',
  styleUrls: ['./create-scrape.component.scss'],
})
export class CreateScrapeComponent implements OnInit {
  @ViewChild('myToast',{static:true}) toastEl!: ElementRef<HTMLDivElement>;
  toast: Toast | null = null;
  bootstrap: any;
  _toast={ heading:"", message:"", time:"", visibility:"hidden" }
  checkboxControl: FormControl;
  newScraper: CreateScraperDto;
  selectedGeneratedCode = 'json';

  newExtraction: CreateExtractionDto;

  isAdmin = false;

  form: UntypedFormGroup;
  browserActionsFormClick: UntypedFormGroup;
  browserActionsFormScroll: UntypedFormGroup;
  URL_REGEXP =
    /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s\/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-\/]*)?$/;
  selectedOption = '';
  scrollXValue: number=null;
  scrollYValue: number=null;
  clickXValue: number=null;
  clickYValue: number=null;
  waitValue: number = null;
  scrollValid = false;
  clickValid= false;
  selectedOptionValid = false;
  extractionTypes: string[]=["regex"]
  dayLabels = Days.days;
  selectedExtractionType='';
  enableProxy = false;
  proxyList = ProxyList.proxyList;
  parameters: string[] = ['scroll', 'click', 'wait', 'screenshot','full-page-screenshot'];
  currentIndex = 0;
  chosenDays: number[] = [];
  apiData:ApiDataDto={
    apiUrl: '',
    apiKey: '',
    apiStatus: new ApiStatus
  }
    extractors: Extractor[]=[]

  constructor(
    private scraperService: ScraperService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private clipboardApi: ClipboardService,
    private keycloakService:KeycloakService,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private extractorService:ExtractorService
  ) {
    this.checkboxControl = new FormControl(true);
  }

  schedulingValidator(group: FormGroup): { [key: string]: boolean } | null {
    if(group.get('scheduleType').value === 'interval'){
      const minute:number = group.get('minute').value;
      const hour:number = group.get('hour').value;
      const day:number = group.get('day').value;
      const sum = day + hour + minute;
      
      if (sum > 0 || sum != 0) {
       // At least one field is greater than 0, validation passes
      return null;
      }
      return { atLeastOneFieldGreaterThanZero: false }; // Validation fails
    } if(group.get('scheduleType').value === 'weekly') {
      const days = Object.values(group.get('weeklyScheduleDetails').value);
      const day = days.some((day: any) => day.length > 0 &&  day.every(
        (time: any) => { 
            return (time != '') && /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
          }
      ));
      if(day){
        return null;
      }
      return { atLeastOneFieldGreaterThanZero: true };
    }
  }


  ngOnInit(): void {
    this.initForm();
    this.fetchApiData();
    this.fetchExtractors();
    this.headers.disable();
    this.schedules.enable();
    this.disableAllDays();  
    this.workflowArray.disable();
    this.toast = new Toast(this.toastEl.nativeElement,{});
    this.isAdmin = this.keycloakService.isUserInRole('admin');
  }

  async fetchApiData(){
    this.apiService.getApiData().pipe().subscribe(data => {
      this.apiData = data
    })
  }

    async fetchExtractors(){
    this.extractorService.getAddedExtractors().subscribe((res)=>{
      this.extractors = res
      this.extractors.forEach((extractor)=>{
        this.extractionTypes.push(extractor.key)
      })
    })  
  }

  disableAllDays(){
    this.Monday.disable();
    this.Tuesday.disable();
    this.Wednesday.disable();
    this.Thursday.disable();
    this.Friday.disable();
    this.Saturday.disable();
    this.Sunday.disable();
  }

  onChangeCategory(parameter: string): void {
    const formArray: UntypedFormArray = this.form.get(
      'parameter'
    ) as UntypedFormArray;
    formArray.push(new UntypedFormControl(parameter));
  }

  onChangeWebhookEnable(_event: any) {
    if (_event.target.checked) {
      this.form.get('extraction.webhook.url').enable();
      this.form.get('extraction.webhook.method').enable()
      this.form.get('extraction.webhook.header').enable()
    } else {
      this.form.get('extraction.webhook.url').disable();
      this.form.get('extraction.webhook.method').disable()
      this.form.get('extraction.webhook.header').disable()
    }
  }

  onChangeScheduleSetting(_event: any){
    if(_event.target.value=='interval'){
      this.dayLabels.forEach(day => {
        // Uncheck all checkboxes       
        const checkbox = document.getElementById(day) as HTMLInputElement;
        if (checkbox) {
          checkbox.checked = false;
        } 
      });
      this.disableAllDays();

      this.form.get('scraper.scheduleDetails.scheduleType').setValue('interval')
      this.form.get('scraper.scheduleDetails.minute').enable();
      this.form.get('scraper.scheduleDetails.hour').enable();
      this.form.get('scraper.scheduleDetails.day').enable();
      this.form.get("scraper.scheduleDetails.weeklyScheduleDetails").disable();
    } else {
      this.form.get("scraper.scheduleDetails.weeklyScheduleDetails").enable();
      this.disableAllDays();
      this.form.get('scraper.scheduleDetails.scheduleType').setValue('weekly')
      this.form.get('scraper.scheduleDetails.minute').disable();
      this.form.get('scraper.scheduleDetails.hour').disable();
      this.form.get('scraper.scheduleDetails.day').disable();
    }
  }

  onChangeWindowSize(_event: any) {
    if (_event.target.checked) {
      this.form.get('parameters.windowSize.width').enable();
      this.form.get('parameters.windowSize.height').enable();
    } else {
      this.form.get('parameters.windowSize.width').disable();
      this.form.get('parameters.windowSize.height').disable();
    }
  }

  onChangeFullExtraction(_event: any) {
    if (_event.target.checked) {
      this.selector.push(
        this.fb.group({
          type: new FormControl({ value: 'full', disabled: false },Validators.required),
          value: new FormControl({ value: '', disabled: false }),
        })
      );
    } else if(!_event.target.checked){
      const index = this.selector.controls.findIndex((control: AbstractControl) => {
        const value = control.value;
        // Compare the content of the form group here
        return value.type === 'full' && value.value === '';
      });
      if (index !== -1) {
        this.selector.removeAt(index);
      }

    }
  }

  filteredExtractions() {
    return this.selector.controls.filter((control: AbstractControl) => control.value.type !== 'full');
  }

  initForm() {
    if (this.form) {
      return;
    }
    this.form = this.fb.group({
      scraper: this.fb.group({
        name:[
          {value:'', disabled: false},
          [Validators.required, Validators.maxLength(20)]
        ],
        schedulingEnabled: true,
        scheduleDetails: this.fb.group({
          scheduleType:[{value:'weekly', disabled:false},Validators.required],
          minute: [{ value: '', disabled: true }, [Validators.max(60)]],
          hour: [{ value: '', disabled: true }, [Validators.max(24)]],
          day: [{ value: '', disabled: true }, [Validators.max(31)]],
          weeklyScheduleDetails: this.fb.group({
            MONDAY: this.fb.array([]),
            TUESDAY: this.fb.array([]),
            WEDNESDAY: this.fb.array([]),
            THURSDAY: this.fb.array([]),
            FRIDAY: this.fb.array([]),
            SATURDAY: this.fb.array([]),
            SUNDAY: this.fb.array([]),
          })
        },{ validators: this.schedulingValidator }) 
      }),
      extraction: this.fb.group({
        url: [
          { value: '', disabled: false },
          [Validators.required, Validators.pattern(this.URL_REGEXP)],
        ],
        agent: this.fb.group({
          resolution: this.fb.group({
            width: [
              { value: '1903', disabled: false },
              [Validators.required, Validators.min(100), Validators.max(4100)],
            ],
            height: [
              { value: '927', disabled: false },
              [Validators.required, Validators.min(100), Validators.max(4100)],
            ]
          }),
          options: this.fb.group({
            headers: this.fb.array([]),
          }),
        }),
        connectivity: this.fb.group({
          proxy: [{ value: 'europe', disabled: false }]
        }),
        workflow: new UntypedFormArray([]),
        selector: this.fb.array([this.fb.group({
          type: new FormControl({ value: 'full', disabled: false },Validators.required),
          value: new FormControl({ value: '', disabled: false }),
        })],Validators.required),
        webhook: this.fb.group({
          enabled: false,
          url: [
            { value: '', disabled: true },
            [Validators.required, Validators.pattern(this.URL_REGEXP)],
          ],
          header:this.fb.array([]),
          method:[{ value: 'GET', disabled: true }, [Validators.required]]
        }),
      })
    });
  }

  getDay(day: string): FormArray {
    return this.form.get(`scraper.scheduleDetails.weeklyScheduleDetails.${day}`) as FormArray;
  }

  getFormDay(day: string): FormControl {
    return this.form.get(`scraper.scheduleDetails.weeklyScheduleDetails.${day}`) as FormControl;
  }

  trackBy(index: number): number {
    return index;
  }

  toggleDay(_event: any): void {
    const selectedDay = _event.target.value;
    if(_event.currentTarget.checked){
      const arr = this.getDay(selectedDay.toUpperCase());
      arr.enable();
      this.getDay(selectedDay.toUpperCase()).push(this.fb.control(''));
    } if(_event.currentTarget.checked === false && this.getDay(selectedDay.toUpperCase()).length > 0){
      this.getDay(selectedDay.toUpperCase()).clear();
      this.getFormDay(selectedDay.toUpperCase()).disable();
    }
  }

  get scheduleType(){
    return this.form.get('scraper.scheduleDetails.scheduleType');
  }

  get schedule(){
    return this.form.get('scraper.scheduleDetails');
  }

  get scraper(){
    return this.form.get('scraper');
  }

  get schedules(){
    return this.form.get('scraper.scheduleDetails.weeklyScheduleDetails')
  }

  get resolution(){
    return this.form.get('extraction.agent.resolution');
  }

  get webhookEnabled(){
    return this.form.get('extraction.webhook.enabled');
  }

  get webhookUrl() {
    return this.form.get('extraction.webhook.url');
  }

  get options(){
    return this.form.get('extraction.agent.options')
  }

  get name(){
    return this.form.get("scraper.name");
  }

  get url() {
    return this.form.get('extraction.url');
  }

  get browser() {
    return this.form.get('extraction.browser');
  }

  get selector(){
    return this.form.get('extraction.selector') as UntypedFormArray;
  }

  get workflowArray() {
    return this.form.get('extraction.workflow') as UntypedFormArray;
  }

  get width() {
    return this.form.get('extraction.agent.resolution.width');
  }

  get height() {
    return this.form.get('extraction.agent.resolution.height');
  }

  get minute() {
    return this.form.get('scraper.scheduleDetails.minute');
  }

  get hour() {
    return this.form.get('scraper.scheduleDetails.hour');
  }

  get day() {
    return this.form.get('scraper.scheduleDetails.day');
  }
  
  get Monday(){
    return this.form.get('scraper.scheduleDetails.weeklyScheduleDetails.MONDAY') as UntypedFormArray
  }

  get Tuesday(){
    return this.form.get('scraper.scheduleDetails.weeklyScheduleDetails.TUESDAY') as UntypedFormArray
  }

  get Wednesday(){
    return this.form.get('scraper.scheduleDetails.weeklyScheduleDetails.WEDNESDAY') as UntypedFormArray
  }

  get Thursday(){
    return this.form.get('scraper.scheduleDetails.weeklyScheduleDetails.THURSDAY') as UntypedFormArray
  }

  get Friday(){
    return this.form.get('scraper.scheduleDetails.weeklyScheduleDetails.FRIDAY') as UntypedFormArray
  }

  get Saturday(){
    return this.form.get('scraper.scheduleDetails.weeklyScheduleDetails.SATURDAY') as UntypedFormArray
  }

  get Sunday(){
    return this.form.get('scraper.scheduleDetails.weeklyScheduleDetails.SUNDAY') as UntypedFormArray
  }

  get headers() {
    return this.form.get('extraction.agent.options.headers') as FormArray;
  }

  get webhookHeaders(){
    return this.form.get('extraction.webhook.header') as FormArray;
  }

  get agent() {
    return this.form.get('extraction.agent');
  }

  get xScroll() {
    return this.browserActionsFormScroll.get('options.x');
  }
  get yScroll() {
    return this.browserActionsFormScroll.get('options.y');
  }

  addAction() {
    if(this.workflowArray.status==='DISABLED'){
      this.workflowArray.enable()
    }

    if (this.selectedOption == 'screenshot') {
      const screenshotObject = {
        action: this.selectedOption,
      };
      this.workflowArray.value.push(screenshotObject);
    } else if (this.selectedOption == 'scroll') {
      const scrollObject = {
        action: this.selectedOption,
        options:{
          x: this.scrollXValue,
          y: this.scrollYValue,
        }
      };
      this.workflowArray.value.push(scrollObject);
      this.scrollXValue = 0;
      this.scrollYValue = 0;
    } else if (this.selectedOption == 'click') {
      const clickObject = {
        action: this.selectedOption,
        options:{
          x: this.clickXValue,
          y: this.clickYValue,
        }
      };
      this.workflowArray.value.push(clickObject);
      this.clickXValue = 0;
      this.clickYValue = 0;
    } else if (this.selectedOption == 'wait') {
      const waitObject = {
        action: this.selectedOption,
        value: this.waitValue,
      };
      this.workflowArray.value.push(waitObject);
      this.waitValue = 0;
    }else if (this.selectedOption == 'full-page-screenshot') {
      const fullPageScreenshotObject = {
        action: this.selectedOption,
      };
      this.workflowArray.value.push(fullPageScreenshotObject);
    }
  }

  deleteBrowserAction(i: any) {
    this.workflowArray.value.splice(i, 1);
    if(i===0 && this.workflowArray.length === 1){
      this.workflowArray.disable()
    }
  }

  addHeader() {
    this.headers.push(
      this.fb.group({
        key: new FormControl({ value: '', disabled: false },Validators.required),
        value: new FormControl({ value: '', disabled: false },Validators.required),
      })
    );
  }

  deleteHeader(i: any) {
    this.headers.removeAt(i);
    if(i === 0){
      this.headers.disable()
    }
  }

  addWebhookHeader() {
    this.webhookHeaders.push(
      this.fb.group({
        key: new FormControl({ value: '', disabled: false },Validators.required),
        value: new FormControl({ value: '', disabled: false },Validators.required),
      })
    );
  }

  deleteWebhookHeader(i: any) {
    this.webhookHeaders.removeAt(i);
    if(i === 0){
      this.webhookHeaders.disable()
    }
  }

  deleteExtraction(i:any){
    if(this.selector.at(i).value.type === 'full'){
      this.checkboxControl.patchValue(false)
    }
    this.selector.removeAt(i);
  }

  deleteExtractionByValue(i: any) {
    for (let index = 0; index < this.selector.length; index++) {
      if (this.selector.at(index).value === i) {
        this.selector.removeAt(index);
      }
    }
  }


  deleteBrowserActions(i: any){
    this.workflowArray.removeAt(i);
    if(i === 0){
      this.workflowArray.disable();
    }
  }

  selecetExtractionType(_event:any){
    this.selectedExtractionType = _event.target.value
  }

  addExtraction(input: HTMLInputElement) {
    if(this.selectedExtractionType === 'Regex'){
          if (this.extractionAdditionAllowed(this.selectedExtractionType, input.value)) {
      this.selector.push(
        this.fb.group({
          type: new FormControl({ value: this.selectedExtractionType.toLowerCase(), disabled: false }, Validators.required),
          value: new FormControl({ value: input.value, disabled: false }, Validators.required),
        })
      );
      input.value = '';
    }
    } else{
            this.selector.push(
        this.fb.group({
          type: new FormControl({ value: this.selectedExtractionType.toLowerCase(), disabled: false }, Validators.required),
          value: new FormControl({ value: input.value, disabled: true }),
        })
      );
    }

  }


  toggleSelectorInput() {
    const selectorControl = this.form.get('selector');
    if (this.selectedExtractionType === 'regex') {
      selectorControl.enable();
    } else {
      selectorControl.disable();
    }
  }

  extractionAdditionAllowed(selectedExtractionType: any, inputValueRef: any){
    if(['Regex', 'Select', ''].includes(selectedExtractionType) && inputValueRef === ''){
      return false;
    }
    if(selectedExtractionType !== 'regex') {
      return true;
    }
   return true;
  }

  addButtonExpressionChecker(selectedExtractionType: any, inputValueRef: any) {
    return this.extractionAdditionAllowed(selectedExtractionType, inputValueRef);
  }

  changeParameter(_event: any) {
    this.form
      .get('browserActions')
      .setValue(_event.target.value, { onlySelf: true });
  }

  scrollXValueOnKeyUp(_event: any) {
    this.scrollXValue = _event.target.value;
    if(this.scrollXValue){
      this.scrollValid=true;
    }
  }
  scrollYValueOnKeyUp(_event: any) {
    this.scrollYValue = _event.target.value;
    if(this.scrollYValue){
      this.scrollValid=true;
    }
  }

  clickXValueOnKeyUp(_event: any) {
    this.clickXValue = _event.target.value;
    if(this.clickXValue){
      this.clickValid = true;
    }
  }

  clickYValueOnKeyUp(_event: any) {
    this.clickYValue = _event.target.value;
    if(this.clickYValue){
      this.clickValid = true;
    }
  }

  waitValueOnKeyUp(_event: any) {
    this.waitValue =_event.target.value;
  }

  selectOption(_event: any) {
    this.selectedOption = _event.target.value;
  }

  async submitData() {
    this.newScraper = this.form.value;

    this.scraperService.createScraper(this.newScraper).subscribe({
      next: () => {
        this.router.navigate(['/scrape']);
      },
      error: (e) => {
        console.log('ERROR:' + e.message);
      }
    });
  }

  copyText(){
    if(this.selectedGeneratedCode === 'json'){
      this.clipboardApi.copyFromContent(JSON.stringify(this.getFormValue()))
      this._toast.heading = "Copied Json ✅";
    } else {
      this.clipboardApi.copyFromContent(this.getCurlValue())
      this._toast.heading = ("Copied curl command ✅");
    }
    this.toast!.show();
    this._toast.time = new Date().toDateString()
    this._toast.message= "Successfully copied to clipboard!";
    this._toast.visibility= "show";
    setTimeout(()=>{this._toast.visibility="hide"},10000)
  }

  cancel() {
    this.router.navigate(['/scrape']);
  }

  setMobileResolution(){
    this.form.get('extraction.agent.resolution.width').setValue(480)
    this.form.get('extraction.agent.resolution.height').setValue(800)
  }

  setDesktopResolution(){
    this.form.get('extraction.agent.resolution.width').setValue(2048)
    this.form.get('extraction.agent.resolution.height').setValue(1080)
  }

  onInputChange() {
    this.cdr.detectChanges();
  }

  addTime(dayLabel:string): void {
    this.getDay(dayLabel).push(this.fb.control('', [Validators.required, Validators.pattern('([01]?[0-9]|2[0-3]):[0-5][0-9]')]));
  }

  removeTime(dayLabel:string): void {
    this.getDay(dayLabel).removeAt(this.getDay(dayLabel).length - 1);
  }

  getFormValue(): string {
    return this.form.value;
  }

  selectClient($event: any) {
    this.selectedGeneratedCode = $event.target.value
  }

  getCurlValue(): string {
    const jsonData = JSON.stringify(this.getFormValue());
    const curlCode = `curl -X POST ${this.apiData.apiUrl}/scraper \\
     -H "Content-Type: application/json" \\
     -H "API-KEY: ${this.apiData.apiKey}" \\
     -d '${jsonData}'`;
    return curlCode;
  }

}
