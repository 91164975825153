import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import { CreateSupportRequestDto } from '../_model/CreateSupportRequestDto';
import { SupportService } from '../_service/support.service';
import { SupportRequestType } from '../_model/enum/SupportRequestType';
import { SupportRequestDto } from '../_model/SupportRequestDto';
import { UserTierLevel } from '../_model/UserTierLevelDto';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  createSupportRequestDto:CreateSupportRequestDto={
    createdBy:"",
    issueText:"",
    supportRequestType:SupportRequestType.ISSUE
  };
  requestTypeOptions = Object.values(SupportRequestType)
  formSend:boolean;
  formError:boolean;
  userHasSupportLevel:boolean=false;
  userName:string;
  userTierLevel: UserTierLevel;
  form: FormGroup;
  supportList:SupportRequestDto[]=[]
  ticketCount:number;
  selectedSupportRequest:SupportRequestDto

  constructor(private keycloakService:KeycloakService,
              private supportService:SupportService,
              private fb: FormBuilder,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.formSend = false;
    this.initForm();
  }

  resetForm(){
    this.formSend=false;
    this.userName =  this.keycloakService.getUsername();
    this.getAllTickets()
  }


  async sendForm(){
    this.spinner.show()
    this.createSupportRequestDto.issueText = this.form.get('issueText').value;
    this.createSupportRequestDto.supportRequestType= this.form.get('supportRequestType').value;
    this.createSupportRequestDto.createdBy = this.keycloakService.getUsername();
    this.supportService.createNewSupportRequest(this.createSupportRequestDto).subscribe({
      next: (v) => {
        this.spinner.hide()
        this.formSend = true
        this.clearForm();
        this.getAllTickets()
        this.userName =  this.keycloakService.getUsername();
      },
      error: (e) => {
        this.spinner.hide()
        this.formError = true;
        console.log('ERROR:'+e.message)
      }
    })
  }

  initForm(){
    this.form = this.fb.group({
      issueText:  [{ value: '', disabled: false }, Validators.required],
      createdBy: [],
      supportRequestType: [SupportRequestType.ISSUE]
    })
  }

  get issueText(){
    return this.form.get('issueText')
  }

  clearForm(){
    this.formError= false;
    this.issueText.patchValue('')
  }

  getAllTickets(){
    this.supportService.getAllSupportRequestsForUser().subscribe({
      next: (res) => {
        this.supportList =res
        this.ticketCount = this.supportList.length;
        this.selectedSupportRequest = this.supportList[0]
      },
      error: (err) => {
        throw err
      }
    })
  }

  selectItem(item:SupportRequestDto){
    this.selectedSupportRequest = item
  }

}
