import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Toast } from 'bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ApiDataDto } from 'src/app/_model/ApiDataDto';
import { ApiStatus } from 'src/app/_model/ApiStatus';
import { CreateExtractionDto } from 'src/app/_model/CreateExtractionDto';
import { Extractor } from 'src/app/_model/Extractor';
import { KeycloakService } from 'keycloak-angular';
import { ApiService } from 'src/app/_service/api.service';
import { ExtractionService } from 'src/app/_service/extraction.service';
import { ExtractorService } from 'src/app/_service/extractor.service';

@Component({
  selector: 'app-create-extraction',
  templateUrl: './create-extraction.component.html',
  styleUrls: ['./create-extraction.component.scss']
})
export class CreateExtractionComponent {
  toast: Toast | null = null;
  bootstrap: any;
  _toast = { heading: "", message: "", time: "", visibility: "hidden" };
  @ViewChild('myToast',{static:true}) toastEl!: ElementRef<HTMLDivElement>;
  checkboxControl: FormControl;
  newExtraction: CreateExtractionDto;
  form: UntypedFormGroup;
  browserActionsFormClick: UntypedFormGroup;
  browserActionsFormScroll: UntypedFormGroup;
  URL_REGEXP =
    /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s\/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-\/]*)?$/;
  selectedOption = '';
  scrollXValue: number = null;
  scrollYValue: number = null;
  clickXValue: number = null;
  clickYValue: number = null;
  waitValue: number = null;
  scrollValid = false;
  clickValid = false;
  selectedOptionValid = false;
  extractionTypes: string[] = ["regex"];
  selectedExtractionType = '';
  enableProxy = false;
  selectedGeneratedCode = 'json'
  proxyList: string[] = [
    'america-north',
    'america-south',
    'middleeast',
    'europe-central',
    'europe-west',
    'europe-north',
    'europe-south',
    'asiapacific-southeast',
    'asiapacific-northeast',
    'asiapacific-south',
    'asiapacific-east',
    'africa-south',
    'america',
    'middleeast',
    'asiapacific',
    'africa',
    'worldwide',
    'europe'
  ];
  parameters: string[] = ['scroll', 'click', 'wait', 'screenshot', 'full-page-screenshot'];
  apiData:ApiDataDto={
    apiUrl: '',
    apiKey: '',
    apiStatus: new ApiStatus
  }
  extractors: Extractor[]=[]
  isAdmin = false;

  constructor(private extractionService: ExtractionService,
              private router: Router,
              private fb: UntypedFormBuilder,
              private clipboardApi: ClipboardService,
              private keycloakService:KeycloakService,
              private cdr: ChangeDetectorRef,
              private apiService: ApiService,
              private extractorService: ExtractorService
  ) { }

  ngOnInit() {
    this.initForm();
    this.fetchApiData();
    this.fetchExtractors();
    this.toast = new Toast(this.toastEl.nativeElement,{});
    this.isAdmin = this.keycloakService.isUserInRole('admin');
  }

  async fetchApiData(){
    this.apiService.getApiData().pipe().subscribe(data => {
      this.apiData = data
    })
  }

  async fetchExtractors(){
    this.extractorService.getAddedExtractors().subscribe((res)=>{
      this.extractors = res
      this.extractors.forEach((extractor)=>{
        this.extractionTypes.push(extractor.key)
      })
    })  
  }

  initForm() {
    if (this.form) {
      return;
    }
    this.form = this.fb.group({
      url: [
        { value: '', disabled: false },
        [Validators.required, Validators.pattern(this.URL_REGEXP)],
      ],
      agent: this.fb.group({
        resolution: this.fb.group({
          width: [
            { value: '1903', disabled: false },
            [Validators.required, Validators.min(100), Validators.max(4100)],
          ],
          height: [
            { value: '927', disabled: false },
            [Validators.required, Validators.min(100), Validators.max(4100)],
          ]
        }),
        options: this.fb.group({
          headers: this.fb.array([]),
        }),
      }),
      connectivity: this.fb.group({
        proxy: [{ value: 'europe', disabled: false }]
      }),
      workflow: new UntypedFormArray([]),
      selector: this.fb.array([this.fb.group({
        type: new FormControl({ value: 'full', disabled: false },Validators.required),
        value: new FormControl({ value: '', disabled: false }),
      })],Validators.required),
      webhook: this.fb.group({
        enabled: false,
        url: [
          { value: '', disabled: true },
          [Validators.required, Validators.pattern(this.URL_REGEXP)],
        ],
        header: this.fb.array([]),
        method: [{ value: 'GET', disabled: true }, [Validators.required]]
      }),
    });
  }

  onChangeFullExtraction(_event: any) {
    if (_event.target.checked) {
      this.selector.push(
        this.fb.group({
          type: new FormControl({ value: 'full', disabled: false }, Validators.required),
          value: new FormControl({ value: '', disabled: false }),
        })
      );
    } else if (!_event.target.checked) {
      const index = this.selector.controls.findIndex((control: AbstractControl) => {
        const value = control.value;
        return value.type === 'full' && value.value === '';
      });
      if (index !== -1) {
        this.selector.removeAt(index);
      }
    }
  }

  filteredExtractions() {
    return this.selector.controls.filter((control: AbstractControl) => control.value.type !== 'full');
  }

  async submitData() {
    this.newExtraction = this.form.value;
    this.extractionService.createExtraction(this.newExtraction).subscribe({
      next: (v) => {
        this.router.navigate(['/scrape']);
      },
      error: (e) => {
        console.log('ERROR:' + e.message);
      },
      complete: () => { },
    });
  }

  cancel() {
    this.router.navigate(['/scrape']);
  }

  setMobileResolution() {
    this.form.get('agent.resolution.width').setValue(480)
    this.form.get('agent.resolution.height').setValue(800)
  }

  setDesktopResolution() {
    this.form.get('agent.resolution.width').setValue(2048)
    this.form.get('agent.resolution.height').setValue(1080)
  }

  onInputChange() {
    this.cdr.detectChanges();
  }

  selecetExtractionType(_event: any) {
    this.selectedExtractionType = _event.target.value;
  }

  addExtraction(input: HTMLInputElement) {
    if(this.selectedExtractionType === 'Regex'){
          if (this.extractionAdditionAllowed(this.selectedExtractionType, input.value)) {
      this.selector.push(
        this.fb.group({
          type: new FormControl({ value: this.selectedExtractionType.toLowerCase(), disabled: false }, Validators.required),
          value: new FormControl({ value: input.value, disabled: false }, Validators.required),
        })
      );
      input.value = '';
    }
    } else{
            this.selector.push(
        this.fb.group({
          type: new FormControl({ value: this.selectedExtractionType.toLowerCase(), disabled: false }, Validators.required),
          value: new FormControl({ value: input.value, disabled: true }),
        })
      );
    }

  }

  extractionAdditionAllowed(selectedExtractionType: any, inputValueRef: any){
    if(['Regex', 'Select', ''].includes(selectedExtractionType) && inputValueRef === ''){
      return false;
    }
    if(selectedExtractionType !== 'regex') {
      return true;
    }

   return true;
  }

  addButtonExpressionChecker(selectedExtractionType: any, inputValueRef: any) {
    return this.extractionAdditionAllowed(selectedExtractionType, inputValueRef);
  }

  changeParameter(_event: any) {
    this.form
      .get('browserActions')
      .setValue(_event.target.value, { onlySelf: true });
  }

  scrollXValueOnKeyUp(_event: any) {
    this.scrollXValue = _event.target.value;
    if (this.scrollXValue) {
      this.scrollValid = true;
    }
  }
  scrollYValueOnKeyUp(_event: any) {
    this.scrollYValue = _event.target.value;
    if (this.scrollYValue) {
      this.scrollValid = true;
    }
  }

  clickXValueOnKeyUp(_event: any) {
    this.clickXValue = _event.target.value;
    if (this.clickXValue) {
      this.clickValid = true;
    }
  }

  clickYValueOnKeyUp(_event: any) {
    this.clickYValue = _event.target.value;
    if (this.clickYValue) {
      this.clickValid = true;
    }
  }

  waitValueOnKeyUp(_event: any) {
    this.waitValue = _event.target.value;
  }

  selectOption(_event: any) {
    this.selectedOption = _event.target.value;
  }

  copyText(){
    if(this.selectedGeneratedCode === 'json'){
      this.clipboardApi.copyFromContent(JSON.stringify(this.getFormValue()))
      this._toast.heading = "Copied Json ✅";
    } else {
      this.clipboardApi.copyFromContent(this.getCurlValue())
      this._toast.heading = ("Copied curl command ✅");
    }
    this.toast!.show();
    this._toast.time = new Date().toDateString()
    this._toast.message= "Successfully copied to clipboard!";
    this._toast.visibility= "show";
    setTimeout(()=>{this._toast.visibility="hide"},10000)
  }

  addAction() {
    if (this.workflowArray.status === 'DISABLED') {
      this.workflowArray.enable()
    }

    if (this.selectedOption == 'screenshot') {
      const screenshotObject = {
        action: this.selectedOption,
      };
      this.workflowArray.value.push(screenshotObject);
    } else if (this.selectedOption == 'scroll') {
      const scrollObject = {
        action: this.selectedOption,
        options: {
          x: this.scrollXValue,
          y: this.scrollYValue,
        }
      };
      this.workflowArray.value.push(scrollObject);
      this.scrollXValue = 0;
      this.scrollYValue = 0;
    } else if (this.selectedOption == 'click') {
      const clickObject = {
        action: this.selectedOption,
        options: {
          x: this.clickXValue,
          y: this.clickYValue,
        }
      };
      this.workflowArray.value.push(clickObject);
      this.clickXValue = 0;
      this.clickYValue = 0;
    } else if (this.selectedOption == 'wait') {
      const waitObject = {
        action: this.selectedOption,
        value: this.waitValue,
      };
      this.workflowArray.value.push(waitObject);
      this.waitValue = 0;
    } else if (this.selectedOption == 'full-page-screenshot') {
      const fullPageScreenshotObject = {
        action: this.selectedOption,
      };
      this.workflowArray.value.push(fullPageScreenshotObject);
    }
  }

  deleteBrowserAction(i: any) {
    this.workflowArray.value.splice(i, 1);
    if (i === 0 && this.workflowArray.length === 1) {
      this.workflowArray.disable()
    }
  }

  onChangeWebhookEnable(_event: any) {
    if (_event.target.checked) {
      this.form.get('webhook.url').enable();
      this.form.get('webhook.method').enable()
      this.form.get('webhook.header').enable()
    } else {
      this.form.get('webhook.url').disable();
      this.form.get('webhook.method').disable()
      this.form.get('webhook.header').disable()
    }
  }

  addHeader() {
    this.headers.push(
      this.fb.group({
        key: new FormControl({ value: '', disabled: false }, Validators.required),
        value: new FormControl({ value: '', disabled: false }, Validators.required),
      })
    );
  }

  deleteHeader(i: any) {
    this.headers.removeAt(i);
    if (i === 0) {
      this.headers.disable()
    }
  }

  addWebhookHeader() {
    this.webhookHeaders.push(
      this.fb.group({
        key: new FormControl({ value: '', disabled: false }, Validators.required),
        value: new FormControl({ value: '', disabled: false }, Validators.required),
      })
    );
  }

  deleteWebhookHeader(i: any) {
    this.webhookHeaders.removeAt(i);
    if (i === 0) {
      this.webhookHeaders.disable()
    }
  }

  deleteExtraction(i: any) {
    if (this.selector.at(i).value.type === 'full') {
      this.checkboxControl.patchValue(false)
    }
    this.selector.removeAt(i);
  }

  deleteExtractionByValue(i: any) {
    for (let index = 0; index < this.selector.length; index++) {
      if (this.selector.at(index).value === i) {
        this.selector.removeAt(index);
      }
    }
  }

  deleteBrowserActions(i: any) {
    this.workflowArray.removeAt(i);
    if (i === 0) {
      this.workflowArray.disable();
    }
  }

  get resolution() {
    return this.form.get('agent.resolution');
  }

  get webhookEnabled() {
    return this.form.get('webhook.enabled');
  }

  get webhookUrl() {
    return this.form.get('webhook.url');
  }

  get options() {
    return this.form.get('agent.options')
  }

  get url() {
    return this.form.get('url');
  }

  get browser() {
    return this.form.get('browser');
  }

  get selector(): FormArray {
    return this.form.get('selector') as FormArray;
  }

  get type() {
    return this.form.get('')
  }

  get workflowArray() {
    return this.form.get('workflow') as UntypedFormArray;
  }

  get width() {
    return this.form.get('agent.resolution.width');
  }

  get height() {
    return this.form.get('agent.resolution.height');
  }

  get headers() {
    return this.form.get('agent.options.headers') as FormArray;
  }

  get webhookHeaders() {
    return this.form.get('webhook.header') as FormArray;
  }

  get agent() {
    return this.form.get('agent');
  }

  get version() {
    return this.form.get('browser.version');
  }


  get xScroll() {
    return this.browserActionsFormScroll.get('options.x');
  }
  get yScroll() {
    return this.browserActionsFormScroll.get('options.y');
  }

  getFormValue(): string {
    return this.form.value;
  }

  selectClient($event: any) {
    this.selectedGeneratedCode = $event.target.value
  }

  getCurlValue(): string {
    const jsonData = JSON.stringify(this.getFormValue());
    //const escapedJsonData = jsonData.replace(/"/g, '\\"'); 

    const curlCode = `curl -X POST ${this.apiData.apiUrl}/extractions \\
     -H "Content-Type: application/json" \\
     -H "API-KEY: ${this.apiData.apiKey}" \\
     -d '${jsonData}'`;
    return curlCode;
  }



}