<!-- Modal Form -->
<div class="modal fade" id="contact-us" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="contact-us">Contact us</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div *ngIf="!formSend" class="modal-body">
                <div class="col-12">
                    <p class="text-muted">Just Tell us what you need! We will reach out to you and find the perfect solution for you and
                        your business.<br></p>
                    <div class="col-12">
                        <form class="row g-3 p-3" [formGroup]="form">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="inputEmail" class="form-label">Email*</label>
                                    <input type="email" class="form-control" id="inputEmail" placeholder="Enter Email" formControlName="email" required>
                                    <div *ngIf="email.touched && email.invalid" class="text-danger">
                                        <small>Please enter a valid email.</small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="inputName" class="form-label">Name*</label>
                                    <input type="text" class="form-control" id="inputName" placeholder="Enter name" formControlName="name">
                                    <div *ngIf="name.touched && name.invalid" class="text-danger">
                                        <small>Please provide a name.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label for="inputCompany" class="form-label">Company</label>
                                    <input type="text" class="form-control" id="inputCompany" placeholder="Enter your company name" formControlName="company">
                                </div>
                                <div class="col-6">
                                    <label for="inputPhone" class="form-label">Phone</label>
                                    <input type="text" class="form-control" id="inputPhone" placeholder="Enter your phone number" formControlName="phone">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label for="inputMessage" class="form-label">Message*</label>
                                    <textarea class="col-12" id="inputMessage" rows="6"placeholder="Enter your message here" formControlName="message" required></textarea>
                                    <div *ngIf="message.touched && message.invalid" class="text-danger">
                                        <small> Please enter a message</small>
                                      </div>
                                </div>
                            </div>
                            <div class="row">
                                <small class="text-muted">*Required fields</small>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="formSend" class="modal-body">
                <h4 class="text-center">Successfully send request ✔️</h4>
                <p>Thank you for reaching out to us! We will contact you as soon as possible.</p><br>
              </div>
              <div *ngIf="formError" class="modal-body">
                <h4 class="text-center"> Whoops! Something went wrong. </h4>
                <p>We are sorry! It seems that somthing went wrong. Please try again later or send us an email with your
                  issue <a href="mailto:help@adcolabs.com"><b>help&#64;adcolabs.com</b></a> </p><br>
              </div>
            <div class="modal-footer">
                <button type="button" (click)="close()" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                <button *ngIf="!formSend" (click)="contactUs()" class="resetbutton btn btn-primary" [disabled]="!form.valid">Send</button>
            </div>
        </div>
    </div>
</div>
<ngx-spinner type="ball-pulse" bdColor="rgba(0, 0, 0, 0.6)" size="medium" color="#fff" [fullScreen]="true">
</ngx-spinner>

<!-- Modal Form -->
<div class="modal fade" id="contact-us-confirm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="contact-us-confirm">Thank you for reaching out to us!</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-12">
                    <p>We appreciate your interest in our enterprise solutions. Our team will review your information and contact you promptly to discuss how we can collaborate to find the best solution for your enterprise needs.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>