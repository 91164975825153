<div class="row h-100">
    <div class="col-md-12 grid-margin py-2">
        <div class="card shadow-sm">
            <div class="pricing-header p-3 pb-md-4 mx-auto text-center">
                <h2 class="display-4 fw-normal" i18n>🎉 Your transaction was successfull! 🎉</h2>
                <p class="fs-5 text-muted" i18n>You can now Start Scraping something or head to our Documentation page to get easily started!</p>
                <button class="btn btn-primary" (click)="createPortalSession()" i18n>Manage my Subscription
                </button>
            </div>
        </div>
    </div>
</div>