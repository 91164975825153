import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { CreateScrapeComponent } from "../scrape/create-scrape/create-scrape.component";


@Injectable({
    providedIn:CreateScrapeComponent
})
export class DataService{

    private viewData = new BehaviorSubject('')
    currentData = this.viewData.asObservable();

    constructor(){
    }

    changeData(data:string){
        this.viewData.next(data);
    }

}