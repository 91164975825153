import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { MetricsDto } from "../_model/MetricsDto";
import { ApiConfiguration } from "./api-configuration";
import { BaseService } from "./base-service";

@Injectable({
    providedIn:'root',
})
export class MetricsService extends BaseService{
    private RootUrl = environment.SCRAPER_UI_SERVICE;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        }),
    };

    constructor(config:ApiConfiguration, http:HttpClient){
        super(config,http);
    }

    getMetrics():Observable<MetricsDto>{
        return this.http.get<MetricsDto>(this.RootUrl+'/userdata/metrics')
    }


}