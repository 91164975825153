import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './account/account.component';
import { ExtractorsComponent } from './extractors/extractors.component';
import { ApiComponent } from './api/api.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ErrorPagesComponent } from './error-pages/error-pages.component';
import { CancelComponent } from './payment/cancel/cancel.component';
import { PaymentComponent } from './payment/payment.component';
import { SuccessComponent } from './payment/success/success.component';
import { CreateScrapeComponent } from './scrape/create-scrape/create-scrape.component';
import { ScrapeComponent } from './scrape/scrape.component';
import { ViewScrapeComponent } from './scrape/view-scrape/view-scrape.component';
import { AuthGuard } from './_utility/app.guard';
import { ScraperComponent } from './scrape/scraper/scraper.component';
import { ScraperExtractionsComponent } from './scrape/scraper/scraper-extractions/scraper-extractions.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { CreateExtractionComponent } from './scrape/extraction/create-extraction/create-extraction.component';

const routes: Routes = [
//sidebar navigation
{ path: '', component: DashboardComponent, pathMatch: 'full' ,canActivate:[AuthGuard]},
{ path: 'dashboard', component: DashboardComponent,canActivate:[AuthGuard] },
{ path: 'scrape', component: ScrapeComponent,canActivate:[AuthGuard],runGuardsAndResolvers: 'always' },
{ path: 'api', component: ApiComponent,canActivate:[AuthGuard] },
{ path: 'pricing', component: PaymentComponent,canActivate:[AuthGuard] },
{ path: 'account',  component: AccountComponent, canActivate:[AuthGuard]},
{ path: 'enterprise',  component: EnterpriseComponent, canActivate:[AuthGuard]},
{ path: 'extractors',  component: ExtractorsComponent, canActivate:[AuthGuard]},


{ path: 'extraction/:id', component: ViewScrapeComponent,canActivate:[AuthGuard] },
{ path: 'scrape/scraper/:id',component:ScraperComponent, canActivate:[AuthGuard] },
{ path: 'scrape/scraper/:id/extractions',component:ScraperExtractionsComponent, canActivate:[AuthGuard] },
{ path: 'create-scraper', component: CreateScrapeComponent,canActivate:[AuthGuard] },
{ path: 'create-extraction', component: CreateExtractionComponent,canActivate:[AuthGuard] },
{ path: 'payment-success', component: SuccessComponent,canActivate:[AuthGuard] },
{ path: 'payment-cancel', component: CancelComponent,canActivate:[AuthGuard] },
//Error Pages
{ path: 'error-404', component: ErrorPagesComponent ,canActivate:[AuthGuard]},
{ path: '**', redirectTo: '/error-404'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation:'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
