export interface NewsDTO{
    created:Date;
    headline: string
    newsText: string
    createdBy:string;
    newsType: NewsType;
}
export enum NewsType {
    SCRAPER="NEWS",
    EXTRACTION="UPDATE"
}