import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { UserDataService } from './_service/userdata.service';
import { UserTierLevel } from './_model/UserTierLevelDto';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'scraper-frontend-app';

  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  userHasSupportLevel = false
  userTierLevel:UserTierLevel; 

  constructor(private readonly keycloak: KeycloakService,
              private userdataService:UserDataService){}

  public async ngOnInit() {

    this.isLoggedIn = this.keycloak.isLoggedIn()
    if (this.isLoggedIn) {
      this.getUserTier();
      this.userProfile = await this.keycloak.loadUserProfile();
    }   
  }

  public login() {
    this.keycloak.login();
  }

  public logout() {
    this.keycloak.logout();
  }

  public getUserTier(){
    this.userdataService.getUserTierLevel().subscribe({
      next: (res) => {
        this.userTierLevel = res;
        if(this.userTierLevel.tierLevel === "PRO" || this.userTierLevel.tierLevel ==="BUSINESS" || this.userTierLevel.tierLevel ==="ENTERPRISE"){
          this.userHasSupportLevel= true;
        }
      }
    })
   }


}
