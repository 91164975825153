<div class="row">
    <div class="col-md-12 grid-margin py-2">
        <div class="card shadow-sm">
            <div class="p-3 pb-md-4 mx-auto text-start">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <h4 class="display-4 fw-normal" i18n>Enterprise-Grade <br>
                                <span class="text-muted">Webscraping Solution</span></h4>
                            <p class="lead mt-3">In addition to our standard offerings, we provide custom solutions for our enterprise clients. This includes access to a wider range of browser versions, tailored functionalities, post-processing of results, automated deliveries and custom interfaces for data delivery.</p>
                            <button type="button" class="btn btn-lg btn-primary mt-auto" data-bs-target="#contact-us" data-bs-toggle="modal"
                                            i18n>Contact Us</button>
                        </div>
                    </div>
                    <hr class="divider">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <h2 class="feature-heading">Use-Cases</h2>
                            </div>
                            <div class="col-md-7">
                                <h3 class="feature-heading">Market Research & Competitive Analysis</h3>
                                <p class="lead">Companies constantly need insights into their competition. By scraping product listings, reviews, and prices, enterprises can keep a tab on their competitors' product offerings, customer feedback, and pricing strategies. Helps in refining product strategies, setting competitive prices, and identifying market gaps or opportunities.</p>
                            </div>
                            <div class="col-md-5">
                                <img class="img-fluid rounded m-2" src="assets/images/marketing/1.png">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-7">
                                <h3 class="feature-heading">Lead Generation & CRM Enhancement</h3>
                                <p class="lead">Sales and marketing teams require updated and enriched contact data. By scraping relevant industry websites, enterprises can gather potential lead data or augment existing CRM data. Provides sales teams with a rich pool of potential clients and improves the effectiveness of marketing campaigns by ensuring that the data is accurate and up-to-date.</p>
                            </div>
                            <div class="col-md-5">
                                <img class="img-fluid rounded m-2" src="assets/images/marketing/2.png">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-7">
                                <h3 class="feature-heading">Brand Monitoring & Reputation Management</h3>
                                <p class="lead">Companies can scrape review sites, social media platforms, and forums to monitor mentions of their brand and products. Allows for quick response to customer feedback, both positive and negative. Enterprises can manage their online reputation, address grievances, and capitalize on positive sentiment.</p>
                            </div>
                            <div class="col-md-5">
                                <img class="img-fluid rounded m-2" src="assets/images/marketing/3.png">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-7">
                                <h3 class="feature-heading">Recruitment & Talent Acquisition</h3>
                                <p class="lead">Extract data from job portals, professional networking sites, university alumni databases, and other relevant platforms.Recruitment teams can perform proactive talent scouting, reducing the time-to-hire, and ensuring a better fit for open positions and are able identify potential candidates based on specific criteria such as skill sets, experiences, locations, and more</p>
                            </div>
                            <div class="col-md-5">
                                <img class="img-fluid rounded m-2" src="assets/images/marketing/4.png">
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
<app-contact-us></app-contact-us>