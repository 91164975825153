import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../_service/userdata.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { PaymentService } from '../_service/payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { KeycloakService } from 'keycloak-angular';
import { ChangePasswordDTO, changeEmailDTO } from '../_model/UserDataDto';
import { Router } from '@angular/router';
import { Tooltip } from 'bootstrap';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  authUrl:string = environment.KEYCLOAK_URL;
  form: FormGroup;
  passwordForm: FormGroup;
  emailForm: FormGroup
  changePasswordDTO: ChangePasswordDTO = {
    oldPassword:"",
    newPassword:"",
    newPasswordRepeat:""
  }

  changeEmailDTO: changeEmailDTO = {
    newEmail:"",
    oldEmail:""
  }
  mailUpdatedFailed = false
  mailUpdated = false;
  passwordUpdatedFailed = false
  passwordUpdated = false;
  mailVerified = false;

  inputTypePasswordField="password";

  constructor(private userdataService: UserDataService,
              private paymentService: PaymentService,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder,
              private readonly keycloakService: KeycloakService,
              private router: Router) { }

  ngOnInit(): void {
    this.initForm();
    this.emailForm.get("oldEmail").patchValue(this.keycloakService.getKeycloakInstance().tokenParsed['email'])
    this.keycloakService.loadUserProfile().then(proflile=>{
      this.mailVerified = proflile.emailVerified;
    })
    Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))
  }

  accountSettings(){
    window.open(this.authUrl+"/realms/adcolabs-scraper/account/","_blank")
  }

  deleteAccount(){
    this.userdataService.deleteUser().subscribe({
      next:(res)=>{
        this.keycloakService.getKeycloakInstance().clearToken();
      }, error:(err)=>{
        console.log(err)
      }
    });
  }

  updatePassword(){
    this.spinner.show()
    this.changePasswordDTO.newPassword = this.newPassword.value
    this.changePasswordDTO.newPasswordRepeat = this.newPasswordRepeat.value
    this.userdataService.updatePassword().subscribe({
      next:(res)=>{
        this.spinner.hide()
        this.passwordUpdated = true;
      },
      error:(err)=>{
        this.passwordUpdatedFailed = true
        this.spinner.hide()
        console.log(err)
      },
      complete:()=>{
        this.spinner.hide()
        this.ngOnInit();
      }

    })

  }

  updateEmail(){
    this.spinner.show()
    this.changeEmailDTO.newEmail = this.newEmail.value;
    this.changeEmailDTO.oldEmail = this.oldEmail.value;
    this.userdataService.updateEmail(this.changeEmailDTO).subscribe({
      next:(res)=>{
        this.spinner.hide()
        this.emailForm.get("oldEmail").patchValue(this.newEmail.value)
        this.emailForm.get("newEmail").setValue("");
        this.mailUpdated = true;
        this.newEmail.markAsPristine();
        this.newEmail.markAsUntouched();
      },
      error:(err)=>{
        this.mailUpdatedFailed = true
        this.spinner.hide()
        console.log(err)
      }
    })

  }
  
  manageSubscription(){
    this.spinner.show();
    this.paymentService.createPortalSessionForUser().subscribe(
      {
        next: (res) => {
          window.location.href=res
          this.spinner.hide();
        },
        error: (err) => {
          this.spinner.hide();
        }
      }
    )
  }

  initForm(){
    this.form = this.fb.group({
      deleteString: ['', [Validators.required, Validators.pattern(/\bDELETE PERMANENTLY\b/)]],
    })

    this.passwordForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      newPasswordRepeat: ['', [Validators.required, Validators.minLength(6)]],
    })

    this.emailForm= this.fb.group({
      oldEmail:[{value:'', disabled:true}, [Validators.required, Validators.email]],
      newEmail:['', [Validators.required, Validators.email, ]]
    })
  }

  get deleteString(){
    return this.form.get("deleteString")
  }

  get oldPassword(){
    return this.passwordForm.get("oldPassword")
  }

  get newPassword(){
    return this.passwordForm.get("newPassword")
  }

  get newPasswordRepeat(){
    return this.passwordForm.get("newPasswordRepeat")
  }

  get oldEmail(){
    return this.emailForm.get("oldEmail")
  }

  get newEmail(){
    return this.emailForm.get("newEmail")
  }


  oldEmailMatchesNewEmail(){
    const oldEmail = this.oldEmail.value
    const newEmail = this.newEmail.value
    return newEmail != oldEmail
  }

}
