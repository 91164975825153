<div class="row">
  <div class="grid-margin col-md-12">
    <div class="row">
      <div class="col-md-6 my-2">
        <div class="card shadow-sm h-100">
          <div class="card-body">
            <h3 i18n class="card-title">API-Access</h3>
            <h5 i18n class="card-title" style="margin-top: 1em;">Status <span
                style="margin-left: 0.2em; color: #157347;">Online</span></h5>
            <h5 i18n class="card-title" style="margin-top: 1em;">Url</h5>
            <div class="codeblock col-12 bg-black p-2 user-select-all" style="height: 40px">
              <span>
                {{apiData.apiUrl}}
                <div class="copyapikey">
                  <svg (click)="copyText(apiData.apiUrl)" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                    fill="currentColor" class="bi bi-clipboard2" viewBox="0 0 16 16">
                    <path
                      d="M3.5 2a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H12a.5.5 0 0 1 0-1h.5A1.5 1.5 0 0 1 14 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-12A1.5 1.5 0 0 1 3.5 1H4a.5.5 0 0 1 0 1h-.5Z" />
                    <path
                      d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z" />
                  </svg>
                </div>
              </span>
            </div>
            <h5 i18n class="card-title" style="margin-top: 1em;">Key</h5>
            <div class="codeblock col-12 bg-black p-2 user-select-all" style="max-height: 80px">
              {{apiData.apiKey}}
              <div class="copyapikey">
                <svg (click)="copyText(apiData.apiKey)" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                  fill="currentColor" class="bi bi-clipboard2" viewBox="0 0 16 16">
                  <path
                    d="M3.5 2a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H12a.5.5 0 0 1 0-1h.5A1.5 1.5 0 0 1 14 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-12A1.5 1.5 0 0 1 3.5 1H4a.5.5 0 0 1 0 1h-.5Z" />
                  <path
                    d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z" />
                </svg>
              </div>
            </div>
            <div class="alert alert-primary my-3 col-md-8" role="alert">
              Check out our <a href="https://docs.scraper.adcolabs.de" target="_blank"
                rel="noopener noreferrer">documentation</a> to see how to use our API and the API-Key provided here.
            </div>
            <div class="row">
              <div class="col-md-8">
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"
                  i18n>Reset API Key</button>
              </div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
              aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModal" i18n>Reset API-Key</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <p><small i18n>Your current API-Key will be permanently delete. Your new API key will be instantly
                        available and your old key will no longer work.</small></p>
                    <p> <b>Are you sure you want to reset your API-Key?</b></p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" i18n>Close</button>
                    <button (click)="resetApiKey()" data-bs-dismiss="modal" class="resetbutton btn btn-primary"
                      routerLink="/api" i18n>Reset API Key</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 my-2">
        <div class="card shadow-sm h-100">
          <div class="card-body">
            <h3 i18n class="card-title">API-Examples</h3>
            <div class="btn-group flex-wrap" role="group" aria-label="Basic radio toggle button group">
              <input type="radio" class="btn-check" name="apiexample" value="curl" id="curl" checked
                (change)="selectClient($event)">
              <label class="btn" for="curl">cURL</label>

              <input type="radio" class="btn-check" name="apiexample" value="httpie" id="httpie"
                (change)="selectClient($event)">
              <label class="btn" for="httpie">HTTPIE</label>

              <input type="radio" class="btn-check" name="apiexample" value="java" id="java"
                (change)="selectClient($event)">
              <label class="btn" for="java">Java</label>

              <input type="radio" class="btn-check" name="apiexample" value="nodejs" id="nodejs"
                (change)="selectClient($event)">
              <label class="btn" for="nodejs">Nodejs</label>

              <input type="radio" class="btn-check" name="apiexample" value="python" id="python"
                (change)="selectClient($event)">
              <label class="btn" for="python">python</label>

              <input type="radio" class="btn-check" name="apiexample" value="kotlin" id="kotlin"
                (change)="selectClient($event)">
              <label class="btn" for="kotlin">Kotlin</label>

              <input type="radio" class="btn-check" name="apiexample" value="php" id="php"
                (change)="selectClient($event)">
              <label class="btn" for="php">PHP</label>
            </div>
            <h5 i18n class="card-title" style="margin-top:1em;">{{selectedClient | titlecase}} - Example</h5>
            <pre *ngIf="selectedClient ==='curl'" class="col-md-12">
                <code class="col-md-12 col-12" [highlight]="curlExample" [languages]="['bash']"></code>
              </pre>
            <pre *ngIf="selectedClient ==='httpie'">
                <code class="col-md-12 col-12" [highlight]="httpieExample" [languages]="['bash']"></code>
              </pre>
            <pre *ngIf="selectedClient ==='java'">
                <code class="col-md-12 col-12" [highlight]="javaExample" [languages]="['java']"></code>
              </pre>
            <pre *ngIf="selectedClient ==='nodejs'">
                <code class="col-md-12 col-12" [highlight]="nodejsExample" [languages]="['typescript']"></code>
              </pre>
            <pre *ngIf="selectedClient ==='python'">
                <code class="col-md-12 col-12" [highlight]="pythonExample" [languages]="['python']"></code>
              </pre>
            <pre *ngIf="selectedClient ==='kotlin'">
                <code class="col-md-12 col-12" [highlight]="kotlinExample" [languages]="['kotlin']"></code>
              </pre>
            <pre *ngIf="selectedClient ==='php'">
                <code class="col-md-12 col-12" [highlight]="phpExample" [languages]="['php']"></code>
              </pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div aria-live="polite" aria-atomic="true" class="position-relative">
  <div class="toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 11">
    <div #myToast role="alert" aria-live="assertive" aria-atomic="true" class="toast fade" data-bs-autohide="true">
      <div class="toast-header">
        <strong class="me-auto">{{_toast.heading}}</strong>
        <small>{{_toast.time}}</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        {{_toast.message}}
      </div>
    </div>
  </div>
</div>