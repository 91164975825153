<div class="bg-light">
    <app-navbar></app-navbar>
    <div class="container-fluid">
        <div class="row">
            <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                <app-sidebar></app-sidebar>
            </nav>
            <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <router-outlet></router-outlet>
            </main>
            <app-support *ngIf="userHasSupportLevel"></app-support>
        </div>
    </div>
    <app-footer></app-footer>
</div>

<!-- Modal -->
<div class="modal fade" id="welcomeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Welcome to Adcolabs Scraper!</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p>
                    Thanks for joining us! 🙌
                </p>
                <p>
                    We’re excited for you to explore the features we’ve crafted just for you. We hope our product brings
                    you
                    the value you
                    need. 🚀
                </p>
                <p>
                    Stay updated with news, tips, and exclusive offers by signing up for our newsletter, and <b> get 10
                        free
                        Extractions</b> as a thank-you!
                </p>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                        (change)="enableOptIn($event)">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Sign Up and get 10 Extractions for Free
                        🎁</label>
                </div>

                <br>
                Happy scraping!
                <br>
                The Adcolabs Team
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                    (click)="submitOptIn()">Ok!</button>
            </div>
        </div>
    </div>
</div>