import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";


export function initializeKeycloak(keycloak: KeycloakService):()=>Promise<boolean> {
    return () =>
      keycloak.init({
        config: {
          url: environment.KEYCLOAK_URL,
          realm: environment.KEYCLOAK_REALM,
          clientId: 'angular-client',
        },
        initOptions:{
            checkLoginIframe: true,
            checkLoginIframeInterval: 5,
            onLoad: "login-required"
        },
        loadUserProfileAtStartUp: true
      });
  }