import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiDataDto } from "../_model/ApiDataDto";
import { ApiConfiguration } from "./api-configuration";
import { BaseService } from "./base-service";


@Injectable({
    providedIn:'root',
})
export class ApiService extends BaseService{
    private RootUrl = environment.SCRAPER_UI_SERVICE;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        }),
    };

    constructor(config:ApiConfiguration, http:HttpClient){
        super(config,http);
    }

    getApiData():Observable<ApiDataDto>{
        return this.http.get<ApiDataDto>(this.RootUrl+'/userdata/api')
    }

    resetApiKey(apiDataDto:ApiDataDto):Observable<ApiDataDto>{
        return this.http.put<ApiDataDto>(this.RootUrl+'/userdata/api/key',apiDataDto)
    }

}