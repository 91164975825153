import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiConfiguration } from "./api-configuration";
import { BaseService } from "./base-service";
import ScraperData from "../_model/Scraperdata";
import { UpdateScraperDto } from "../_model/UpdateScraperDto";
import { ExtractionDataUIDTO } from "../_model/ExtractionDataUIDTO";
import { CreateScraperDto } from "../_model/CreateScraperDto";

@Injectable({
    providedIn:'root',
})
export class ScraperService extends BaseService{
    private RootUrl = environment.SCRAPER_UI_SERVICE;
    
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        }),
    };

    constructor(config:ApiConfiguration, http:HttpClient){
        super(config,http);
    }

    getScraperList():Observable<ScraperData[]>{
        return this.http.get<ScraperData[]>(this.RootUrl + '/scraper')
    }

    getAllScraperExtractions(id:string, page:number):Observable<ExtractionDataUIDTO>{
        const params = new HttpParams().set('page',page);
        return this.http.get<ExtractionDataUIDTO>(this.RootUrl + '/scraper/' + id + '/extractions',{params})
    }

    getScraperById(id:String):Observable<ScraperData>{
        return this.http.get<ScraperData>(this.RootUrl+'/scraper/' + id)
    }
    
    deleteScraper(id:String){
        return this.http.delete(this.RootUrl+'/scraper/' + id)
    }

    updateScraper(scraperData:UpdateScraperDto, id:string):Observable<UpdateScraperDto>{
        return this.http.patch<UpdateScraperDto>(this.RootUrl+'/scraper/'+ id, scraperData)
    }

    createScraper(createScraperDto: CreateScraperDto): Observable<CreateScraperDto>{
        return this.http.post<CreateScraperDto>(this.RootUrl + '/scraper', createScraperDto)
    }

}