import { Component, OnInit } from '@angular/core';


import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  loggedIn:boolean=false;
  authUrl:string = environment.KEYCLOAK_URL;

  constructor(private readonly keycloakService:KeycloakService) {
   }

  ngOnInit(): void {
    this.loggedIn = this.keycloakService.isLoggedIn()
  }

  logout(){
    this.keycloakService.logout();
  }

  }
