import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDataService } from '../_service/userdata.service';
import { ExtractorService } from '../_service/extractor.service';
import { Extractor } from '../_model/Extractor';
import { KeycloakService } from 'keycloak-angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserDataDto } from '../_model/UserDataDto';

@Component({
  selector: 'app-extractors',
  templateUrl: './extractors.component.html',
  styleUrl: './extractors.component.scss'
})
export class ExtractorsComponent implements OnInit {

  extractorData: Extractor[] = []
  isAdmin = false;
  form: FormGroup;
  createExtractorDTO: Extractor = {
    enabled: false,
    description: '',
    title: '',
    key: ''
  }
  userData:UserDataDto;
  userExtractorList: string[]=[];
  selectedExtractor: Extractor | null = null;
  constructor(private spinner: NgxSpinnerService,
              private extractorService: ExtractorService,
              private userDataService: UserDataService,
              private keycloakService: KeycloakService,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef
  ) { }
  
  
  ngOnInit(): void {
    this.spinner.show()
    this.isAdmin = this.keycloakService.isUserInRole('admin')
    this.fetchData();
    this.initForm()
  }

  async fetchData(){
    this.extractorService.getExtractors().subscribe((data)=>{
      this.extractorData = data;
      if(data.length){
        this.selectedExtractor = data[0]
      }
      this.spinner.hide();
    })
    this.userDataService.getUserData().subscribe((res)=>{
      this.userData = res;
      this.userExtractorList = res.extractorList;
    })
  }

  initForm() {
    this.form = this.fb.group({
      enabled: [{ value: false, disabled: false }, Validators.required],
      description: [{ value: '', disabled: false }, Validators.required],
      title: [{value: '', disabled: false}, Validators.required],
      key: [{value: '', disabled: false}, Validators.required],
    });
}

  createExtractor(){
    this.createExtractorDTO.enabled = this.form.get('enabled').value;
    this.createExtractorDTO.description = this.form.get('description').value;
    this.createExtractorDTO.title = this.form.get('title').value
    this.createExtractorDTO.key = this.form.get('key').value
    this.extractorService.createExtractor(this.createExtractorDTO).subscribe({
      next:()=>{
        this.clearForm();
        this.fetchData();
      }, error:(err)=>{
        console.log(err)
      }
    });
  }
  clearForm() {
    this.createExtractorDTO.description = ''
    this.createExtractorDTO.enabled = false
    this.createExtractorDTO.key = ''
    this.createExtractorDTO.title = ''
    this.createExtractorDTO.id = ''


    this.form.get('description').patchValue('');
    this.form.get('title').patchValue('');
    this.form.get('key').patchValue('');
  }

  enableExtractor(_event:any, extractor:Extractor){
    extractor.enabled = _event.target.checked;

    this.extractorService.enableExtractor(extractor.id, _event.target.checked).subscribe({
      error:(err) => {
        console.log(err)
        extractor.enabled = !extractor.enabled
      }
    })
  }

  selectExtractorIndex(index:number){
    this.selectedExtractor = this.extractorData[index];
  }

  deleteExtractor(id:string){
    this.extractorService.removeExtractr(id).subscribe(()=> this.ngOnInit())
  }

  toggleExtractorToUser(id:string){
    this.userDataService.toggleExtractorForUser(id).subscribe((res)=>{
      this.userData = res
      this.userExtractorList = res.extractorList
    })
  }

  //getter 
  get description(){
    return this.form.get("description");
  }

  get enabled(){
    return this.form.get("enabled");
  }

  get title(){
    return this.form.get("title");
  }

  get key(){
    return this.form.get("key");
  }

}
