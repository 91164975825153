import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentService } from 'src/app/_service/payment.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  session_id: string; 

  constructor(private paymentService:PaymentService,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.route.queryParams.
    subscribe(params=>{
      this.session_id = params["session_id"];
    })
  }

  async createPortalSession(){
    this.spinner.show();
    this.paymentService.createPortalSession(this.session_id).subscribe(
      {
        next: (res) => {
          window.location.href=res
          this.spinner.hide();
        },
        error: (err) => {
          this.spinner.hide();
        }
      }
    )
  }

}
