export const environment = {
    production: false,
    configFiles:'assets/config/config.dev.json',
    KEYCLOAK_URL:"https://auth.adcolabs.de/",
    KEYCLOAK_REALM:"adcolabs-scraper-copilot",
    KEYCLOAK_CLIENT_ID:"angular-web-client",
    SCRAPER_UI_SERVICE:"https://scrapercopilot.adcolabs.de",
    ROOT_URI:"https://scrapercopilot.adcolabs.de",
    PAYMENT_URL:'https://scrapercopilot.adcolabs.de/payment/payment',
    STRIPE_PUBLIC_KEY:"pk_test_51LLp9cFIJbuWXB9KA3Q5J6fBt1060zdhEy53sJYTYog5wuMTIwXRpWD2U4GP7du2ioJ8jRincU3x6RRiq5R3uFoU00KRNKfSgU",
    PRICE_BASIC_ID:"price_1NhBbTFIJbuWXB9KqTUGcu4L",
    PRICE_PRO_ID:"price_1NhBbTFIJbuWXB9KSvw1Z8Xm",
    PRICE_1k_SCRAPES_ID:"price_1NhBeVFIJbuWXB9Kxwweuk00",
    PRICE_4k_SCRAPES_ID:"price_1NhBeVFIJbuWXB9KgnwGW7wk",
    PRICE_8k_SCRAPES_ID:"price_1NhBeVFIJbuWXB9KBvmSXQG6"
  };    