import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";


import { ApiConfiguration } from "./api-configuration";
import { BaseService } from "./base-service";
import { ExtractionDataUIDTO } from "../_model/ExtractionDataUIDTO";
import { ExtractionPayloadDto } from "../_model/ExtractionPayloadDto";
import { CreateExtractionDto } from "../_model/CreateExtractionDto";


@Injectable({
    providedIn:'root',
})
export class ExtractionService extends BaseService{
    private RootUrl = environment.SCRAPER_UI_SERVICE;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        }),
    };

    constructor(config:ApiConfiguration, http:HttpClient){
        super(config,http);
    }

    getExtractionData():Observable<ExtractionDataUIDTO>{
        return this.http.get<ExtractionDataUIDTO>(this.RootUrl+'/extractions')
    }

    getCrawlDataByPage(page:number):Observable<ExtractionDataUIDTO>{
        const params = new HttpParams().set('page', page.toString());
        return this.http.get<ExtractionDataUIDTO>(this.RootUrl+'/extractions', { params })
    }

    getCrawlPayload(identifier:string):Observable<ExtractionPayloadDto>{
        return this.http.get<ExtractionPayloadDto>(this.RootUrl+'/extractions/'+identifier)
    }
    
    createExtraction(scrape:CreateExtractionDto):Observable<any>{
         return this.http.post<any>(this.RootUrl + '/extractions',scrape)
    }

    getCrawlDataPage(page:number, archived:boolean):Observable<ExtractionDataUIDTO>{
        const params = new HttpParams()
        .set('page',page.toString())
        .set('archived', archived);
        return this.http.get<ExtractionDataUIDTO>(this.RootUrl + '/extractions',{params})
    }
} 