<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css">
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Playwrite+ES:wght@100..400&display=swap" rel="stylesheet">
<nav class="navbar sticky-top navbar-expand-md navbar-dark shadow">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/dashboard">
      <img class="navbar-brand navbar-brand-logo" src="assets/icons/AdcolabsLogo_white-64px.png" alt="Adcolabs Logo"
        height="41">
      <span class="adcolabs-text-glow navbar-brand-text">scraper</span>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler"
      aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarToggler">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item p-2">
          <button (click)="logout()" class="btn btn-secondary dropdown-toggle btn-logout" type="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa-solid fa-right-from-bracket"></i>
          </button>
        </li>
        <li class="nav-item d-block d-md-none">
          <a class="nav-link" aria-current="page" routerLink="/dashboard" routerLinkActive
            #dashbaord="routerLinkActive">
            <div class="container">
              <div class="row">
                <div class="col-1">
                  <i class="fa-solid fa-gauge"></i>
                </div>
                <div class="col-sm-10 col-10" i18n>Dashboard</div>
              </div>
            </div>
          </a>
        </li>
        <li class=" nav-item d-block d-md-none">
          <a class="nav-link" routerLink="/scrape" routerLinkActive #scrape="routerLinkActive">
            <div class="container">
              <div class="row">
                <div class="col-1">
                  <i class="fa-solid fa-brain"></i>
                </div>
                <div class="col-sm-10 col-10" i18n>Scrape</div>
              </div>
            </div>
          </a>
        </li>
        <li class=" nav-item d-block d-md-none">
          <a class="nav-link" role="button" routerLink="extractors">
            <div class="container">
              <div class="row">
                <div class="col-1">
                  <i class="fa-solid fa-braille"></i>
                </div>
                <div class="col-sm-10 col-10">Extractors
                </div>
              </div>
            </div>
          </a>
        </li>
        <li class=" nav-item d-block d-md-none">
          <a class="nav-link" routerLink="/api" routerLinkActive #api="routerLinkActive">
            <div class="container">
              <div class="row">
                <div class="col-1">
                  <i class="fa-solid fa-circle-nodes"></i>
                </div>
                <div class="col-sm-10 col-10" i18n>API</div>
              </div>
            </div>
          </a>
        </li>
        <hr>
        <li class=" nav-item d-block d-md-none">
          <a class="nav-link" role="button" routerLink="/account">
            <div class="container">
              <div class="row">
                <div class="col-1">
                  <i class="fa-solid fa-user-gear"></i>
                </div>
                <div class="col-sm-10 col-10">Account
                </div>
              </div>
            </div>
          </a>
        </li>
        <li class=" nav-item d-block d-md-none">
          <a class="nav-link" routerLinkActive routerLink="/pricing" routerLinkActive #pricing="routerLinkActive">
            <div class="container">
              <div class="row">
                <div class="col-1">
                  <i class="fa-solid fa-store"></i>
                </div>
                <div class="col-sm-10 col-10" i18n>Subscription</div>
              </div>
            </div>
          </a>
        </li>
        <li class=" nav-item d-block d-md-none">
          <a class="nav-link" class="nav-link" href="https://docs.scraper.adcolabs.de/" target="_blank"
            rel="noopener noreferrer">
            <div class="container">
              <div class="row">
                <div class="col-1">
                  <i class="fa-solid fa-link"></i>
                </div>
                <div class="col-sm-10 col-10">Documentation
                </div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>